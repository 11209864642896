import React, { useState} from "react";
import Menubar2 from "../menubar2";
import { Button } from "primereact/button";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TabMenu } from 'primereact/tabmenu';

const TopBar = () => {

  const [activeIndex, setActiveIndex] = useState(0);

  const items = [
    // { label: "Devices", url: "/devices" }
  ];

  return (
    <Navbar expand="lg" className="bg-body-tertiary w-100 pt-0 pb-0">
      <div className="topBar">
        <Container className="mw-100">
          <Row>
            <Col xs={4}>
              <div className="d-lg-none d-sm-block d-block">
                <Menubar2 />
              </div>
            </Col>
            <Col xs={8} className="MobileMenu text-end">
              <Navbar.Toggle aria-controls="navbarScroll" />
            </Col>
          </Row>
        </Container>

        <div className="row">
          <Navbar.Collapse id="navbarScroll">
            <div className="col-lg-4 title flex-wrap justify-content-center gap-3">
              <TabMenu model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} />
            </div>

            <div className="col-lg-8 text-end ">
              <Button
                className="ms-4"
                severity="secondary"
                icon="pi pi-window-maximize"
                text
                outlined
                rounded
                raised
                style={{ fontSize: "1.5rem" }}
                tooltip="Fullscreen"
                tooltipOptions={{ position: "bottom" }}
              />
            </div>
          </Navbar.Collapse>
        </div>
      </div>
    </Navbar>
  );
};

export default TopBar;
