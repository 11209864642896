import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { DataTable } from "primereact/datatable";
import { DeviceService } from "../services/DeviceList";

import { ReactComponent as DeleteIcon } from "../../../assets/Images/client/icons/delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/Images/client/icons/edit.svg";

const ShareWithMe = () => {
  const [devices, setDevices] = useState([]);
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    DeviceService.getDevicesMini().then((data) => setDevices(data));
  }, []);

  const rowActionsBodyTemplate = (rowData) => {
    return (
      <div className="flex flex-row gap-3">
        <a
          href=" "
          tooltip="Enter your username"
          tooltipOptions={{ position: "bottom" }}
        >
          <EditIcon />
        </a>
        <DeleteIcon />
      </div>
    );
  };

  const switchTemplate = () => {
    return (
      <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
    );
  };

  return (
    <>
      <Row className="mb-4">
        <Col sm={8} className="gap-3 flex">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText placeholder="Search" style={{ height: "43px" }} />
          </span>

          <Button label="Search" className="btn btn-primary" rounded />
        </Col>

        <Col sm={4} className="gap-3 flex justify-content-end">
          
        </Col>
      </Row>

      <DataTable value={devices} tableStyle={{ minWidth: "50rem" }} stripedRows>
        <Column field="id" header="ID"></Column>
        <Column field="name" header="Device Name"></Column>
        <Column field="name" header="Device Owner"></Column>
        <Column field="category" header="Visible to Homepage" body={switchTemplate}></Column>
        <Column
          field=""
          header="Operations"
          body={rowActionsBodyTemplate}
        ></Column>
      </DataTable>
    </>
  );
};

export default ShareWithMe;
