/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import Menubar2 from "./adminMenubar2";
import Menubar from "./adminMenubar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import { ReactComponent as AttachmentIcon } from "../../assets/Images/client/attachmentIcon.svg";
import Modal from "react-bootstrap/Modal";
import { useNavigate, Link } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const moment = require('moment');

const TroubleShooting = () => {

  const navigate = useNavigate()
  var data = localStorage.getItem('adminLoginData');
  var result = JSON.parse(data);
  useEffect(() => {
    if (result) {
      getTicketDetails()
    } else {
      navigate("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [ticket_all_details, setTicketAllDetails] = useState([]);
  const [ticket_messages, setTicketMessages] = useState([]);
  const [ticket_messages_details, setTicketMessagesDetails] = useState([]);
  const [reply_button, setReplyButton] = useState(false);
  const [ticket_staus, setTicketStaus] = useState();

  
  const color_array_list = ['#adff2f', '#FF0000', '#008080','#800080', '#FF00FF', '#00FFFF','#008080', '#000080', '#adff2f', '#800000', '#008000','#9932cc', '#a52a2a', '#663399','#cc7722', '#882d17','#8bbe1b', '#648c11', '#a7fc00','#ceff00', '#00ced1', '#008b8b','#00ffef', '#00b7eb','#008b8b', '#009698', '#ffd700','#daa520', '#ffdf00', '#00FFFF','#008080', '#000080', '#adff2f', '#FF0000', '#008080','#800080', '#FF00FF', '#00FFFF','#8bbe1b', '#648c11', '#a7fc00','#ceff00', '#008080','#800080', '#FF00FF', '#00FFFF','#008080', '#000080','#adff2f', '#FF0000', '#008080','#800080', '#FF00FF', '#00FFFF','#008080', '#000080','#adff2f', '#FF0000', '#008080','#800080', '#FF00FF', '#00FFFF','#008080', '#000080','#adff2f', '#FF0000', '#008080','#800080', '#FF00FF', '#00FFFF','#008080', '#000080','#adff2f', '#FF0000', '#008080','#800080', '#FF00FF', '#00FFFF','#008080', '#000080'];

  const getTicketDetails = () => {
    
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "get_ticket_details_by_admin", axiosConfig).then((res) => {
      if (res.status === 200) {
        // console.log(res.data.client_details);
        const newArray = [];
        // eslint-disable-next-line array-callback-return
        res.data.client_ticket_details.map((data, index) => {
          var temp_array = {
            sr_no: index + 1,
            id: data.ticket_id,
            subject: data.ticket_subject,
            date: moment(data.created_date).utc('asia/kolkata').format('DD MMM, YYYY'),
            status: data.status,
            updated_date: moment(data.updated_date).utc('asia/kolkata').format('DD MMM, YYYY HH:mm a')
          }
          newArray.push(temp_array);
        });
        setTicketAllDetails(newArray);
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const viewTicket = (t_id) => {
    var postData = {
      ticket_id: t_id
    }

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + 'get_ticket_replys', postData, axiosConfig).then((res) => {
      setReplyButton(true)
      setTicketMessages(res.data.TicketMessages)
      setTicketStaus(res.data.TicketDetails.status)
      setTicketMessagesDetails(res.data.TicketDetails)
    });
  }

  const rowActionsBodyTemplate = (rowData) => {
    return (
      <div className="flex flex-row gap-3">
        <Button
          variant="primary"
          className="p-2"
          size="sm"
          onClick={() => viewTicket(rowData.id)}
        >
          View Ticket
        </Button>
      </div>
    );
  };

  const StatusBody = (rowData) => {
    return (
      rowData.status ? <Badge bg="dark">Close</Badge> : <Badge bg="warning">Open</Badge>
    )
  }

  const handleClose = () => setSmShow(false);

  const handleReplyClose = () => setReplyButton(false);

  // Constants for Modal
  // Open Submit Reply Modal
  const [smShow, setSmShow] = useState(false);

  const [editFormVal, setEditFormVal] = useState([{ edit_ticket_attachment: '' }])
  const updateAddRow = () => {
    setEditFormVal([...editFormVal, { edit_ticket_attachment: '' }])
  }
  const updateToRemove = (i) => {
    const newForm = [...editFormVal]
    newForm.splice(i, 1)
    setEditFormVal(newForm)
  }
  const updateToHandle = (e, i) => {
    let newForm = [...editFormVal]
    newForm[i][e.target.name] = e.target.files
    setEditFormVal(newForm)
  }


  const upateFormValidation = (editFormVal) => {
    const data = [...editFormVal]

    console.log(editFormVal)

    let valid = true
    for (let index = 0; index < data.length; index++) {
      if (data[index].edit_ticket_attachment === "") {
        data[index].updateParaIdCheck = "Please select parameter."
        valid = false
      } else {
        data[index].updateParaIdCheck = ""
        valid = true
      }
    }
    setEditFormVal(data)
    return valid
  }

  const onUpdate = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    
    const errorRes = upateFormValidation(editFormVal)
    if (errorRes) {
      const formData = new FormData();
      formData.append("ticket_message", data.get('update_ticket_message'));
      formData.append("ticket_id", data.get('ticket_id'));
      formData.append("c_id", result.id);
      formData.append("user_type", 'admin');
      formData.append("ticket_status", data.get('ticket_status'));
      

      for (let i = 0; i < editFormVal.length; i++) {
        formData.append("data", editFormVal[i]['edit_ticket_attachment'][0]);
      }

      if (data.get('update_ticket_message') === "") {
        toast.dismiss()
        toast.error("Ticket Message is required!", { theme: "colored", })
      } else {
        let axiosConfig = {
          headers: {
            'Content-Type': 'charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
          }
        };

        axios.post(global.passUrl + 'submit_client_ticket_reply', formData, axiosConfig)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.status === 'success') {
                toast.dismiss()
                toast.success(res.data.message, { theme: "colored", })
                setSmShow(false)
                viewTicket(data.get('ticket_id'))                
                getTicketDetails();
              } else {
                toast.dismiss()
                toast.error(res.data.message, { theme: "colored", })
              }
            } else if (res.status === 400) {
              window.location.href = '';
            }
          })
          .catch((err) => {
            alert(err);
          })
      }
    }
  }
  return (
    <>
      <ToastContainer />
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-lg-block">
            <Menubar pageName="ticket" />
          </div>

          <div className="col-12 col-lg-11 ps-0">
            <div className="topBar">
              <div className="row">
                <div className="col-lg-4 title">
                  <div className="d-lg-none d-sm-block d-block">
                    <Menubar2 />
                  </div>
                  <h3>Support Request</h3>
                </div>

                <div className="col-lg-4 search d-none d-sm-block">
                  <div className="input-group">
                    <span
                      className="input-group-text border-end-0"
                      id="basic-addon2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="33"
                        height="32"
                        viewBox="0 0 33 32"
                        fill="none"
                      >
                        <path
                          d="M28.1601 24.586L23.0831 19.509C24.1321 17.928 24.7461 16.035 24.7461 14C24.7461 8.486 20.2601 4 14.7461 4C9.23209 4 4.74609 8.486 4.74609 14C4.74609 19.514 9.23209 24 14.7461 24C16.7811 24 18.6741 23.386 20.2551 22.337L25.3321 27.414C26.1121 28.195 27.3801 28.195 28.1601 27.414C28.9411 26.633 28.9411 25.367 28.1601 24.586ZM7.74609 14C7.74609 10.14 10.8861 7 14.7461 7C18.6061 7 21.7461 10.14 21.7461 14C21.7461 17.86 18.6061 21 14.7461 21C10.8861 21 7.74609 17.86 7.74609 14Z"
                          fill="#55A1B2"
                        />
                      </svg>
                    </span>
                    <input
                      type="password"
                      className="form-control border-start-0"
                      placeholder="Search here..."
                      aria-label="Password"
                      aria-describedby="Password"
                    />
                  </div>
                </div>

                <div className="col-lg-4"></div>
              </div>
            </div>

            <div className="mainContent">
              <div className="row">
                <div className="col-lg-8">
                  <div className="leftPanel">
                    <div className="row">
                      <div className="col-md-12 tableTitle w-50">
                        Support Request
                      </div>
                    </div>
                    <div style={{ margin: "0px 10px" }}>

                      <DataTable className="mt-2" value={ticket_all_details} tableStyle={{ minWidth: "50rem" }} stripedRows showGridlines paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} >
                        <Column field="sr_no" header="ID" sortable></Column>
                        <Column field="date" header="Date" sortable></Column>
                        <Column field="subject" header="Subject" sortable></Column>
                        <Column field="status" header="Status" body={StatusBody}></Column>
                        <Column field="updated_date" header="Last Updated" ></Column>
                        <Column
                          field=""
                          header="Actions"
                          body={rowActionsBodyTemplate}
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="rightPanel">
                    <div className="row tableTitle">
                      Troubleshooting Details
                    </div>

                    {
                      reply_button ?
                        <div className="details p-4">
                          {ticket_messages.map((result, index) => {
                            return (
                              <div className="ticketStatus">
                                <div className="row status">
                                  <div className={ticket_messages.length === (index + 1) ? "col-2 text-center" : "col-2 text-center user"} >
                                    <div className="userInitials success" style={{ background: color_array_list[index] }}>{result.userDetails.name.substring(0, 1)}</div>
                                  </div>

                                  <div className="col-10">
                                    <Card>
                                      <Card.Header>
                                        <Row>
                                          <Col sm={5} lg={6} >{result.userDetails.name}</Col>
                                          <Col sm={5} lg={6} className="text-right">{moment(result.created_date).utc('asia/kolkata').format('DD MMM, YYYY HH:mm a')}</Col>
                                        </Row>
                                      </Card.Header>
                                      <Card.Body className={ result.user_type == 'A' ? 'text-right' : '' } >
                                        <Card.Text>{result.meesage_details}</Card.Text>
                                        {result.images_details.map((res, id) => {
                                          return (
                                            <Link to={require(`../../../../server/ticket_attachments/${res.attchment_name}`)} target='_blank' download>
                                              <AttachmentIcon /> <span style={{ color: 'blue' }} >{res.attchment_name}</span> <br />
                                            </Link>
                                          )
                                        })}
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        :
                        ""
                    }

                    {
                      reply_button ?
                        <div className="btnFooter">
                          <Button variant="danger" onClick={handleReplyClose}>
                            Cancle
                          </Button>
                          <Button
                            variant="primary"
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => setSmShow(true)}
                          >
                            Submit Reply
                          </Button>
                        </div>
                        :
                        ""
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ------------ Modal of Reply---------------- */}
      <Modal size="" show={smShow} onHide={() => setSmShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Reply</Modal.Title>
        </Modal.Header>
        <Form className="form-auth-small" id='login-form' method='POST' onSubmit={(e) => onUpdate(e)} >
          <input type="hidden" name="ticket_id" value={ticket_messages_details.ticket_id} />
          <Modal.Body>
            <Form.Group className="mb-4 mandatory" controlId="t_id">
              <Form.Label>Ticket Number</Form.Label>
              <Form.Control
                type="text"
                placeholder={ticket_messages_details.ticket_id}
                disabled="disabled"
                name="t_id"
              />
            </Form.Group>

            <Form.Group className="mb-4 mandatory" controlId="update_ticket_message">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="update_ticket_message"
                placeholder="Enter Message"
              />
            </Form.Group>

            <Form.Group className="mb-4 mandatory" controlId="ticket_status" >
              <Form.Label>Status </Form.Label>
              <Form.Select aria-label="Default select example" name="ticket_status">
                <option>Select Status</option>
                <option value="0" selected={ticket_staus === 0 ? 'selected' : false} >Open</option>
                <option value="1" selected={ticket_staus === 1 ? 'selected' : false} >Closed</option>
              </Form.Select>
            </Form.Group>

            <div className="row mb-1" style={{ marginTop: "5%" }}>
              <div className="col-12">
                <Row>
                  <Col sm={5} lg={6} >Attachment</Col>
                  <Col sm={5} lg={6} className="text-right">
                    <span className="label rounded ml-2 cursor-pointer" onClick={updateAddRow} style={{ background: "green", padding: "10px" }} ><FaPlus /></span>
                  </Col>
                </Row>
                <hr />

                {editFormVal.map((item, i) => (
                  <div key={i} >
                    <div className="row col-sm-12 p-0 mt-3 mb-2">
                      <div className="form-group col-sm-11 mb-0">
                        <input type='file' className="form-control" id="edit_ticket_attachment" name="edit_ticket_attachment" placeholder="Ticket Attachment" onChange={(e) => updateToHandle(e, i)} />
                        <div className='error_msg' style={{ color: 'red' }}>{item.updateParaIdCheck}</div>
                      </div>
                      <div className="form-group col-sm-1 m-auto text-right">
                        <span className="label rounded ml-2 cursor-pointer" onClick={() => updateToRemove(i)} style={{ background: "red", padding: "10px" }} ><FaMinus /></span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Cancle
            </Button>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* ----------------------- Modal Ends Here ----------------------- */}
    </>
  );
};

export default TroubleShooting;
