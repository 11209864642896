import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Navbar from "react-bootstrap/Navbar";
import { Dialog } from "primereact/dialog";
import { TabMenu } from "primereact/tabmenu";

import Menubar from "../menubar";
import Menubar2 from "../menubar2";

// Data File
import { DeviceService } from "../services/DeviceList";

// Icons
import { ReactComponent as InactiveIcon } from "../../../assets/Images/client/icons/inactive.svg";
import { ReactComponent as NormalIcon } from "../../../assets/Images/client/icons/normal.svg";
import { ReactComponent as OfflineIcon } from "../../../assets/Images/client/icons/offline.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/Images/client/icons/settings.svg";
import { ReactComponent as BarChartIcon } from "../../../assets/Images/client/icons/barChart.svg";
import { ReactComponent as InfoIcon } from "../../../assets/Images/client/icons/info.svg";

const Gateways = () => {
  const [Devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [activeIndex, setActiveIndex] = useState(1);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const menu = [{ label: "Devices", url: "/devices" }, { label: "Gateway" }];

  useEffect(() => {
    DeviceService.getDevicesMini().then((data) => setDevices(data));
  }, []);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();

  const getSeverity = (rowData) => {
    switch (rowData.interfaceStatus.active) {
      case "Yes":
        return "1";

      case "No":
        return "0";

      default:
        return null;
    }
  };

  const interfaceBodyTemplate = (rowData) => {
    return (
        <div className="flex flex-column align-items-center">
          <h5>{getSeverity(rowData)} / {getSeverity(rowData)} / {getSeverity(rowData)}</h5>
          <a href="./" className="mb-0">Details</a>
        </div>
    );
  };

  const ReportNameTemplate = (device) => {
    return (
      <div className="text-center">
        <h5 className="text-primary">{device.name}</h5>
        <p>{device.subName}</p>
      </div>
    );
  };

  const rowActionsBodyTemplate = (rowData) => {
    return (
      <div className="flex flex-row gap-3">
        <a
          href="/gateways/gatewaysSetting"
          tooltip="Enter your username"
          tooltipOptions={{ position: "bottom" }}
        >
          <SettingsIcon />
        </a>
        <BarChartIcon />
        <InfoIcon />
      </div>
    );
  };

  // Add Devices
  const [visible, setVisible] = useState(false);
  const footerContent = (
    <div>
      <Button
        label="Cancle"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        severity="danger"
        rounded
      />
      <Button
        label="Confirm"
        icon="pi pi-check"
        onClick={() => setVisible(false)}
        className="ms-2"
        autoFocus
        rounded
      />
    </div>
  );

  return (
    <>
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-lg-block">
            <Menubar pageName="devices" />
          </div>

          <div className="col-12 col-lg-11 ps-0">
            {/* Topbar */}
            <Navbar expand="lg" className="bg-body-tertiary w-100 pt-0 pb-0">
              <div className="topBar">
                <Container className="mw-100">
                  <Row>
                    <Col xs={4}>
                      <div className="d-lg-none d-sm-block d-block">
                        <Menubar2 />
                      </div>
                    </Col>
                    <Col xs={8} className="MobileMenu text-end">
                      <Navbar.Toggle aria-controls="navbarScroll" />
                    </Col>
                  </Row>
                </Container>

                <div className="row">
                  <Navbar.Collapse id="navbarScroll">
                    <div className="col-lg-4 title flex-wrap justify-content-center gap-3">
                      <TabMenu
                        model={menu}
                        activeIndex={activeIndex}
                        onSelect={(e) => setActiveIndex(e.index)}
                      />
                    </div>

                    <div className="col-lg-8 text-end ">
                      <Button
                        className="ms-4"
                        severity="secondary"
                        icon="pi pi-window-maximize"
                        text
                        outlined
                        rounded
                        raised
                        style={{ fontSize: "1.5rem" }}
                        tooltip="Fullscreen"
                        tooltipOptions={{ position: "bottom" }}
                      />
                    </div>
                  </Navbar.Collapse>
                </div>
              </div>
            </Navbar>

            {/* Main body */}
            <div className="MainBody p-4">
              <Container className="mw-100">
                <Row>
                  <Col>
                    <Row className="p-3 bg-white border-bottom">
                      <Col sm={6}>
                        <Row className="gap-4">
                          <Col
                            sm={2}
                            className="deviceStatus col-sm-2 flex gap-3 p-3 border align-items-center "
                          >
                            <NormalIcon width={30} height={30} />
                            <div className="flex flex-column align-items-center ">
                              <h2 className="text-success">01</h2>
                              <p className="mb-0">Normal</p>
                            </div>
                          </Col>
                          <Col
                            sm={2}
                            className="deviceStatus col-sm-2 flex gap-3 p-3 border align-items-center "
                          >
                            <OfflineIcon width={30} height={30} />
                            <div className="flex flex-column align-items-center ">
                              <h2 className="text-secondary">01</h2>
                              <p className="mb-0">Offline</p>
                            </div>
                          </Col>
                          <Col
                            sm={2}
                            className="deviceStatus col-sm-2 flex gap-3 p-3 border align-items-center "
                          >
                            <InactiveIcon width={30} height={30} />
                            <div className="flex flex-column align-items-center ">
                              <h2 className="text-primary">01</h2>
                              <p className="mb-0">Inactive</p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        sm={6}
                        className="flex justify-content-end align-items-start"
                      >
                        {header}
                        <Button
                          label="Add Device"
                          className="btn btn-primary addDashboard ms-4"
                          icon="pi pi-plus"
                          onClick={() => setVisible(true)}
                        />
                      </Col>
                    </Row>

                    <DataTable
                      value={Devices}
                      selection={selectedDevices}
                      onSelectionChange={(e) => setSelectedDevices(e.value)}
                      dataKey="id"
                      tableStyle={{ minWidth: "50rem" }}
                      stripedRows
                      globalFilterFields={[
                        "status",
                        "name",
                        "interfaceStatus",
                        "updateTime",
                      ]}
                    >
                      <Column
                        selectionMode="multiple"
                        headerStyle={{ width: "3rem" }}
                      ></Column>
                      <Column field="status" header="Status" sortable></Column>
                      <Column
                        field="name"
                        header="Name"
                        body={ReportNameTemplate}
                        className="justify-content-center"
                        sortable
                      ></Column>
                      <Column
                        field="interfaceStatus.title"
                        header="Associated Devices"
                        body={interfaceBodyTemplate}
                        className="justify-content-center"
                        headerStyle={{ align: "center" }}
                        sortable
                      ></Column>
                      <Column
                        field="updateTime"
                        header="Updated Time"
                        sortable
                      ></Column>
                      <Column
                        field=""
                        header=""
                        body={rowActionsBodyTemplate}
                      ></Column>
                    </DataTable>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>

      {/* ----------- Modal of Add Device ------------ */}
      <Dialog
        header="Add Device"
        visible={visible}
        style={{ width: "30vw" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        <Row className="mb-4">
          <Col sm={12}>
            <div className="flex flex-column gap-2 mb-4">
              <label htmlFor="dashboardName">Name of Dashboard</label>
              <InputText id="dashboardName" className="w-100" />
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="sn">SN</label>
              <InputText id="sn" className="w-100" />
            </div>
          </Col>
        </Row>
      </Dialog>
    </>
  );
};

export default Gateways;
