import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Row from "react-bootstrap/Row";

import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom'
import { Col } from "react-bootstrap";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
const moment = require('moment');


export default function InterfaceSettings() {
  const [devices, setDevices] = useState(null);
  const paramsData = useParams();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    getSettingData()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);

  const rowActionsBodyTemplate = (rowData) => {
    return (
      <Row className="flex flex-row gap-3">       
        {rowData.data?.map((data) => (
          <div class="d-flex device_reading w-25">
            <span class="label">{data.display_name}:</span>
            <span class="value">{(data.option_value * data.multi_factor) / data.divide_factor}{data.unit_symbol}</span>
          </div>
        ))}
      </Row>
    );
  };

  const getSettingData = () => {

    var postData = {
      cd_id: paramsData.d_id,
      client_id: result.client_id
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "get_devices_list_data", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        setDevices([])
        setDevices(res.data.device_data)
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const getFilterData = () => {
    var postData = {
      cd_id: paramsData.d_id,
      client_id: result.client_id,
      start_date: moment(startDate).utc('asia/kolkata').format('YYYY-MM-DD')+" 00:00:00",
      end_date: moment(endDate).utc('asia/kolkata').format('YYYY-MM-DD')+" 23:59:59"
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "get_devices_list_data_by_search", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        setDevices([])
        setDevices(res.data.device_data)
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  return (
    <>
      <Row>
        <Col sm={4} className="gap-3 flex">                    
          <Calendar
            className="w-75"
            value={startDate}
            onChange={(e) => setStartDate(e.value)}
            placeholder="Start Date"
            showIcon
          />
          <Calendar
            className="w-75"
            value={endDate}
            onChange={(e) => setEndDate(e.value)}
            placeholder="End Date"
            showIcon
          />
        </Col>
        <Col sm={3} className="gap-3 flex justify-content-center">
          <Button label="Submit" className="btn btn-primary" onClick={() => getFilterData()} rounded />
        </Col>
      </Row>
      <Row className="mt-3">
        <DataTable className="mt-2" value={devices} tableStyle={{ minWidth: "50rem" }} stripedRows showGridlines paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]}>
          <Column field="received_datetime" header="Date/Time" style={{ width: '20%' }} ></Column>
          <Column
            field=""
            header="Readings"
            body={rowActionsBodyTemplate}
          ></Column>
        </DataTable>      
      </Row>
    </>
  );
}
