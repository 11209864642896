import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "@mui/material";

const GlobalSettings = () => {
  const [selectedCity, setSelectedCity] = useState(null);

  const cities = [
    { name: "English", code: "NY" },
    { name: "Hindi", code: "RM" },
  ];


  return (
    <>
      <Container>
        <Row>
          <Col sm={7}>

            <div className="flex flex-row gap-2 align-items-center mb-4">
              <label htmlFor="display" className="font-bold w-50">
              Switch Panel Display Mode
              </label>
              <Dropdown
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.value)}
                options={cities}
                optionLabel="name"
                placeholder="Single Switch"
                className="w-100"
              />
            </div>
            
            <div className="flex flex-row gap-2 align-items-center mb-4">
              <label htmlFor="dst" className="font-bold w-50">
              System Language
              </label>
              <Dropdown
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.value)}
                options={cities}
                optionLabel="name"
                placeholder="English"
                className="w-100"
              />
            </div>
            <Divider />
            <Row className="mt-4">
              <Col>
                <Button
                  label="Cancle"
                  icon="pi pi-times"
                  severity="danger"
                  rounded
                />
                <Button
                  label="Save"
                  icon="pi pi-check"
                  className="ms-4"
                  autoFocus
                  rounded
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default GlobalSettings;
