import React from "react";
import Menubar from "./menubar";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";

const menubar2 = () => {
  return (
    <>
      {["xs"].map((expand) => (
        <Navbar key={expand} expand={expand} className="py-0">
          <Container fluid>
            <Navbar.Toggle
              className="d-block d-lg-none d-sm-block"
              aria-controls={`offcanvasNavbar-expand-${expand}`}
            />

            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Ceryletech
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Menubar />
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default menubar2;
