import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { TabView, TabPanel } from "primereact/tabview";
import { TabMenu } from "primereact/tabmenu";
import Navbar from "react-bootstrap/Navbar";

import Log from "./log";
import Menubar from "../menubar";
import Menubar2 from "../menubar2";
import GatewayBasicSettings from "./gatewayBasicSettings";

const GatewaysSettings = () => {
  const [activeIndex, setActiveIndex] = useState(1);

  const menu = [
    { label: "Devices", url: "/devices" },
    { label: "Gateway", url: "/gateways" },
  ];

  const items = [
    { label: "Gateways", url: "/gateways" },
    { label: "612C8799Y133423" },
    { label: "Basic Settings" },
  ];
  const home = { icon: "pi pi-home", url: "/dashboard" };

  return (
    <>
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-lg-block">
            <Menubar pageName="devices" />
          </div>

          <div className="col-12 col-lg-11 ps-0">
            {/* Topbar */}
            <Navbar expand="lg" className="bg-body-tertiary w-100 pt-0 pb-0">
              <div className="topBar">
                <Container className="mw-100">
                  <Row>
                    <Col xs={4}>
                      <div className="d-lg-none d-sm-block d-block">
                        <Menubar2 />
                      </div>
                    </Col>
                    <Col xs={8} className="MobileMenu text-end">
                      <Navbar.Toggle aria-controls="navbarScroll" />
                    </Col>
                  </Row>
                </Container>

                <div className="row">
                  <Navbar.Collapse id="navbarScroll">
                    <div className="col-lg-4 title flex-wrap justify-content-center gap-3">
                      <TabMenu model={menu} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)}/>
                    </div>

                    <div className="col-lg-8 text-end ">
                      <Button
                        className="ms-4"
                        severity="secondary"
                        icon="pi pi-window-maximize"
                        text
                        outlined
                        rounded
                        raised
                        style={{ fontSize: "1.5rem" }}
                        tooltip="Fullscreen"
                        tooltipOptions={{ position: "bottom" }}
                      />
                    </div>
                  </Navbar.Collapse>
                </div>
              </div>
            </Navbar>

            {/* Main body */}
            <div className="MainBody p-4 deviceSettings">
              <Container className="mw-100">
                <Row>
                  <Col sm={6}>
                    {/* Breadcrum here */}
                    <BreadCrumb model={items} home={home} />
                  </Col>
                  <Col sm={6} className="text-end">
                    <Button
                      label="Refresh"
                      className="btn btn-primary ms-4"
                      rounded
                      size="small"
                    />
                    <Button
                      label="Share"
                      className="btn btn-primary ms-4"
                      rounded
                      icon="pi pi-share-alt"
                      size="small"
                      outlined
                    />
                  </Col>
                </Row>

                <TabView>
                  {/* -------------- Basic Settings Starts here --------------- */}
                  <TabPanel header="Basic Settings">
                    <GatewayBasicSettings />
                  </TabPanel>
                  {/* -------------- Basic Settings Ends here ----------------- */}

                  {/* -------------- Maintenance Starts here --------------- */}
                  <TabPanel header="Maintenance">
                    <Row className="mh-100 justify-content-center">
                      <Col sm={4} className="text-center pt-5">
                        <Button
                          label="Replace"
                          className="btn btn-primary mb-2"
                          rounded
                        />
                        <p>Replace Gateway by SN</p>
                      </Col>
                    </Row>
                  </TabPanel>
                  {/* -------------- Maintenance Ends here --------------- */}

                  {/* ----------- Log Starts here -------------------- */}
                  <TabPanel header="Log">
                    <Log />
                  </TabPanel>
                  {/* ----------- Log Ends here ---------------------- */}
                </TabView>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GatewaysSettings;
