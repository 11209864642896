/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import Menubar from "./menubar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import { ReactComponent as AttachmentIcon } from "../../assets/Images/client/attachmentIcon.svg";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TopBar from "./topbar";

const moment = require('moment');

const TroubleShooting = () => {
  const navigate = useNavigate()
  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);
  
  const [add_status, setAddStatus] = useState(0)
  const [view_status, setViewStatus] = useState(0)
  const [reply_status, setReplyStatus] = useState(0)

  useEffect(() => {
    if (result) {
      getClientDetails();
      getTicketDetails();
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };

      var AccessData = {
        role_id: result.role_id,
        menu_id: 7,
      };      
      axios.post(global.passUrl + 'get_access_details', AccessData, axiosConfig).then((res) => {
        if (res.data.access_data.length) {
          // eslint-disable-next-line array-callback-return
          res.data.access_data.map((data1) => {
            if (data1.privileges_key === "ADD") {
              if (data1.status) {
                setAddStatus(1)
              } else {
                setAddStatus(0)
              }
            }else if (data1.privileges_key === "View_Ticket") {
              if (data1.status) {
                setViewStatus(1)
              } else {
                setViewStatus(0)
              }
            }else if (data1.privileges_key === "REPLY") {
              if (data1.status) {
                setReplyStatus(1)
              } else {
                setReplyStatus(0)
              }
            }
          })
        }
      });
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [device_details, setDeviceDetails] = useState([]);
  const [problesm_details, setProblesmDetails] = useState([]);
  const [c_id, setCId] = useState();
  const [c_name, setCName] = useState();
  const [c_email, setCEmail] = useState();
  const [c_mobileno, setCMobileNo] = useState();
  const [ticket_all_details, setTicketAllDetails] = useState([]);

  const [ticket_messages, setTicketMessages] = useState([]);
  const [ticket_messages_details, setTicketMessagesDetails] = useState([]);
  const [reply_button, setReplyButton] = useState(false);
  const [ticket_staus, setTicketStaus] = useState();


  const color_array_list = ['#adff2f', '#FF0000', '#008080', '#800080', '#FF00FF', '#00FFFF', '#008080', '#000080', '#adff2f', '#800000', '#008000', '#9932cc', '#a52a2a', '#663399', '#cc7722', '#882d17', '#8bbe1b', '#648c11', '#a7fc00', '#ceff00', '#00ced1', '#008b8b', '#00ffef', '#00b7eb', '#008b8b', '#009698', '#ffd700', '#daa520', '#ffdf00', '#00FFFF', '#008080', '#000080', '#adff2f', '#FF0000', '#008080', '#800080', '#FF00FF', '#00FFFF', '#8bbe1b', '#648c11', '#a7fc00', '#ceff00', '#008080', '#800080', '#FF00FF', '#00FFFF', '#008080', '#000080', '#adff2f', '#FF0000', '#008080', '#800080', '#FF00FF', '#00FFFF', '#008080', '#000080', '#adff2f', '#FF0000', '#008080', '#800080', '#FF00FF', '#00FFFF', '#008080', '#000080', '#adff2f', '#FF0000', '#008080', '#800080', '#FF00FF', '#00FFFF', '#008080', '#000080', '#adff2f', '#FF0000', '#008080', '#800080', '#FF00FF', '#00FFFF', '#008080', '#000080'];

  const getTicketDetails = () => {
    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);

    var postData = {
      c_id: result.usr_id
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "get_ticket_details", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        // console.log(res.data.client_details);
        const newArray = [];
        // eslint-disable-next-line array-callback-return
        res.data.client_ticket_details.map((data, index) => {
          var temp_array = {
            sr_no: index + 1,
            id: data.ticket_id,
            subject: data.ticket_subject,
            date: moment(data.created_date).utc('asia/kolkata').format('DD MMM, YYYY'),
            status: data.status,
            updated_date: moment(data.updated_date).utc('asia/kolkata').format('DD MMM, YYYY HH:mm a')
          }
          newArray.push(temp_array);
        });
        setTicketAllDetails(newArray);
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const getClientDetails = () => {
    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);

    var postData = {
      c_id: result.client_id
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "get_support_details", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        // console.log(res.data.client_details);
        setDeviceDetails(res.data.device_details);
        setProblesmDetails(res.data.problesm_details);
        setCId(result.usr_id);
        setCMobileNo(res.data.client_details.client_mobileno);
        setCEmail(res.data.client_details.client_email);
        setCName(res.data.client_details.client_name);
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const viewTicket = (t_id) => {
    var postData = {
      ticket_id: t_id
    }

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + 'get_ticket_replys', postData, axiosConfig).then((res) => {
      setReplyButton(true)
      setTicketMessages(res.data.TicketMessages)
      setTicketStaus(res.data.TicketDetails.status)
      setTicketMessagesDetails(res.data.TicketDetails)
    });
  }

  const rowActionsBodyTemplate = (rowData) => {
    return (
      <div className="flex flex-row gap-3">
        <Button
          variant="primary"
          className="p-2"
          size="sm"
          onClick={() => viewTicket(rowData.id)}
        >
          View Ticket
        </Button>
      </div>
    );
  };

  const StatusBody = (rowData) => {
    return (
      rowData.status ? <Badge bg="dark">Close</Badge> : <Badge bg="warning">Open</Badge>
    )
  }

  const [formVal, setFormVal] = useState([{ ticket_attachment: '' }])
  const addRow = () => {
    setFormVal([...formVal, { ticket_attachment: '' }])
  }
  const onRemove = (i) => {
    const newForm = [...formVal]
    newForm.splice(i, 1)
    setFormVal(newForm)
  }
  const onHandle = (e, i) => {
    let newForm = [...formVal]
    newForm[i][e.target.name] = e.target.files
    setFormVal(newForm)
  }

  const formValidation = (formVal) => {
    const data = [...formVal]
    let valid = true
    for (let index = 0; index < data.length; index++) {
      if (data[index].ticket_attachment === "") {
        data[index].paraIdCheck = "Please select parameter."
        valid = false
      } else {
        data[index].paraIdCheck = ""
        valid = true
      }
    }
    setFormVal(data)
    return valid
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const errorRes = formValidation(formVal)
    if (errorRes) {
      const formData = new FormData();
      formData.append("ticket_subject", data.get('ticket_subject'));
      formData.append("ticket_message", data.get('ticket_message'));
      formData.append("ticket_problem_type", data.get('ticket_problem_type'));
      formData.append("device_list", data.get('device_list'));
      formData.append("c_id", data.get('c_id'));

      for (let i = 0; i < formVal.length; i++) {
        formData.append("data", formVal[i]['ticket_attachment'][0]);
      }

      if (data.get('ticket_subject') === "" && data.get('ticket_message') === "" && data.get('ticket_problem_type') === "Select Problem" && data.get('device_list') === "Select Device") {
        toast.dismiss()
        toast.error("All fields are required!", { theme: "colored", })
      } else if (data.get('ticket_subject') === "") {
        toast.dismiss()
        toast.error("Ticket Subject is required!", { theme: "colored", })
      } else if (data.get('ticket_message') === "") {
        toast.dismiss()
        toast.error("Ticket Message is required!", { theme: "colored", })
      } else if (data.get('ticket_problem_type') === "Select Problem Type") {
        toast.dismiss()
        toast.error("Please Select Problem!", { theme: "colored", })
      } else if (data.get('device_list') === "Select Device") {
        toast.dismiss()
        toast.error("Please Select device!", { theme: "colored", })
      } else {
        let axiosConfig = {
          headers: {
            'Content-Type': 'charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
          }
        };

        axios.post(global.passUrl + 'submit_client_ticket_data', formData, axiosConfig)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.status === 'success') {
                toast.dismiss()
                toast.success(res.data.message, { theme: "colored", })
                setLgShow(false);
                getTicketDetails();
              } else {
                toast.dismiss()
                toast.error(res.data.message, { theme: "colored", })
              }
            } else if (res.status === 400) {
              window.location.href = '';
            }
          })
          .catch((err) => {
            alert(err);
          })
      }
    }
  }

  const handleClose = () => setSmShow(false);

  const handleReplyClose = () => setReplyButton(false);

  const handleClose2 = () => setLgShow(false);

  const openTicketsPopup = () => {
    setLgShow(true);
    setReplyButton(false)
  }

  // Constants for Modal
  // Open Submit Reply Modal
  const [smShow, setSmShow] = useState(false);
  // Open New Ticket Modal
  const [lgShow, setLgShow] = useState(false);


  const [editFormVal, setEditFormVal] = useState([{ edit_ticket_attachment: '' }])
  const updateAddRow = () => {
    setEditFormVal([...editFormVal, { edit_ticket_attachment: '' }])
  }
  const updateToRemove = (i) => {
    const newForm = [...editFormVal]
    newForm.splice(i, 1)
    setEditFormVal(newForm)
  }
  const updateToHandle = (e, i) => {
    let newForm = [...editFormVal]
    newForm[i][e.target.name] = e.target.files
    setEditFormVal(newForm)
  }


  const upateFormValidation = (editFormVal) => {
    const data = [...editFormVal]

    console.log(editFormVal)

    let valid = true
    for (let index = 0; index < data.length; index++) {
      if (data[index].edit_ticket_attachment === "") {
        data[index].updateParaIdCheck = "Please select parameter."
        valid = false
      } else {
        data[index].updateParaIdCheck = ""
        valid = true
      }
    }
    setEditFormVal(data)
    return valid
  }

  const onUpdate = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const errorRes = upateFormValidation(editFormVal)
    if (errorRes) {
      const formData = new FormData();
      formData.append("ticket_message", data.get('update_ticket_message'));
      formData.append("ticket_id", data.get('ticket_id'));
      formData.append("c_id", result.usr_id);
      formData.append("user_type", 'client');
      formData.append("ticket_status", data.get('ticket_status'));

      for (let i = 0; i < editFormVal.length; i++) {
        formData.append("data", editFormVal[i]['edit_ticket_attachment'][0]);
      }

      if (data.get('update_ticket_message') === "") {
        toast.dismiss()
        toast.error("Ticket Message is required!", { theme: "colored", })
      } else {
        let axiosConfig = {
          headers: {
            'Content-Type': 'charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
          }
        };

        axios.post(global.passUrl + 'submit_client_ticket_reply', formData, axiosConfig)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.status === 'success') {
                toast.dismiss()
                toast.success(res.data.message, { theme: "colored", })
                setSmShow(false)
                viewTicket(data.get('ticket_id'));
                getTicketDetails();
              } else {
                toast.dismiss()
                toast.error(res.data.message, { theme: "colored", })
              }
            } else if (res.status === 400) {
              window.location.href = '';
            }
          })
          .catch((err) => {
            alert(err);
          })
      }
    }
  }

  return (
    <>
      <ToastContainer/>
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-lg-block">
            <Menubar pageName="support-ticket" />
          </div>

          <div className="col-12 col-lg-11 ps-0">
            {/* Topbar */}
            <TopBar />

            {/* Main body */}
            <div className="MainBody">
              <div className="mainContent p-3">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="leftPanel">
                      <div className="row">
                        <div className="col-md-6 tableTitle w-50">
                          Support Request
                        </div>
                        <div className="col-md-6 text-end pt-4 px-4">
                          {
                            add_status === 1 ?
                              <Button
                                variant="primary"
                                className="btn btn-primary btn-sm p-2"
                                type="submit"
                                onClick={() => openTicketsPopup()}
                              >
                                Open New Ticket
                              </Button>
                            :
                            ""
                          }
                          
                        </div>
                      </div>
                      <div style={{ margin: "0px 10px" }}>

                        <DataTable className="mt-2" value={ticket_all_details} tableStyle={{ minWidth: "50rem" }} stripedRows showGridlines paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} >
                          <Column field="sr_no" header="ID" sortable></Column>
                          <Column field="date" header="Date" sortable></Column>
                          <Column field="subject" header="Subject" sortable></Column>
                          <Column field="status" header="Status" body={StatusBody}></Column>
                          <Column field="updated_date" header="Last Updated" ></Column>
                          {
                            view_status === 1 ?
                              <Column
                                field=""
                                header="Actions"
                                body={rowActionsBodyTemplate}
                              ></Column>
                            :
                            ""
                          }
                        </DataTable>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="rightPanel">
                      <div className="row tableTitle">
                        Troubleshooting Details
                      </div>

                      {
                        reply_button ?
                          <div className="details p-4">
                            {
                              ticket_messages.map((result, index) => {
                                return (
                                  <div className="ticketStatus">
                                    <div className="row status">
                                      <div className={ticket_messages.length === (index + 1) ? "col-2 text-center" : "col-2 text-center user"}>
                                        <div className="userInitials success" style={{ background: color_array_list[index] }}>{result.userDetails.name.substring(0, 1)}</div>
                                      </div>

                                      <div className="col-10">
                                        <Card>
                                          <Card.Header>
                                            <Row>
                                              <Col sm={5} lg={6} >{result.userDetails.name}</Col>
                                              <Col sm={5} lg={6} className="text-right">{moment(result.created_date).utc('asia/kolkata').format('DD MMM, YYYY HH:mm a')}</Col>
                                            </Row>
                                          </Card.Header>
                                          <Card.Body className={ result.user_type == 'U' ? 'text-right' : '' } >
                                            <Card.Text>{result.meesage_details}</Card.Text>
                                            {result.images_details.map((res, id) => {
                                              return (
                                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                <a href={require(`../../../../server/ticket_attachments/${res.attchment_name}`)} target='_blank' download rel="noreferrer" >
                                                  <AttachmentIcon /> <span style={{ color: 'blue' }} >{res.attchment_name}</span> <br />
                                                </a>
                                              )
                                            })}
                                          </Card.Body>
                                        </Card>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                          :
                          ""
                      }

                      {
                        reply_button ?
                          <div className="btnFooter">
                            <Button variant="danger" onClick={handleReplyClose}>
                              Cancle
                            </Button>
                            {
                              reply_status === 1 ?
                                <Button
                                  variant="primary"
                                  className="btn btn-primary"
                                  type="submit"
                                  onClick={() => setSmShow(true)}
                                >
                                  Submit Reply
                                </Button>
                              :
                              ""
                            }
                          </div>
                          :
                          ""
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ------------ Modal of Reply---------------- */}
      <Modal size="" show={smShow} onHide={() => setSmShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Reply</Modal.Title>
        </Modal.Header>
        <Form className="form-auth-small" id='login-form' method='POST' onSubmit={(e) => onUpdate(e)} >
          <input type="hidden" name="ticket_id" value={ticket_messages_details.ticket_id} />
          <Modal.Body>
            <Form.Group className="mb-4 mandatory" controlId="t_id">
              <Form.Label>Ticket Number</Form.Label>
              <Form.Control
                type="text"
                placeholder={ticket_messages_details.ticket_id}
                disabled="disabled"
                name="t_id"
              />
            </Form.Group>

            <Form.Group className="mb-4 mandatory" controlId="update_ticket_message">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="update_ticket_message"
                placeholder="Enter Message"
              />
            </Form.Group>

            <Form.Group className="mb-4 mandatory" controlId="ticket_status" >
              <Form.Label>Status </Form.Label>
              <Form.Select aria-label="Default select example" name="ticket_status">
                <option>Select Status</option>
                <option value="0" selected={ticket_staus === 0 ? 'selected' : false} >Open</option>
                <option value="1" selected={ticket_staus === 1 ? 'selected' : false} >Closed</option>
              </Form.Select>
            </Form.Group>

            <div className="row mb-1" style={{ marginTop: "5%" }}>
              <div className="col-12">
                <Row>
                  <Col sm={5} lg={6} >Attachment</Col>
                  <Col sm={5} lg={6} className="text-right">
                    <span className="label rounded ml-2 cursor-pointer" onClick={updateAddRow} style={{ background: "green", padding: "10px" }} ><FaPlus /></span>
                  </Col>
                </Row>
                <hr />

                {editFormVal.map((item, i) => (
                  <div key={i} >
                    <div className="row col-sm-12 p-0 mt-3 mb-2">
                      <div className="form-group col-sm-11 mb-0">
                        <input type='file' className="form-control" id="edit_ticket_attachment" name="edit_ticket_attachment" placeholder="Ticket Attachment" onChange={(e) => updateToHandle(e, i)} />
                        <div className='error_msg' style={{ color: 'red' }}>{item.updateParaIdCheck}</div>
                      </div>
                      <div className="form-group col-sm-1 m-auto text-right">
                        <span className="label rounded ml-2 cursor-pointer" onClick={() => updateToRemove(i)} style={{ background: "red", padding: "10px" }} ><FaMinus /></span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Cancle
            </Button>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* ----------------------- Modal Ends Here ----------------------- */}

      {/* ------------ Modal of Add Ticket---------------- */}
      <Modal size="xl" show={lgShow} onHide={() => setLgShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Ticket</Modal.Title>
        </Modal.Header>
        <Form className="form-auth-small" id='login-form' method='POST' onSubmit={(e) => onSubmit(e)} >
          <input type='hidden' name='c_id' value={c_id} />
          <Modal.Body>
            <div className="container">
              <div className="row mb-1">
                <div className="col-lg-4 col">
                  <Form.Group className="mb-4 mandatory" controlId="UserName">
                    <Form.Label>Name </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={c_name}
                      disabled="disabled"
                    />
                  </Form.Group>
                </div>

                <div className="col-lg-4 col">
                  <Form.Group className="mb-4 mandatory" controlId="UserEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={c_email}
                      disabled="disabled"
                    />
                  </Form.Group>
                </div>

                <div className="col-lg-4 col">
                  <Form.Group
                    className="mb-4 mandatory"
                    controlId="UserMobileNumber"
                  >
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={c_mobileno}
                      disabled="disabled"
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-lg-6 col">
                  <Form.Group className="mb-4 mandatory" controlId="DeviceList">
                    <Form.Label>Device List </Form.Label>
                    <Form.Select aria-label="Default select example" name="device_list">
                      <option>Select Device</option>
                      {device_details.map((data, index) =>
                        <option key={index} value={data.device_eui} >{data.device_name}</option>
                      )}
                    </Form.Select>
                  </Form.Group>
                </div>

                <div className="col-lg-6 col">
                  <Form.Group
                    className="mb-4 mandatory"
                    controlId="ProblemType"
                  >
                    <Form.Label>Problem Type </Form.Label>
                    <Form.Select aria-label="Default select example" name="ticket_problem_type">
                      <option>Select Problem Type </option>
                      {problesm_details.map((data, index) =>
                        <option key={index} value={data.tbs_id} >{data.problem_name}</option>
                      )}
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-12">
                  <Form.Group className="mb-4 mandatory" controlId="ticket_subject">
                    <Form.Label>Ticket Subject</Form.Label>
                    <Form.Control
                      type="text"
                      name="ticket_subject"
                      placeholder="Enter Ticket Subject"
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-12">
                  <Form.Group className="mb-4 mandatory" controlId="Message">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="ticket_message"
                      placeholder="Enter Message"
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-12">
                  <div className='row'>
                    <div className='col-sm-11'>Attachment<span className='text-danger'>*</span></div>
                    <div className='col-sm-1 text-right' style={{ paddingRight: "2rem" }}>
                      <span className="label rounded ml-2 cursor-pointer" onClick={addRow} style={{ background: "green", padding: "10px" }} ><FaPlus /></span>
                    </div>
                  </div>
                  <hr />

                  {formVal.map((item, i) => (
                    <div key={i} >
                      <div className="row col-sm-12 p-0 mt-3 mb-2">
                        <div className="form-group col-sm-11 mb-0">
                          <input type='file' className="form-control" id="ticket_attachment" name="ticket_attachment" placeholder="Ticket Attachment" onChange={(e) => onHandle(e, i)} />
                          <div className='error_msg' style={{ color: 'red' }}>{item.paraIdCheck}</div>
                        </div>
                        <div className="form-group col-sm-1 m-auto text-right">
                          <span className="label rounded ml-2 cursor-pointer" onClick={() => onRemove(i)} style={{ background: "red", padding: "10px" }} ><FaMinus /></span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose2}>
              Cancle
            </Button>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* ----------------------- Modal Ends Here ----------------------- */}
    </>
  );
};

export default TroubleShooting;
