/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import Topbar from './topbar';
import Menubar from "./menubar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/custom.css";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/Material.css";
import "@pnotify/core/BrightTheme.css";
import "@pnotify/confirm/dist/PNotifyConfirm.css";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Loader from '../../assets/Images/default.gif';

const SetPermission = () => {
    const paramsData = useParams();
    const [get_permission_details, setPermissionDetails] = useState([])
    const [role_name, setRoleName] = useState("")
    const [role_id, setRoleId] = useState("")
    const [role_loading_id, setRoleLoading] = useState(true)

    const navigate = useNavigate()
    var data1 = localStorage.getItem('userData');
    var result1 = JSON.parse(data1);

    useEffect(() => {
        if (result1) {
            getAllDetails()
        } else {
            navigate("/admin-login")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllDetails = () => {
        var postData = {
            user_id: paramsData.d_id
        };

        let axiosConfig = {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        };
        //Get all users details in bootstrap table
        axios.post(global.passUrl + "user_rights_data", postData, axiosConfig).then((res) => {
            setRoleLoading(false)
            setRoleName(res.data.roleName.role_name)
            setRoleId(res.data.roleName.role_id)
            setPermissionDetails(res.data.final_array_data)
        });
    }

    const handleUpdate = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        
        let axiosConfig = {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
            },
        };

        axios.post(global.passUrl + "update_role_permission", data, axiosConfig).then((res) => {
            console.log(res);
            if (res.status === 200) {
                toast.dismiss()
                toast.success("Role Permission Updated Successfully...", { theme: "colored", })
                setInterval(() => {
                  window.location.href = '/role';
                }, 2500);
            } else if (res.status === 400) {
                toast.dismiss();
                toast.error("Faill!!", { theme: "colored" });
            }
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        });
    }

    // const handleCheckboxChange = (id) => {
    //     setPermissionDetails((prevCheckboxes) =>
    //       prevCheckboxes.map((checkbox) =>
    //         checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
    //       )
    //     );
    // };

    const updateToHandle = (e, index, i) => {
        const res = [...get_permission_details]
        console.log(res)
        res[index]['access_right'][i]['status'] = e.target.checked
        setPermissionDetails(res)
    }

    return (
        <>
            <ToastContainer />
            <div className="conatainer otherPages">
                <div className="row">
                    <div className="col-2 col-lg-1 pe-0 d-none d-sm-block">
                        <Menubar />
                    </div>

                    <div className="col-12 col-lg-11 ps-0">
                        
                        <Topbar />

                        <div className="mainContent" style={{ padding: "15px" }}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="leftPanel">
                                        <div className="row tableTitle">Role Permission</div>
                                        <div style={{ margin: "0px 15px 15px 17px", textAlign: "center" }}>
                                            <h3>{role_name}</h3>
                                        </div>
                                        <div style={{ marginLeft: "15px",marginRight: "15px" }}>
                                            {
                                                role_loading_id ?
                                                <div className="col-sm-12 mt-4 text-center">
                                                    <img src={Loader} alt="logo" style={{ width: 30 }} /> <br />
                                                </div>
                                                :
                                                <Form
                                                    className="Details"
                                                    method="POST"
                                                    onSubmit={handleUpdate}
                                                >
                                                    <input type="hidden" name="role_id" value={role_id} />
                                                    <table className="table table-bordered border-primary">
                                                        <tbody>
                                                            {
                                                                get_permission_details?.map((prms, index) => {
                                                                    return(
                                                                        <tr key={index}>
                                                                            <td >{prms.menu_name}</td>
                                                                            <td className="d-flex" >
                                                                                { 
                                                                                    prms.access_right?.map((a,i) => {
                                                                                        return(
                                                                                            <div style={{ marginLeft: "2%"}}key={i}>                   
                                                                                                <input className="cursor-pointer" type="checkbox" id={ prms.menu_id+"_"+a.access_id } name="access_data[]" value={ prms.menu_id+"/"+a.access_id } style={{ marginRight: "5px" }} checked={a.status} onChange={(e) => updateToHandle(e, index, i)}  />                                                                                           
                                                                                                {a.access_name}                                                                                
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <div className="text-center">
                                                        <Button variant="primary" className="btn btn-primary" type="submit" > Update Permission </Button>
                                                    </div>
                                                </Form>
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SetPermission;
