import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Divider } from "@mui/material";
import { Button } from "primereact/button";

import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";

import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom'
import { ReactComponent as EditIcon } from "../../../assets/Images/client/icons/edit.svg";

export default function InterfaceSettings() {
  const [devices, setDevices] = useState(null);
  const [visible, setVisible] = useState(false);

  const [dp_id, setParameterId] = useState()
  const [upper_limit, setUpperLimit] = useState()
  const [lower_limit, setLowerLimit] = useState()

  const paramsData = useParams();

  useEffect(() => {
    getSettingData()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getSettingData = () => {
    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);

    var postData = {
      cd_id: paramsData.d_id,
      user_id: result.usr_id
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "get_interface_settings_details", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        setDevices(res.data.parameter_details)
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const priceBodyTemplate = (rowData) => {
    return (
      <div className="flex flex-row gap-3">
        <div className="flex flex-column align-items-center w-50">
          &lt;= {rowData.minThreshold}
        </div>
        <div className="flex flex-column align-items-center w-50">
          &gt;= {rowData.maxThreshold}
        </div>
      </div>
    );
  };

  const rowActionsBodyTemplate = (rowData) => {
    return (
      <div className="flex flex-row gap-3">
        <EditIcon className="cursor-pointer" onClick={() => editDetails(rowData.dp_id) } />
      </div>
    );
  };
  
  const editDetails = (id) => {  

    var postData = {
      dp_id: id
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "get_parameter_id_details", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        setVisible(true)
        setUpperLimit(res.data.parameter_details[0].upper_limit)
        setLowerLimit(res.data.parameter_details[0].lower_limit)
        setParameterId(id)
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const handleSubmit = () => {
    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);

    var postData = {
      dp_id: dp_id,
      cd_id: paramsData.d_id,
      lower_limit: lower_limit,
      upper_limit: upper_limit,
      user_id: result.usr_id
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "update_interface_setting", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        if (res.data.status === "success") {
          toast.dismiss()
          toast.success(res.data.message, { theme: "colored", })
          setVisible(false)
          getSettingData();
        } else {
          toast.dismiss()
          toast.error(res.data.message, { theme: "colored", })
        }
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  return (
    <Row>
      <DataTable className="mt-2" value={devices} tableStyle={{ minWidth: "50rem" }} stripedRows showGridlines >
        <Column field="param_name" header="parameter Name" ></Column>
        <Column field="current_value" header="Current Value" ></Column>
        <Column field="unit_symbol" header="Unit" ></Column>
        <Column field="price" header="Alarm Threshold" className="text-center" body={priceBodyTemplate} style={{ width: "20%" }} ></Column>
        <Column
          field=""
          header="Operations"
          body={rowActionsBodyTemplate}
        ></Column>
      </DataTable>
      <Divider />
      <Row className="mt-4 d-none">
        <Col>
          <Button label="Cancle" icon="pi pi-times" severity="danger" rounded />
          <Button
            label="Save"
            icon="pi pi-check"
            className="ms-4"
            autoFocus
            rounded
          />
        </Col>
      </Row>

      {/* ----------- Modal of Add Device ------------ */}
      <Dialog
        className="rightPanel"
        header="Set Interface Settings"
        visible={visible}
        style={{ width: "30vw" }}
        onHide={() => setVisible(false)}
      >
        <Row className="mb-4">
          <Col sm={12}>
            <div className="flex flex-column gap-2 mb-4">
              <label htmlFor="Name">Upper Limt</label>
              <InputText id="name" className="w-100" value={upper_limit} onChange={(e) => setUpperLimit(e.target.value)} />
            </div>

            <div className="flex flex-column gap-2 mb-4">
              <label htmlFor="email">Lower Limt</label>
              <InputText id="email" className="w-100" value={lower_limit} onChange={(e) => setLowerLimit(e.target.value)} />
            </div>
          </Col>
        </Row>
        <div className="text-right">
          <Button
            label="Cancle"
            icon="pi pi-times"
            onClick={() => setVisible(false)}
            severity="danger"
            rounded
          />
          <Button
            label="Confirm"
            icon="pi pi-check"
            onClick={() => handleSubmit()}
            className="ms-2"
            autoFocus
            rounded
          />
        </div>
      </Dialog>
      
    </Row>
  );
}
