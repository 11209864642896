import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { TabView, TabPanel } from "primereact/tabview";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Menubar from "../menubar";
import { useParams } from 'react-router-dom'
import List from "./list";
import Chart from "./DeviceChart";
import TopBar from "../deviceManagement/myDeviceTopbar";
import { useNavigate } from 'react-router-dom';

const DeviceSettings = () => {
  const paramsData = useParams();

  const navigate = useNavigate()

  const [device_eui, setDeviceEUI] = useState();

  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);

  const  updateAuditLog = (device_data) => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    var postData1 = {
      usr_id: result.usr_id,
      user_action: "Device",
      sys_remark : "Device History Open",
      device_eui: device_data
    };

    axios.post(global.passUrl + 'call_audit_log', postData1, axiosConfig).then((res) => {
      
    });
  }
  
  useEffect(() => {
    if (result) {
      var postData = {
        cd_id: paramsData.d_id,
        user_id: result.usr_id
      };
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        }
      };
  
      axios.post(global.passUrl + "get_device_details", postData, axiosConfig).then((res) => {
        if (res.status === 200) {
          const deviceDetailsArray = res.data.device_details[0];          
          setDeviceEUI(deviceDetailsArray.device_eui)
          updateAuditLog(deviceDetailsArray.device_eui)
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Faill!!", { theme: "colored", })
        }
      }).catch((err) => { console.log("AXIOS ERROR: ", err); });  
    } else {
      navigate("/")
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const items = [
    { label: "My Devices", url: "/devices" },
    { label: device_eui },
    { label: "History" },
  ];
  const home = { icon: "pi pi-home", url: "/dashboard" };

  return (
    <>
      <ToastContainer />
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-lg-block">
            <Menubar pageName="devices" />            
          </div>

          <div className="col-12 col-lg-11 ps-0">
            <TopBar />

            {/* Main body */}
            <div className="MainBody p-4 deviceSettings">
              <Container className="mw-100">
                <Row>
                  <Col sm={6}>
                    {/* Breadcrum here */}
                    <BreadCrumb model={items} home={home} />
                  </Col>
                  <Col sm={6} className="text-end">
                    <Button
                      label="Refresh"
                      className="btn btn-primary ms-4"
                      rounded
                      size="small"
                    />
                    <Button
                      label="Share"
                      className="btn btn-primary ms-4"
                      rounded
                      icon="pi pi-share-alt"
                      size="small"
                      outlined
                    />
                  </Col>
                </Row>

                <TabView>
                  
                  {/* -------------- List Starts here --------------- */}
                  <TabPanel header="List">
                    <List />
                  </TabPanel>
                  {/* -------------- List Ends here --------------- */}

                  {/* ----------- Chart Starts here -------------------- */}
                  <TabPanel header="Chart">
                    <Chart />
                  </TabPanel>
                  {/* ----------- Chart Ends here ---------------------- */}

                </TabView>

              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceSettings;
