import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Button } from "primereact/button";
import Navbar from "react-bootstrap/Navbar";
import { InputText } from "primereact/inputtext";
import { BreadCrumb } from "primereact/breadcrumb";
import { Divider } from "@mui/material";
import { Dropdown } from "primereact/dropdown";

import Menubar from "./menubar";
import Menubar2 from "./menubar2";

const AddTriggers = () => {
  const items = [
    { label: "Triggers", url: "/triggers" },
    { label: "Tigger List" },
    { label: "Edit Trigger" },
  ];
  const home = { icon: "pi pi-home", url: "/dashboard" };

  // Device Dropdown in Custom Image tab
  const [selectedDevice, setSelectedDevice] = useState(null);
  const devices = [
    { name: "Device Name 01", code: "DV1" },
    { name: "Device Name 02", code: "DV2" },
    { name: "Device Name 03", code: "DV3" },
    { name: "Device Name 04", code: "DV4" },
  ];

  return (
    <>
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-lg-block">
            <Menubar pageName="triggers" />
          </div>

          <div className="col-12 col-lg-11 ps-0">
            {/* Topbar */}
            <Navbar expand="lg" className="bg-body-tertiary w-100 pt-0 pb-0">
              <div className="topBar">
                <Container className="mw-100">
                  <Row>
                    <Col xs={4}>
                      <div className="d-lg-none d-sm-block d-block">
                        <Menubar2 />
                      </div>
                    </Col>
                    <Col xs={8} className="MobileMenu text-end">
                      <Navbar.Toggle aria-controls="navbarScroll" />
                    </Col>
                  </Row>
                </Container>

                <div className="row">
                  <Navbar.Collapse id="navbarScroll">
                    <div className="col-lg-4 title flex flex-row justify-content-start align-items-center gap-4">
                      <h4 className="text-primary">Triggers</h4>
                      <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText placeholder="Search" className="w-100" />
                      </span>
                    </div>

                    <div className="col-lg-8 text-end flex align-items-center justify-content-end">
                      <a
                        href="/AddTriggers"
                      >
                        <Button
                          label="Add Trigger"
                          className="btn btn-primary addDashboard ms-4"
                          icon="pi pi-plus"
                        />
                      </a>

                      <Button
                        className="ms-4"
                        severity="secondary"
                        icon="pi pi-window-maximize"
                        text
                        outlined
                        rounded
                        raised
                        style={{ fontSize: "1.5rem" }}
                        tooltip="Fullscreen"
                        tooltipOptions={{ position: "bottom" }}
                      />
                    </div>
                  </Navbar.Collapse>
                </div>
              </div>
            </Navbar>

            {/* Main body */}
            <div className="MainBody p-4 mapBody">
              <Container className="mw-100">
                <Row>
                  {/* Breadcrum here */}
                  <Col sm={12}>
                    <BreadCrumb model={items} home={home} />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Row
                      className="p-3 bg-white border-bottom box"
                      style={{ height: "calc(100vh - 155px)" }}
                    >
                      <Col sm={8}>
                        <Row className="mb-4">
                          <Col sm={2}>
                            <label htmlFor="title" className="font-bold w-50">
                              Title
                            </label>
                          </Col>
                          <Col sm={3}></Col>
                          <Col sm={6}>
                            <InputText
                              id="title"
                              className="w-100"
                              placeholder="Enter Title Here"
                            />
                          </Col>
                          <Col sm={1}></Col>
                        </Row>

                        <Row
                          className="p-3 ps-0 mb-4"
                          style={{
                            backgroundColor:
                              "var(--table-hover, rgba(243, 243, 243, 0.61));",
                          }}
                        >
                          <Col sm={2}>
                            <h6 className="text-primary mb-0">Condition</h6>
                          </Col>
                          <Col sm={3}>
                            <h6 className="text-primary mb-0">
                              Relationship: (A + B) & T
                            </h6>
                          </Col>
                          <Col sm={6}></Col>
                          <Col sm={1}></Col>
                        </Row>

                        <Row className="mb-4">
                          <Col sm={2}></Col>
                          <Col sm={3}>
                            <h6 className="mb-0">Condition A</h6>
                          </Col>
                          <Col sm={6}>
                            <Dropdown
                              value={selectedDevice}
                              onChange={(e) => setSelectedDevice(e.value)}
                              options={devices}
                              optionLabel="name"
                              placeholder="When the Device"
                              className="w-100"
                            />
                          </Col>
                          <Col sm={1}>
                            <Button
                              icon="pi pi-minus"
                              rounded
                              outlined
                              aria-label="Filter"
                              size="small"
                              severity="danger"
                            />
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Col sm={2}></Col>
                          <Col sm={3}></Col>
                          <Col sm={6}>
                            <Dropdown
                              value={selectedDevice}
                              onChange={(e) => setSelectedDevice(e.value)}
                              options={devices}
                              optionLabel="name"
                              placeholder="6725C37269160016    (6725C37269160016)"
                              className="w-100"
                            />
                          </Col>
                          <Col sm={1}></Col>
                        </Row>

                        <Row className="mb-4">
                          <Col sm={2}></Col>
                          <Col sm={3}></Col>
                          <Col sm={6}>
                            <Dropdown
                              value={selectedDevice}
                              onChange={(e) => setSelectedDevice(e.value)}
                              options={devices}
                              optionLabel="name"
                              placeholder="Humidity"
                              className="w-100"
                            />
                          </Col>
                          <Col sm={1}></Col>
                        </Row>

                        <Row className="mb-4">
                          <Col sm={2}></Col>
                          <Col sm={3}>
                            <h6 className="mb-0">Condition B</h6>
                          </Col>
                          <Col sm={6}>
                            <Dropdown
                              value={selectedDevice}
                              onChange={(e) => setSelectedDevice(e.value)}
                              options={devices}
                              optionLabel="name"
                              placeholder="When the Device"
                              className="w-100"
                            />
                          </Col>
                          <Col sm={1} className="flex gap-3">
                          <Button
                              icon="pi pi-minus"
                              rounded
                              outlined
                              aria-label="Filter"
                              size="small"
                              severity="danger"
                            />
                            <Button
                              icon="pi pi-plus"
                              rounded
                              outlined
                              aria-label="Filter"
                              size="small"
                              severity="primary"
                            />
                          </Col>
                        </Row>

                        <Divider />
                        <Row className="mt-4">
                          <Col>
                            <Button
                              label="Cancle"
                              icon="pi pi-times"
                              severity="danger"
                              rounded
                            />
                            <Button
                              label="Save"
                              icon="pi pi-check"
                              className="ms-4"
                              autoFocus
                              rounded
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTriggers;
