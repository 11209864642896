import React, { useState,useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Divider } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const ProfileBasicDetails = () => {
  const [u_first_name, setFirstName] = useState("");
  const [u_last_name, setLastName] = useState("");
  const [u_phone_number, setPhoneNumber] = useState("");
  const [u_email, setEmail] = useState("");

  useEffect(() => {
    
    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);

    var postData = {
      usr_id: result.usr_id
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "edit_user", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        setFirstName(res.data[0].u_first_name)
        setLastName(res.data[0].u_last_name)
        setPhoneNumber(res.data[0].usr_mobile_no)
        setEmail(res.data[0].usr_email)
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = () => {

    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);

    var postData = {
      usr_id: result.usr_id,
      u_first_name: u_first_name,
      u_last_name: u_last_name,
      u_phone_number: u_phone_number
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "update_basic_setting", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        if (res.data.status === "success"){
          toast.dismiss()
          toast.success(res.data.msg, { theme: "colored", })        
        } else {
          toast.dismiss()
          toast.error(res.data.msg, { theme: "colored", })
        }
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  return (
    <>
      <ToastContainer/>
      <Container>
        <Row>
          <Col sm={7}>
            <div className="flex flex-row gap-2 align-items-center mb-4">
              <label htmlFor="server" className="font-bold w-50">
                Email
              </label>
              <InputText id="server" className="w-100" placeholder="Enter Server here..." value={u_email} disabled />
            </div>

            <div className="flex flex-row gap-2 align-items-center mb-4">
              <label htmlFor="email" className="font-bold w-50">
                First Name
              </label>
              <InputText id="email" className="w-100" placeholder="Enter First Name" value={u_first_name} onChange={(e) => setFirstName(e.target.value)} />
            </div>

            
            <div className="flex flex-row gap-2 align-items-center mb-4">
              <label htmlFor="email" className="font-bold w-50">
                Last Name
              </label>
              <InputText id="email" className="w-100" placeholder="Enter Last Name" value={u_last_name} onChange={(e) => setLastName(e.target.value)} />
            </div>

            <div className="flex flex-row gap-2 align-items-center mb-4">
              <label htmlFor="email" className="font-bold w-50">
                Phone Number
              </label>
              <InputText id="email" className="w-100" placeholder="Enter Phone Number" value={u_phone_number} onChange={(e) => setPhoneNumber(e.target.value)} maxLength={10}/>
            </div>

            <div className="flex1 flex-row gap-2 mb-4 d-none">
              <label htmlFor="desc" className="font-bold w-50">
                Description
              </label>
              <InputTextarea
                keyfilter="int"
                placeholder="Description"
                rows={5}
                cols={30}
                className="w-100"
              />
            </div>

            <div className="flex1 flex-row gap-2 mb-4 d-none">
              <label htmlFor="interval" className="font-bold w-50">
                Reporting Interval
              </label>
              <InputNumber
                inputId="interval"
                className="w-100"
                suffix=" min"
                // value={value1}
                // onValueChange={(e) => setValue1(e.value)}
              />
            </div>
            
            <Divider />
            <Row className="mt-4">
              <Col>
                <Button
                  label="Cancle"
                  icon="pi pi-times"
                  severity="danger"
                  rounded
                />
                <Button
                  label="Save"
                  icon="pi pi-check"
                  className="ms-4"
                  autoFocus
                  rounded
                  onClick={handleSubmit}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProfileBasicDetails;
