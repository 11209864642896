import { ReactComponent as AlarmRedIcon } from "../../../assets/Images/client/icons/alarmRed.svg";
import { ReactComponent as InactiveIcon } from "../../../assets/Images/client/icons/inactive.svg";
import { ReactComponent as NormalIcon } from "../../../assets/Images/client/icons/normal.svg";
import { ReactComponent as OfflineIcon } from "../../../assets/Images/client/icons/offline.svg";

export const DeviceService = {
  getDevicesData() {
    return [
      {
        id: "1000",
        code: "f230fh0g3",
        status: <AlarmRedIcon id="alarm" />,
        name: "612C8799Y133421",
        subName: "612C8799Y133421",
        interfaceStatus: {
          title: "Product Description",
          temprature: "12",
          humidity: "23",
          co2: "34",
          battery: "70",
          active: "Yes",
          parameter: "Below Threshold",
        },
        interface: {
            name: "Humidity",
            unit: "ppm",
            currentValue: "23",
            minThreshold: "20",
            maxThreshold: "30",
        },
        owner: "Kedar D.",
        email: "kedar@ceryletech.com",
        updateTime: "2023-07-02 15:45",
      },
      {
        id: "1001",
        code: "f230fh0g3",
        status: <NormalIcon id="normal" />,
        name: "612C8799Y133421",
        subName: "612C8799Y133421",
        interfaceStatus: {
          title: "Product Description",
          temprature: "12",
          humidity: "23",
          co2: "34",
          battery: "70",
          active: "Yes",
          parameter: "Below Threshold",
        },
        interface: {
            Name: "Temprature",
            unit: "oC",
            currentValue: "23",
            minThreshold: "20",
            maxThreshold: "30"
        },
        owner: "Vaibhav K.",
        email: "vaibhav@ceryletech.com",
        updateTime: "2023-07-02 15:45",
      },
      {
        id: "1002",
        code: "f230fh0g3",
        status: <OfflineIcon id="offline" />,
        name: "612C8799Y133421",
        subName: "612C8799Y133421",
        interfaceStatus: {
          title: "Product Description",
          temprature: "12",
          humidity: "23",
          co2: "34",
          battery: "70",
          active: "No",
          parameter: "Above Threshold",
        },
        interface: {
            Name: "Temprature",
            unit: "%",
            currentValue: "23",
            minThreshold: "20",
            maxThreshold: "30"
        },
        owner: "Vaibhav K.",
        email: "vaibhav@ceryletech.com",
        updateTime: "2023-07-02 15:45",
      },
      {
        id: "1003",
        code: "f230fh0g3",
        status: <InactiveIcon id="inactive" />,
        name: "612C8799Y133421",
        subName: "612C8799Y133421",
        interfaceStatus: {
          title: "Product Description asda",
          temprature: "12",
          humidity: "23",
          co2: "34",
          battery: "70",
          active: "No",
          parameter: "Below Threshold",
        },
        interface: {
            Name: "Temprature",
            unit: "oC",
            currentValue: "23",
            minThreshold: "20",
            maxThreshold: "30"
        },
        owner: "Ranjit",
        email: "ranjit@ceryletech.com",
        updateTime: "2023-07-02 15:45",
      },{
        id: "1000",
        code: "f230fh0g3",
        status: <AlarmRedIcon id="alarm" />,
        name: "612C8799Y133421",
        subName: "612C8799Y133421",
        interfaceStatus: {
          title: "Product Description",
          temprature: "12",
          humidity: "23",
          co2: "34",
          battery: "70",
          active: "Yes",
          parameter: "Below Threshold",
        },
        interface: {
            name: "Humidity",
            unit: "ppm",
            currentValue: "23",
            minThreshold: "20",
            maxThreshold: "30",
        },
        owner: "Kedar D.",
        email: "kedar@ceryletech.com",
        updateTime: "2023-07-02 15:45",
      },
      {
        id: "1001",
        code: "f230fh0g3",
        status: <NormalIcon id="normal" />,
        name: "612C8799Y133421",
        subName: "612C8799Y133421",
        interfaceStatus: {
          title: "Product Description",
          temprature: "12",
          humidity: "23",
          co2: "34",
          battery: "70",
          active: "Yes",
          parameter: "Below Threshold",
        },
        interface: {
            Name: "Temprature",
            unit: "oC",
            currentValue: "23",
            minThreshold: "20",
            maxThreshold: "30"
        },
        owner: "Vaibhav K.",
        email: "vaibhav@ceryletech.com",
        updateTime: "2023-07-02 15:45",
      },
      {
        id: "1002",
        code: "f230fh0g3",
        status: <OfflineIcon id="offline" />,
        name: "612C8799Y133421",
        subName: "612C8799Y133421",
        interfaceStatus: {
          title: "Product Description",
          temprature: "12",
          humidity: "23",
          co2: "34",
          battery: "70",
          active: "No",
          parameter: "Above Threshold",
        },
        interface: {
            Name: "Temprature",
            unit: "%",
            currentValue: "23",
            minThreshold: "20",
            maxThreshold: "30"
        },
        owner: "Vaibhav K.",
        email: "vaibhav@ceryletech.com",
        updateTime: "2023-07-02 15:45",
      },
      {
        id: "1003",
        code: "f230fh0g3",
        status: <InactiveIcon id="inactive" />,
        name: "612C8799Y133421",
        subName: "612C8799Y133421",
        interfaceStatus: {
          title: "Product Description asda",
          temprature: "12",
          humidity: "23",
          co2: "34",
          battery: "70",
          active: "No",
          parameter: "Below Threshold",
        },
        interface: {
            Name: "Temprature",
            unit: "oC",
            currentValue: "23",
            minThreshold: "20",
            maxThreshold: "30"
        },
        owner: "Ranjit",
        email: "ranjit@ceryletech.com",
        updateTime: "2023-07-02 15:45",
      },{
        id: "1000",
        code: "f230fh0g3",
        status: <AlarmRedIcon id="alarm" />,
        name: "612C8799Y133421",
        subName: "612C8799Y133421",
        interfaceStatus: {
          title: "Product Description",
          temprature: "12",
          humidity: "23",
          co2: "34",
          battery: "70",
          active: "Yes",
          parameter: "Below Threshold",
        },
        interface: {
            name: "Humidity",
            unit: "ppm",
            currentValue: "23",
            minThreshold: "20",
            maxThreshold: "30",
        },
        owner: "Kedar D.",
        email: "kedar@ceryletech.com",
        updateTime: "2023-07-02 15:45",
      },
      {
        id: "1001",
        code: "f230fh0g3",
        status: <NormalIcon id="normal" />,
        name: "612C8799Y133421",
        subName: "612C8799Y133421",
        interfaceStatus: {
          title: "Product Description",
          temprature: "12",
          humidity: "23",
          co2: "34",
          battery: "70",
          active: "Yes",
          parameter: "Below Threshold",
        },
        interface: {
            Name: "Temprature",
            unit: "oC",
            currentValue: "23",
            minThreshold: "20",
            maxThreshold: "30"
        },
        owner: "Vaibhav K.",
        email: "vaibhav@ceryletech.com",
        updateTime: "2023-07-02 15:45",
      },
      {
        id: "1002",
        code: "f230fh0g3",
        status: <OfflineIcon id="offline" />,
        name: "612C8799Y133421",
        subName: "612C8799Y133421",
        interfaceStatus: {
          title: "Product Description",
          temprature: "12",
          humidity: "23",
          co2: "34",
          battery: "70",
          active: "No",
          parameter: "Above Threshold",
        },
        interface: {
            Name: "Temprature",
            unit: "%",
            currentValue: "23",
            minThreshold: "20",
            maxThreshold: "30"
        },
        owner: "Vaibhav K.",
        email: "vaibhav@ceryletech.com",
        updateTime: "2023-07-02 15:45",
      },
      {
        id: "1003",
        code: "f230fh0g3",
        status: <InactiveIcon id="inactive" />,
        name: "612C8799Y133421",
        subName: "612C8799Y133421",
        interfaceStatus: {
          title: "Product Description asda",
          temprature: "12",
          humidity: "23",
          co2: "34",
          battery: "70",
          active: "No",
          parameter: "Below Threshold",
        },
        interface: {
            Name: "Temprature",
            unit: "oC",
            currentValue: "23",
            minThreshold: "20",
            maxThreshold: "30"
        },
        owner: "Ranjit",
        email: "ranjit@ceryletech.com",
        updateTime: "2023-07-02 15:45",
      },
    ];
  },

  getDevicesMini() {
    return Promise.resolve(this.getDevicesData().slice(0, 5));
  },

  getDevicesSmall() {
    return Promise.resolve(this.getDevicesData().slice(0, 10));
  },

  getDevices() {
    return Promise.resolve(this.getDevicesData());
  },

  getDevicesWithOrdersSmall() {
    return Promise.resolve(this.getDevicesWithOrdersData().slice(0, 10));
  },

  getDevicesWithOrders() {
    return Promise.resolve(this.getDevicesWithOrdersData());
  },
};
