import React from "react";
import logoIMG from "../../assets/Images/auth/logoImg.png";
import {ReactComponent as TicketLogo}from "../../assets/Images/admin/sidebar/ticket.svg";
import {ReactComponent as ReactLogo} from "../../assets/Images/admin/sidebar/dashboard.svg";
import {ReactComponent as ClientLogo} from "../../assets/Images/admin/sidebar/client.svg";
import {ReactComponent as Device} from "../../assets/Images/admin/sidebar/device.svg";
import {ReactComponent as ParameterLogo} from "../../assets/Images/admin/sidebar/parameter.svg";
import {ReactComponent as Troubleshoot} from "../../assets/Images/admin/sidebar/troubleshoot.svg";
import {ReactComponent as Unit} from "../../assets/Images/admin/sidebar/unit.svg";
import {ReactComponent as Users} from "../../assets/Images/admin/sidebar/users.svg";
import {ReactComponent as SignOut} from "../../assets/Images/admin/sidebar/signOut.svg";

const adminMenubar = () => {
  
  const currentPathName = window.location.pathname
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/admin-login";
  }

  return (
    <div className="adminSideMenu">
      <div className="d-flex flex-column flex-shrink-0 menu">
        <a
          href="/admin-dashboard"
          className="d-none d-sm-block p-3 link-dark text-decoration-none w-100"
          title=""
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          data-bs-original-title="Icon-only"
        >
          <img src={logoIMG} className="w-100" alt="logo_name"/>
          <span className="visually-hidden">Icon-only</span>
        </a>


        <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
          {/* Dashboard  */}
          <li className="nav-item">
            <a
              href="/admin-dashboard"
              className={currentPathName === '/admin-dashboard' ? "nav-link py-3 active" : "nav-link py-3"}
              aria-current="page"
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Home"
            >
              <ReactLogo />
            </a>
          </li>

          {/* Client */}
          <li>
            <a
              href="/clients"
              className={currentPathName === '/clients' ? "nav-link py-3 active" : "nav-link py-3"}
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Dashboard"
            >
              <ClientLogo />
            </a>
          </li>

          {/* Users */}
          <li>
            <a
              href="/admin-users"
              className={currentPathName === '/admin-users' ? "nav-link py-3 active" : "nav-link py-3"}
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Orders"
            >
              <Users />
            </a>
          </li>

          {/* Client Device */}
          <li>
            <a
              href="/clientDeviceManagement"
              className={currentPathName === '/clientDeviceManagement' ? "nav-link py-3 active" : "nav-link py-3"}
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Products"
            >
              <Device />
            </a>
          </li>

          {/* Unit */}
          <li>
            <a
              href="/units"
              className={currentPathName === '/units' ? "nav-link py-3 active" : "nav-link py-3"}
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Customers"
            >
              <Unit />
            </a>
          </li>

          {/* Parameter */}
          <li>
            <a
              href="/parameters"
              className={currentPathName === '/parameters' ? "nav-link py-3 active" : "nav-link py-3"}
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Customers"
            >
              <ParameterLogo />
            </a>
          </li>

          {/* Troubleshoot */}
          <li>
            <a
              href="/troubleshooting"
              className={currentPathName === '/troubleshooting' ? "nav-link py-3 active" : "nav-link py-3"}
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Customers"
            >
              <Troubleshoot />
            </a>
          </li>

          {/* Ticket */}
          <li>
            <a
              href="/supportTicket"
              className={currentPathName === '/supportTicket' ? "nav-link py-3 active" : "nav-link py-3"}
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Customers"
            >
              <TicketLogo />
            </a>
          </li>

          {/* privileges-role */}
          <li>
            <a
              href="/privileges-role"
              className={currentPathName === '/privileges-role' ? "nav-link py-3 active" : "nav-link py-3"}
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Customers"
            >
              <img src={require('../../assets/Images/admin/sidebar/coordination.png')} alt='image_name' style={{ width: 35 , height: 35}} />
            </a>
          </li>

          
          {/* privileges-role */}
          <li>
            <a
              href="/site-map"
              className={currentPathName === '/site-map' ? "nav-link py-3 active" : "nav-link py-3"}
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Customers"
            >
              <img src={require('../../assets/Images/admin/sidebar/placeholder.png')} alt='image_name' style={{ width: 35 , height: 35}} />
            </a>
          </li>

          {/* Signout */}
          <li className="signOut">
            <div
              onClick={handleLogout}
              className="nav-link py-3 logout"
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Customers"
            >
              <SignOut />
            </div>
          </li>
        </ul>
        


      </div>
    </div>
  );
};

export default adminMenubar;
