import React from "react";
import Menubar2 from "./adminMenubar2";
import Menubar from "./adminMenubar";
import Table from 'react-bootstrap/Table';
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from 'react';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from 'react-router-dom';
const Dashboard = () => {

  const [client_count, setClientCount] = useState(0)
  const [user_count, setUserCount] = useState(0)
  const [ticket_count, setTicketCount] = useState(0)
  const [client_device_count, setClientDevicesCount] = useState(0)
  const [client_details, setClientDetails] = useState([]);
  const [client_device_details, setClientDeviceDetails] = useState([]);
  const [client_device_data, setClientDeviceStatus] = useState(false);
  const navigate = useNavigate()

  var data1 = localStorage.getItem('adminLoginData');
  var result1 = JSON.parse(data1);

  useEffect(() => {
    if (result1) {
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };
      //Get all users details in bootstrap table
      axios.post(global.passUrl + 'get_dashboard_details', axiosConfig).then((res) => {
        setClientCount(res.data.client_data);
        setUserCount(res.data.user_data);
        setTicketCount(res.data.ticket_count);
        setClientDevicesCount(res.data.client_devices_data)
        setClientDetails(res.data.client_details)
  
        getNoUserCount(res.data.client_details[0].client_id);
      });
    } else {
      navigate("/admin-login")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])  

  const getNoUserCount = (id) => {
    if (id === "") {
      setClientDeviceStatus(false)
    } else {
      var postData = {
        client_id: id,
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      axios
        .post(global.passUrl + "getDeviceDetails", postData, axiosConfig)
        .then((res) => {
          if (res.status === 200) {
            setClientDeviceStatus(true);
            setClientDeviceDetails(res.data.client_devices_data)
          } else if (res.status === 400) {
            toast.dismiss();
            toast.error("Faill...", { theme: "colored" });
          }
        })
        .catch((err) => {
          // alert(err);
        });
    }
  }
  return (
    <>
      <ToastContainer />
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-lg-block">
            <Menubar />
          </div>

          <div className="col-12 col-lg-11 ps-0">
            <div className="topBar">
              <div className="row">
                <div className="col-lg-4 title">
                  <div className="d-lg-none d-sm-block d-block">
                    <Menubar2 />
                  </div>
                  <h3>Dashboard</h3>
                </div>

                <div className="col-lg-4 search d-none d-sm-block">
                  <div className="input-group">
                    <span
                      className="input-group-text border-end-0"
                      id="basic-addon2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="33"
                        height="32"
                        viewBox="0 0 33 32"
                        fill="none"
                      >
                        <path
                          d="M28.1601 24.586L23.0831 19.509C24.1321 17.928 24.7461 16.035 24.7461 14C24.7461 8.486 20.2601 4 14.7461 4C9.23209 4 4.74609 8.486 4.74609 14C4.74609 19.514 9.23209 24 14.7461 24C16.7811 24 18.6741 23.386 20.2551 22.337L25.3321 27.414C26.1121 28.195 27.3801 28.195 28.1601 27.414C28.9411 26.633 28.9411 25.367 28.1601 24.586ZM7.74609 14C7.74609 10.14 10.8861 7 14.7461 7C18.6061 7 21.7461 10.14 21.7461 14C21.7461 17.86 18.6061 21 14.7461 21C10.8861 21 7.74609 17.86 7.74609 14Z"
                          fill="#55A1B2"
                        />
                      </svg>
                    </span>
                    <input
                      type="password"
                      className="form-control border-start-0"
                      placeholder="Search here..."
                      aria-label="Password"
                      aria-describedby="Password"
                    />
                  </div>
                </div>

                <div className="col-lg-4"></div>
              </div>
            </div>

            <mainContent>
              <div className="card-container row">
                
                <a href="/clients" className="col-lg-2 card cardPink">
                  <div className="cardImg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_296_14818)">
                        <path
                          d="M9.50002 8.88749C7.58752 8.88749 5.90002 7.31249 5.90002 5.28749C5.90002 3.26249 7.47502 1.79999 9.50002 1.79999C11.4125 1.79999 13.1 3.37499 13.1 5.39999C13.1 7.42499 11.4125 8.88749 9.50002 8.88749ZM9.50002 2.58749C8.03752 2.58749 6.80002 3.82499 6.80002 5.28749C6.80002 6.74999 7.92502 7.98749 9.50002 7.98749C11.075 7.98749 12.2 6.74999 12.2 5.28749C12.2 3.82499 10.9625 2.58749 9.50002 2.58749Z"
                          fill="white"
                        />
                        <path
                          d="M15.125 15.1875C15.125 11.475 12.5375 8.54999 9.38752 8.54999C6.23752 8.54999 3.65002 11.475 3.65002 15.1875H15.125Z"
                          fill="white"
                        />
                        <path
                          d="M15.125 15.6375H3.08752V15.1875C3.08752 11.25 5.90002 8.09999 9.27502 8.09999C12.65 8.09999 15.4625 11.25 15.4625 15.075V15.525L15.125 15.6375ZM3.98752 14.7375H14.675C14.5625 11.5875 12.2 8.99999 9.38752 8.99999C6.46252 8.99999 4.21252 11.5875 3.98752 14.7375ZM12.9875 8.77499C12.7625 8.77499 12.5375 8.54999 12.5375 8.32499C12.5375 8.09999 12.7625 7.87499 12.9875 7.87499C14.1125 7.87499 15.125 6.97499 15.125 5.73749C15.125 4.83749 14.5625 4.04999 13.6625 3.71249C13.4375 3.59999 13.325 3.37499 13.4375 3.14999C13.55 2.92499 13.775 2.81249 14 2.92499C15.2375 3.26249 16.025 4.38749 16.025 5.73749C15.9125 7.42499 14.5625 8.77499 12.9875 8.77499Z"
                          fill="white"
                        />
                        <path
                          d="M17.4875 14.175H16.475C16.25 14.175 16.025 13.95 16.025 13.725C16.025 13.5 16.25 13.275 16.475 13.275H17.0375C16.8125 10.8 15.0125 8.88749 12.875 8.88749C12.65 8.88749 12.425 8.66249 12.425 8.43749C12.425 8.21249 12.65 7.98749 12.875 7.98749C15.6875 7.98749 17.9375 10.575 17.9375 13.725V14.175H17.4875Z"
                          fill="white"
                        />
                        <path
                          d="M5.78755 8.77501C5.67505 8.77501 5.67505 8.77501 5.78755 8.77501C5.00005 8.77501 4.21255 8.55001 3.65005 7.98751C3.08755 7.42501 2.86255 6.63751 2.86255 5.85001C2.86255 4.61251 3.65005 3.48751 4.88755 3.03751C5.11255 2.92501 5.33755 3.03751 5.45005 3.26251C5.56255 3.48751 5.45005 3.71251 5.22505 3.82501C4.21255 4.16251 3.65005 4.95001 3.65005 5.85001C3.65005 6.41251 3.87505 6.97501 4.21255 7.31251C4.55005 7.76251 5.11255 7.87501 5.67505 7.98751C6.01255 7.98751 6.12505 8.21251 6.12505 8.43751C6.12505 8.66251 6.01255 8.77501 5.78755 8.77501Z"
                          fill="white"
                        />
                        <path
                          d="M2.07498 14.175H0.724976V13.725C0.724976 10.575 2.97498 8.10001 5.78748 8.10001C6.01248 8.10001 6.23748 8.32501 6.23748 8.55001C6.23748 8.77501 6.01248 9.00001 5.78748 9.00001C3.64998 9.00001 1.84998 10.9125 1.62498 13.3875H2.18748C2.41248 13.3875 2.63748 13.6125 2.63748 13.8375C2.52498 13.95 2.41248 14.175 2.07498 14.175Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_296_14818">
                          <rect
                            width="18"
                            height="18"
                            fill="white"
                            transform="translate(0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>

                  <div className="cardtext">{client_count}</div>

                  <div className="cardTtitle">Total Clients</div>
                </a>              

                <a href="/admin-users"  className="col-lg-2 card cardSuccess">
                  <div className="cardImg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M14.625 5.625C14.625 6.66053 13.7855 7.5 12.75 7.5C11.7145 7.5 10.875 6.66053 10.875 5.625C10.875 4.58947 11.7145 3.75 12.75 3.75C13.7855 3.75 14.625 4.58947 14.625 5.625Z"
                        fill="#A8A8A8"
                      />
                      <path
                        d="M3.375 5.625C3.375 6.66053 4.21447 7.5 5.25 7.5C6.28553 7.5 7.125 6.66053 7.125 5.625C7.125 4.58947 6.28553 3.75 5.25 3.75C4.21447 3.75 3.375 4.58947 3.375 5.625Z"
                        fill="#A8A8A8"
                      />
                      <path
                        d="M16.5 12.375C16.5 13.4105 15.1568 14.25 13.5 14.25C11.8432 14.25 10.5 13.4105 10.5 12.375C10.5 11.3395 11.8432 10.5 13.5 10.5C15.1568 10.5 16.5 11.3395 16.5 12.375Z"
                        fill="#A8A8A8"
                      />
                      <path
                        d="M1.5 12.375C1.5 13.4105 2.84314 14.25 4.5 14.25C6.15685 14.25 7.5 13.4105 7.5 12.375C7.5 11.3395 6.15685 10.5 4.5 10.5C2.84314 10.5 1.5 11.3395 1.5 12.375Z"
                        fill="#A8A8A8"
                      />
                      <path
                        d="M11.625 5.625C11.625 7.07475 10.4497 8.25 9 8.25C7.55025 8.25 6.375 7.07475 6.375 5.625C6.375 4.17525 7.55025 3 9 3C10.4497 3 11.625 4.17525 11.625 5.625Z"
                        fill="white"
                      />
                      <path
                        d="M13.5 12.375C13.5 13.8247 11.4853 15 9 15C6.51472 15 4.5 13.8247 4.5 12.375C4.5 10.9253 6.51472 9.75 9 9.75C11.4853 9.75 13.5 10.9253 13.5 12.375Z"
                        fill="white"
                      />
                    </svg>
                  </div>

                  <div className="cardtext">{user_count}</div>

                  <div className="cardTtitle">Total User</div>
                </a>

                <a href="/clientDeviceManagement"  className="col-lg-2 card cardWarning">
                  <div className="cardImg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_296_15002)">
                        <path
                          d="M15.6875 12.9375H13.9196V14.625H15.6875V12.9375ZM3.3125 14.625H5.08036V12.9375H3.3125V14.625Z"
                          stroke="#14778E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.6875 4.5H17.0312C17.2716 4.5 17.5021 4.61112 17.6721 4.80891C17.842 5.0067 17.9375 5.27497 17.9375 5.55469V11.8828C17.9375 12.1625 17.842 12.4308 17.6721 12.6286C17.5021 12.8264 17.2716 12.9375 17.0312 12.9375H2.53125C2.2909 12.9375 2.06039 12.8264 1.89043 12.6286C1.72048 12.4308 1.625 12.1625 1.625 11.8828V5.55469C1.625 5.27497 1.72048 5.0067 1.89043 4.80891C2.06039 4.61112 2.2909 4.5 2.53125 4.5H5.25"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.8125 9C9.3658 9 10.625 7.61488 10.625 5.90625C10.625 4.19762 9.3658 2.8125 7.8125 2.8125C6.2592 2.8125 5 4.19762 5 5.90625C5 7.61488 6.2592 9 7.8125 9Z"
                          stroke="#14778E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_296_15002">
                          <rect
                            width="18"
                            height="18"
                            fill="white"
                            transform="translate(0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>

                  <div className="cardtext">{client_device_count}</div>

                  <div className="cardTtitle">Total Devices</div>
                </a>

                <a href="/supportTicket"  className="col-lg-2 card cardinfo">
                  <div className="cardImg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_296_15029)">
                        <path
                          d="M15.6289 8.12338H3.37123C2.32705 8.12338 1.47778 8.97276 1.47778 10.017V11.7707V12.3324L2.03346 12.4119C2.35462 12.4579 2.59702 12.7374 2.59702 13.0617C2.59702 13.386 2.35476 13.6654 2.03374 13.7114L1.47778 13.791V14.3527V16.1067C1.47778 17.1506 2.32705 18 3.37123 18H15.6289C16.6731 18 17.5225 17.1506 17.5225 16.1067V14.3644V13.7461L16.9048 13.7172C16.5538 13.7009 16.2787 13.4129 16.2787 13.0617C16.2787 12.7108 16.5538 12.4228 16.905 12.4062L17.5225 12.377V11.759V10.017C17.5225 8.97276 16.6731 8.12338 15.6289 8.12338ZM16.8744 11.759C16.1823 11.7919 15.6309 12.3615 15.6309 13.0617C15.6309 13.7621 16.1823 14.3321 16.8744 14.3644V16.1067C16.8744 16.7945 16.3167 17.3522 15.6289 17.3522H3.37123C2.68326 17.3522 2.12557 16.7945 2.12557 16.1067V14.3527C2.75807 14.262 3.24481 13.7195 3.24481 13.0617C3.24481 12.4041 2.75803 11.8616 2.12557 11.7707V10.017C2.12557 9.32886 2.68326 8.77117 3.37123 8.77117H15.6289C16.3167 8.77117 16.8744 9.32886 16.8744 10.017V11.759Z"
                          fill="white"
                        />
                        <path
                          d="M5.62606 15.4483H5.19751V16.5663H5.62606V15.4483Z"
                          fill="white"
                        />
                        <path
                          d="M5.62606 13.4847H5.19751V14.6024H5.62606V13.4847Z"
                          fill="white"
                        />
                        <path
                          d="M5.62606 11.5207H5.19751V12.6387H5.62606V11.5207Z"
                          fill="white"
                        />
                        <path
                          d="M5.62606 9.5571H5.19751V10.675H5.62606V9.5571Z"
                          fill="white"
                        />
                        <path
                          d="M4.4109 6.35477L4.03638 6.56296L4.57993 7.53964L4.95431 7.33144L4.4109 6.35477Z"
                          fill="white"
                        />
                        <path
                          d="M17.991 6.29385L17.1439 4.7712L16.8431 4.2312L16.2894 4.50633C15.9748 4.66249 15.5944 4.54465 15.424 4.23777C15.2529 3.93117 15.3536 3.54565 15.6522 3.36062L16.1779 3.03486L15.877 2.49458L15.0301 0.972528C14.5225 0.0602586 13.3674 -0.269226 12.4547 0.238712L1.74358 6.19801C0.831096 6.70567 0.501928 7.86108 1.00958 8.77342L1.04035 8.82889C1.15531 8.60411 1.30156 8.39848 1.47361 8.21686C1.30476 7.66691 1.53556 7.05516 2.05847 6.76399L12.7699 0.804657C13.3711 0.47004 14.1295 0.686251 14.4642 1.28742L15.311 2.80976C14.7221 3.17468 14.5174 3.94116 14.8577 4.55288C15.198 5.16463 15.957 5.39459 16.5777 5.08627L17.4248 6.60895C17.6805 7.06795 17.6124 7.61766 17.2992 8.00118C17.4671 8.14043 17.6153 8.30204 17.744 8.47821C18.2679 7.89342 18.3938 7.01771 17.991 6.29385Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_296_15029">
                          <rect
                            width="18"
                            height="18"
                            fill="white"
                            transform="translate(0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>

                  <div className="cardtext">{ticket_count}</div>

                  <div className="cardTtitle">Service Tickets</div>
                </a>
              </div>

              <div className="" style={{ padding: '0px 46px' }}>
                <div className="row">
                  <div className="col-lg-6 deviceList">
                    <div className="row mb-3">
                      <h4>Device List with Clients</h4>
                    </div>
                    <div className="row mb-3 justify-content-md-left">
                      <Form.Group
                        className="mb-4 mandatory col-lg-6"
                        controlId="ClientName"
                      >
                        <Form.Label>Select Client Name </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          id="client_id"
                          name="client_id"
                          onChange={(e) => getNoUserCount(e.target.value)}
                        >
                          {client_details.map((data, index) => (
                            <option value={data.client_id} key={index}>
                              {data.client_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </div>
                    
                    {
                      client_device_data ?
                      <div className="admin_device_table">
                        <Table striped bordered hover responsive="xl">
                          <thead>
                            <tr>
                              <th width="60">#</th>
                              <th>Device Name</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {client_device_details.map((result, index) => {
                                return (                              
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{result.device_name}</td>
                                  <td>
                                    {result.device_log_type === "online" ? <Badge bg="success">Connect</Badge> : <Badge bg="danger">Not Connect</Badge> }                                
                                  </td>
                                </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </div>
                      :
                      ''
                    }
                    
                  </div>
                </div>
              </div>
            </mainContent>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
