import React from "react";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Outlet } from "react-router-dom";

const Layout = () => {

  const navigate = useNavigate()
  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);

  var data1 = localStorage.getItem('adminLoginData');
  var result1 = JSON.parse(data1);
  
  useEffect(() => {
    if(result){
      navigate("/dashboard")
    }

    if(result1){
      navigate("/admin-dashboard")
    }
  });

  return <>
    <Outlet />
  </>;
};

export default Layout;
