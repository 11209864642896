import React, { useState, useEffect } from "react";
import Layout from "./layout/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Temprature from "./widgets/temprature";
import ChartDelete from "./widgets/chartdelete";
import Clock from "./widgets/clock";
import ClockIcons from "../../assets/Images/client/widgets/clock.png";
import Card from "react-bootstrap/Card";
import { Chart } from "primereact/chart";
import { ReactComponent as Bell } from "../../assets/Images/client/icons/bell.svg";
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import axios from "axios";
const moment = require('moment');

const Dashboard = () => {
  const navigate = useNavigate()
  const [device_list_details, setDeviceListDetails] = useState();
  const [check_data_added, setCheckDataAdded] = useState(true);
  const [time, setTime] = useState(moment().format('h:mm:ss a'))

  const [chartData, setChartData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState([]);
  const [pieChartOptions, setPieChartOptions] = useState([]);
  const [columnChartOptions, setColumnChartOptions] = useState([]);
  const [alarm_details, setAlarmDetails] = useState([]);

  // const [add_status, setAddStatus] = useState(false);
  const [delete_status, setDeleteStatus] = useState(0);

  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);

  const loading_dashbaord_data = () => {
    setInterval(() => {
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };

      var postData1 = {
        usr_id: result.usr_id,
        client_id: result.client_id,
      };

      axios.post(global.passUrl + 'get_dashbaord_details', postData1, axiosConfig).then((res) => {
        setDeviceListDetails([]);
        setDeviceListDetails(res.data.deviceListDetails);
        setCheckDataAdded(res.data.checkDataAdded);
        setAlarmDetails(res.data.alarmDetails)
        setPieChartData(res.data.linde_data)
      });

      axios.post(global.passUrl + 'get_dashbaord_chart_details', postData1, axiosConfig).then((res) => {
        setChartData(res.data.linde_data)
      });

      // Line Chart
      const documentStyle = getComputedStyle(document.documentElement);
      const textColorSecondary = documentStyle.getPropertyValue(
        "--text-color-secondary"
      );

      const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

      const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              // color: '#000',
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
      };
      setChartOptions(options);

      const column_options = {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      };

      setColumnChartOptions(column_options);

      const pie_options = {
        plugins: {
          legend: {
            labels: {
              usePointStyle: true
            }
          }
        }
      };
      setPieChartOptions(pie_options);
    }, 30000);
  }
  
  const updateTime = () => {
    setInterval(() => {
      setTime(moment().format('h:mm:ss a'))
    }, 100);
  }

  
  const  updateAuditLog = () => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    var postData1 = {
      usr_id: result.usr_id,
      user_action: "Dashbaord Open",
      sys_remark : "Dashbaord Open",
      device_eui: ""
    };

    axios.post(global.passUrl + 'call_audit_log', postData1, axiosConfig).then((res) => {      
    });
  }

  useEffect(() => {
    if (result) {
      loading_dashbaord_data();
      updateTime();

      updateAuditLog()
  
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };

      var postData1 = {
        usr_id: result.usr_id,
        client_id: result.client_id,
        added_time: result.updated_on === null ? result.created_on : result.updated_on
      };

      axios.post(global.passUrl + 'get_dashbaord_details', postData1, axiosConfig).then((res) => {
        setDeviceListDetails([]);
        setDeviceListDetails(res.data.deviceListDetails);
        setCheckDataAdded(res.data.checkDataAdded);
        setAlarmDetails(res.data.alarmDetails)
        setPieChartData(res.data.linde_data)

        // if (res.data.user_data.first_time_pass_change === "0") {
        //   navigate("/first-login")
        // }
      });

      axios.post(global.passUrl + 'get_dashbaord_chart_details', postData1, axiosConfig).then((res) => {
        setChartData(res.data.linde_data)
      });

      
      var AccessData = {
        role_id: result.role_id,
        menu_id: 1,
      };      
      axios.post(global.passUrl + 'get_access_details', AccessData, axiosConfig).then((res) => {
        // eslint-disable-next-line array-callback-return
        res.data.access_data.map((data1, index) => {
          if (data1.privileges_key === "DELETE") {
            if (data1.status) {
              setDeleteStatus(1)
            } else {
              setDeleteStatus(0)
            }
          }
        })
      });

      // Line Chart
      const documentStyle = getComputedStyle(document.documentElement);
      const textColorSecondary = documentStyle.getPropertyValue(
        "--text-color-secondary"
      );

      const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

      const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              // color: '#000',
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
      };
      setChartOptions(options);

      const column_options = {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      };

      setColumnChartOptions(column_options);
      
      const pie_options = {
        plugins: {
          legend: {
            labels: {
              usePointStyle: true
            }
          }
        }
      };
      setPieChartOptions(pie_options);
    } else {
      navigate("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ToastContainer />
      <Layout>
        <Container className="mw-100">
          <Row>         
            {
              check_data_added
                ?
                ''
                :
                <p className="text-center mt-5">
                  You have not added any widgets yet! <br />
                  Please click Add "on the upper right corner to start adding widgets. "
                </p>
            }
            {
              device_list_details?.map((c, d) => (
                c.type === 'C' ?
                  c.clock_type === 'DefaultClock' ?
                    <Col sm={3} lg={2} key={d} >
                      <Clock
                        image="clock"
                        time={time}
                        day={moment().format('dddd')}
                        date={moment().format('LL')}
                        htmlFor="ingredient1"
                        widgetIcon={ClockIcons}
                        widgetPID={c.dc_id}
                        widgetDeleteIcon={delete_status}
                      />
                    </Col>
                    :
                    <Col sm={3} lg={2} key={d} >
                      <Clock
                        image="clock blue"
                        time={time}
                        day={moment().format('dddd')}
                        date={moment().format('LL')}
                        htmlFor="ingredient2"
                        widgetIcon={ClockIcons}
                        widgetPID={c.dc_id}
                        widgetDeleteIcon={delete_status}
                      />
                    </Col>
                  :
                  <Col sm={3} lg={2} key={d} >
                    <Temprature
                      status="security text-success"
                      Name={c.device_name}
                      widgetIcon={c.image_name === null ? 'controls.png' : c.image_name}
                      widgetValue={(c.option_value)}
                      widgetTitle={c.display_name}
                      widgetID={c.device_eui}
                      widgetPID={c.dc_id}
                      widgetUnitSymbol={c.unit_symbol}
                      widgetColorCode={c.color_code}   
                      widgetDeleteIcon={delete_status}                   
                    />
                  </Col>
              ))
            }
          </Row>

          <Row>
            {
              alarm_details?.map((c, d) => (
                <Col sm={6} lg={4}>
                  <Card className="infoBox">
                    <ChartDelete 
                      widgetPID={c.id_data}
                      widgetType="alaram"
                      widgetName="Resent Alarm Notification"
                      widgetDeleteIcon={delete_status}  
                    />
                    <Card.Body className="d-block" style={{ maxHeight: "320px", overflowY: "scroll" }}>
                      {c.data?.map((d, i) => (
                        <Row className="p-3 row border-bottom">
                          <Col sm={3}>
                            <Bell />
                          </Col>
                          <Col sm={9}>
                            <h4>{d.device_name}</h4>
                            <h5 className={d.log_status === "offline" || d.log_status === "in_active" ? "text-danger" : "text-success"}>
                              <i className="pi pi-circle-fill me-2"></i>
                              {d.reading}
                            </h5>
                            <p>{moment(d.created_on).utc('asia/kolkata').format('YYYY-MM-DD HH:mm:ss')}</p>
                          </Col>
                        </Row>
                      ))}
                    </Card.Body>
                  </Card>
                </Col>
              ))
            }

            {
              pieChartData?.map((c, d) => (
                <Col sm={6} lg={4}>
                  <Card className="infoBox">
                    <ChartDelete 
                      widgetPID={c.id_data}
                      widgetType="Pie"
                      widgetName="Pie Chart"
                      widgetDeleteIcon={delete_status}  
                    />                    
                    <Card.Body className="d-block" style={{ maxHeight: "320px" }}>
                      {
                        <Chart type="pie" data={c} options={pieChartOptions} className="w-full md:w-30rem" />
                      }
                    </Card.Body>
                  </Card>
                </Col>
              ))
            }
            
            {
              chartData?.map((c, d) => (
                <Col sm={6} lg={4}>
                  <Card className="infoBox">
                    <ChartDelete 
                      widgetPID={c.id_data}
                      widgetType={c.type}
                      widgetName={c.line}
                      widgetDeleteIcon={delete_status}  
                    />
                    <Card.Body className="d-block" style={{ maxHeight: "320px" }}>
                      {
                        c.type === 'line' ?
                          <Chart type="line" data={c} options={chartOptions} />
                          :
                          <Chart type="bar" data={c} options={columnChartOptions} />
                      }
                    </Card.Body>
                  </Card>
                </Col>
              ))
            }
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default Dashboard;
