import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import { ReactComponent as DeleteIcon } from "../../../assets/Images/client/icons/delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/Images/client/icons/edit.svg";
import { error } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/Material.css";
import "@pnotify/core/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useNavigate } from 'react-router-dom';

const ShareByMe = () => {
  const [devices, setDevices] = useState([]);

  // Add Recipient
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmailId] = useState("");

  const [unit_check, setUnitAddCheck] = useState(true);
  
  const [name_edit, setEditName] = useState("");
  const [email_edit, setEditEmailId] = useState("");
  const [visible_edit, setEditVisible] = useState(false);
  const [edit_check, setEditCheck] = useState();
  const [sh_id, setSharingId] = useState();

  
  const rowActionsBodyTemplate = (rowData) => {
    return (
      <div className="flex flex-row gap-3">
        <EditIcon className="cursor-pointer" onClick={() => editDetails(rowData.id) } />
        <DeleteIcon className="cursor-pointer" onClick={() => deleteDetails(rowData.id) } />
      </div>
    );
  };

  const StatusBody = (rowData) => {
    return(
      rowData.is_status ? <Badge bg="success">Active</Badge> : <Badge bg="danger">In-Active</Badge>
    )
  }

  const navigate = useNavigate()
  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);

  useEffect(() => {
    if (result) {
      getSharingDetails();      
    } else {
      navigate("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSharingDetails = () => {
    
    var postData = {
      usr_id: result.usr_id
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "get_sharing_details", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        const newArray = [];
        // eslint-disable-next-line array-callback-return
        res.data.alarm_details.map((data,index) => {   
          var temp_array = {
            sr_no: index + 1,
            id: data.id,
            sharing_name: data.sharing_name,
            sharing_email: data.sharing_email,
            is_status: data.is_status
          }
          newArray.push(temp_array);
        });   
        setDevices(newArray)
        setDeviceDetailsFilter(newArray)
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const handleSubmit = () => {
    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);

    var postData = {
      usr_id: result.usr_id,
      name: name,
      email_id: email,      
      is_status: unit_check ? 1 : 0,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "add_sharing_details", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        setVisible(false)
        setName("")
        setEmailId("")
        toast.dismiss()
        toast.success(res.data.msg, { theme: "colored", })
        getSharingDetails();
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const editDetails = (id) => {
    var postData = {
      id: id
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "edit_sharing_details", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        setEditVisible(true)
        setEditName(res.data.sharing_details[0].sharing_name);
        setEditEmailId(res.data.sharing_details[0].sharing_email);
        if (res.data.sharing_details[0].is_status === 1) {
          setEditCheck(true);
        } else {
          setEditCheck(false);
        }
        setSharingId(id);
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const deleteDetails = (id) => {
    error({
      hide: false,
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Details!",
      modules: new Map([
        [
          Confirm,
          {
            confirm: true,
            buttons: [
              {
                text: "submit",
                primary: false,
                click: notice => {
                  notice.close();
                  deleteRecord(id)
                }
              },
              {
                text: "cancel",
                click: notice => {
                  notice.close();
                }
              }
            ]
          }
        ]
      ])
    });
  }

  const deleteRecord = (id) => {
    var postData = {
      id: id
    };

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + 'delete_sharing_details', postData, axiosConfig)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.status === 'success') {
            toast.dismiss()
            toast.success(res.data.message, { theme: "colored", })
            getSharingDetails();
          } else {
            toast.dismiss()
            toast.error(res.data.message, { theme: "colored", })
          }
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Fail!!", { theme: "colored", })
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  const handleUpdate = () => {
    var postData = {
      id: sh_id,
      name: name_edit,
      email: email_edit,
      is_status: edit_check ? 1 : 0,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "update_sharing_details", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        console.log(res.data.status)
        if (res.data.status === "success") {
          setEditVisible(false)
          toast.dismiss()
          toast.success(res.data.msg, { theme: "colored", })
          getSharingDetails();
        } else {
          toast.dismiss()
          toast.error(res.data.msg, { theme: "colored", })
        }
        
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const handleChangeSwitch = (e) => {
    const checked = e.target.checked;
    const lightLabel = checked ? true : false;
    setUnitAddCheck(lightLabel);
  };

  const handleEditSwitch = (e) => {
    const checked = e.target.checked;
    const lightLabel = checked ? true : false;
    setEditCheck(lightLabel);
  };

  
  const [device_details_filter, setDeviceDetailsFilter] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
    const filtered_list = device_details_filter.filter((item) => {
      return item.sharing_name.toLowerCase().includes(value.toLowerCase());
    });
    setDevices(filtered_list);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search by Name"
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();
  return (
    <>
      <ToastContainer/>
      <Row className="mb-4">
        <Col sm={8} className="gap-3 flex">
          <span className="p-input-icon-left d-none">
            <i className="pi pi-search" />
            <InputText placeholder="Search" style={{ height: "43px" }} />
          </span>

          <Button label="Search" className="btn btn-primary d-none" rounded />
        </Col>

        <Col sm={4} className="gap-3 flex justify-content-end">
          <Button
            label="Add New Member"
            className="btn btn-primary addDashboard ms-4"
            icon="pi pi-plus"
            onClick={() => setVisible(true)}
          />
        </Col>
      </Row>

      <Row>
        {header}
        <DataTable className="mt-2" value={devices} tableStyle={{ minWidth: "50rem" }} stripedRows showGridlines paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} >
          <Column field="sr_no" header="ID" sortable></Column>
          <Column field="sharing_name" header="Name" sortable></Column>
          <Column field="sharing_email" header="Email" sortable></Column>
          <Column field="is_status" header="Status" body={StatusBody}></Column>
          <Column
            field=""
            header="Operations"
            body={rowActionsBodyTemplate}
          ></Column>
        </DataTable>
      </Row>

      {/* ----------- Modal of Add Device ------------ */}
      <Dialog
        className="rightPanel"
        header="Add Sharing Details"
        visible={visible}
        style={{ width: "30vw" }}
        onHide={() => setVisible(false)}
      >
        <Row className="mb-4">
          <Col sm={12}>
            <div className="flex flex-column gap-2 mb-4">
              <label htmlFor="Name">Name</label>
              <InputText id="name" className="w-100" value={name} onChange={(e) => setName(e.target.value)} />
            </div>

            <div className="flex flex-column gap-2 mb-4">
              <label htmlFor="email">Email</label>
              <InputText id="email" className="w-100" value={email} onChange={(e) => setEmailId(e.target.value)} />
            </div>
            <Form.Group
              className="mb-4 mandatory"
            >
              <Form.Label>Active </Form.Label>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                checked={unit_check}
                onChange={handleChangeSwitch}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="text-right">
          <Button
            label="Cancle"
            icon="pi pi-times"
            onClick={() => setVisible(false)}
            severity="danger"
            rounded
          />
          <Button
            label="Confirm"
            icon="pi pi-check"
            onClick={() => handleSubmit()}
            className="ms-2"
            autoFocus
            rounded
          />
        </div>
      </Dialog>
      
      {/* ----------- Modal of Edit Device ------------ */}
      <Dialog
        className="rightPanel"
        header="Edit Sharing Details"
        visible={visible_edit}
        style={{ width: "30vw" }}
        onHide={() => setEditVisible(false)}
      >
        <Row className="mb-4">
          <Col sm={12}>
            <div className="flex flex-column gap-2 mb-4">
              <label htmlFor="Name">Name</label>
              <InputText id="name" className="w-100" value={name_edit} onChange={(e) => setEditName(e.target.value)} />
            </div>

            <div className="flex flex-column gap-2 mb-4">
              <label htmlFor="email">Email</label>
              <InputText id="email" className="w-100" value={email_edit} onChange={(e) => setEditEmailId(e.target.value)} />
            </div>

            <Form.Group
              className="mb-4 mandatory"
            >
              <Form.Label>Active </Form.Label>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                checked={edit_check}
                onChange={handleEditSwitch}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="text-right">
          <Button
            label="Cancle"
            icon="pi pi-times"
            onClick={() => setEditVisible(false)}
            severity="danger"
            rounded
          />
          <Button
            label="Confirm"
            icon="pi pi-check"
            onClick={() => handleUpdate()}
            className="ms-2"
            autoFocus
            rounded
          />
        </div>
      </Dialog>
    </>
  );
};

export default ShareByMe;
