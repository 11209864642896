import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Dropdown } from "primereact/dropdown";
import { Chart } from 'primereact/chart';
import Card from "react-bootstrap/Card";
import "primeicons/primeicons.css";
import axios from "axios";
import { Button } from "primereact/button";
import { ToastContainer, toast } from "react-toastify";

const PieChart = () => {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selected_dashboard, setSelectedDashboard] = useState();
  const devices = [
    { name: "Record all devices in this account.", code: "all" },
    { name: "Only record devices in the dashbaord", code: "selected_dashbaord" }
  ];


  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    var user_data = localStorage.getItem('userData');
    var result = JSON.parse(user_data);

    var postData1 = {
      client_id: result.client_id
    };

    axios.post(global.passUrl + 'get_dashbaord_data', postData1, axiosConfig).then((res) => {
      setSelectedDashboard(res.data.selected_dashbaord[0].id);
    });

    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: ['Online', 'In-Active', 'Offline'],
      datasets: [
        {
          data: [4,5,3],
          backgroundColor: [
            documentStyle.getPropertyValue('--PrimaryDarkShade'),
            documentStyle.getPropertyValue('--color-gray'),
            documentStyle.getPropertyValue('--color-primary')
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue('--blue-400'),
            documentStyle.getPropertyValue('--yellow-400'),
            documentStyle.getPropertyValue('--color-secondary')
          ]
        }
      ]
    }
    
    console.log(documentStyle.getPropertyValue('--PrimaryDarkShade'),
    documentStyle.getPropertyValue('--color-gray'),
    documentStyle.getPropertyValue('--color-primary'))

    console.log(documentStyle.getPropertyValue('--blue-400'),
    documentStyle.getPropertyValue('--yellow-400'),
    documentStyle.getPropertyValue('--color-secondary'));

    const options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true
          }
        }
      }
    };

    setChartData(data);
    setChartOptions(options);

  }, []);

  const submitDeviceData = () => {
    if (selectedDevice) {
      var postData = {
        chart_name: selectedDevice.code,
        selected_dashboard: selected_dashboard,
        type: "pie"
      };

      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };
      axios.post(global.passUrl + 'add_pie_chart_data', postData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 'success') {
            toast.dismiss()
            toast.success(res.data.message, { theme: "colored", })
            setInterval(() => {
              window.location.href = '';
            }, 2500);
          } else {
            toast.dismiss()
            toast.error(res.data.message, { theme: "colored", })
          }
        } else if (res.status === 400) {
          window.location.href = '';
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
    }else{
      toast.dismiss()
      toast.error("Please select a record", { theme: "colored", })
    }
  }

  return (
    <>
      <ToastContainer/>
      <Container>
        <Row className="mb-4">
          <Col sm={8}>
            <div className="flex flex-row gap-2 align-items-center justify-content-start">
              <label htmlFor="record">Record information content</label>
              <Dropdown
                value={selectedDevice}
                onChange={(e) => setSelectedDevice(e.value)}
                options={devices}
                optionLabel="name"
                placeholder="Select a Device Type"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <Card className="alarm">
              <Card.Header>
                <small>Overview</small>
                <h5 className="text-success">Total : 12</h5>
                <p>Secondary Text</p>
              </Card.Header>
              <Card.Body>
                <Row className="p-3 row border-bottom">
                  <Col>
                    <Chart type="pie" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="text-end tab-footer" fixed="bottom">
          <div className="flex flex-wrap justify-content-end gap-3">
            <Button label="Confirm" className="ms-2" rounded onClick={submitDeviceData}/>
          </div>
        </Row> 
      </Container>
    </>
  );
};

export default PieChart;
