import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
const moment = require('moment');


const ReportingRecords = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedCity, setSelectedCity] = useState(null);
  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState(null);

  const navigate = useNavigate()
  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);

  useEffect(() => {
    if (result) {
      getDetails();      
    } else {
      navigate("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDetails = () => {
    var postData = {
      usr_id: result.usr_id
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "get_report_log_details", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        const newArray = [];
        // eslint-disable-next-line array-callback-return        
        res.data.log_details.map((data,index) => {   
          var temp_array = {
            sr_no: index + 1,
            id: data.log_id,
            log_msg: data.log_msg,
            created_on: moment(data.recevied_datetime).utc('asia/kolkata').format('DD MMM, YYYY HH:mm a')
          }
          newArray.push(temp_array);
        });   
        setDevices(newArray)
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const handleSubmit = () => {
    const startDateVal = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(startDate));
      
    const endDateVal =(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(endDate));

    var postData = {
      start_date: moment(startDateVal).format('YYYY-MM-DD')+" 00:00:00",
      end_date: moment(endDateVal).format('YYYY-MM-DD')+" 23:59:59",
      usr_id: result.usr_id
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios.post(global.passUrl + "get_log_details_by_search", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        if (res.data.status === 'error') {
          toast.dismiss()
          toast.error(res.data.msg, { theme: "colored", })
        } else {                
          console.log(res.data.log_details)
          const newArray = [];
          // eslint-disable-next-line array-callback-return
          res.data.log_details.map((data,index) => {   
            var temp_array = {
              sr_no: index + 1,
              id: data.log_id,
              log_msg: data.log_msg,
              created_on: moment(data.recevied_datetime).utc('asia/kolkata').format('DD MMM, YYYY HH:mm a')
            }
            newArray.push(temp_array);
          });   
          setDevices([]);
          setDevices(newArray)
        }
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => {
      console.log("AXIOS ERROR: ", err);
    });  
  }
  
  return (
    <>
      <ToastContainer/>
      <Row className="mb-4">
        <Col sm={8} className="gap-3 flex">
          <Dropdown
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.value)}
            optionLabel="name"
            placeholder="Custom"
            className="w-full d-none"
          />
          <Calendar
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            placeholder="Start Date"
            showIcon
            dateFormat="dd/mm/yy" 
            name="start_date"
            showButtonBar
            maxDate={new Date()}
          />
                                      
          <Calendar
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="End Date"
            showIcon
            minDate={startDate}
            dateFormat="dd/mm/yy" 
            name="end_date"
            showButtonBar
          />
          <Button label="Search" className="btn btn-primary" rounded onClick={() => handleSubmit() } />
        </Col>

        <Col sm={4} className="gap-3 flex justify-content-end">
          <Button label="Download" className="btn btn-primary d-none" outlined rounded />
        </Col>
      </Row>

      <Row>
        <DataTable
          value={devices}
          selection={selectedDevices}
          onSelectionChange={(e) => setSelectedDevices(e.value)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column field="sr_no" header="Sr.No" headerStyle={{ width: "10%" }} sortable></Column>
          <Column field="created_on" header="Date/Time" headerStyle={{ width: "20%" }} sortable></Column>
          <Column field="log_msg" sortable header="Recipients Message" ></Column>
        </DataTable>
      </Row>
    </>
  );
};

export default ReportingRecords;
