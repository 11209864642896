import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { MapService } from "../services/MapData";

export default function MapDataSelection() {
  const [map, setMaps] = useState([]);
  const [selectedMaps, setSelectedMaps] = useState(null);
  const [rowClick, setRowClick] = useState(true);

  useEffect(() => {
    MapService.getMapsMini().then((data) => setMaps(data));
  }, []);

  return (
    <>
      <div className="justify-content-center align-items-center mb-4 gap-2 d-none">
        <InputSwitch
          inputId="input-rowclick"
          checked={rowClick}
          onChange={(e) => setRowClick(e.value)}
        />
        <label htmlFor="input-rowclick">Row Click</label>
      </div>
      <DataTable
        value={map}
        selectionMode={rowClick ? null : "checkbox"}
        selection={selectedMaps}
        onSelectionChange={(e) => setSelectedMaps(e.value)}
        dataKey="id"
        tableStyle={{ minWidth: "50rem" }}
        stripedRows
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
        <Column field="name" header="Name"></Column>
        <Column field="model" header="Model"></Column>
        <Column field="sn" header="SN"></Column>
        <Column field="status" header="Status"></Column>
      </DataTable>
    </>
  );
}
