import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Button } from "primereact/button";
import Navbar from "react-bootstrap/Navbar";
import { DataTable } from "primereact/datatable";
import { DeviceService } from "./services/DeviceList";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import Menubar from "./menubar";
import Menubar2 from "./menubar2";

import { ReactComponent as DeleteIcon } from "../../assets/Images/client/icons/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/Images/client/icons/edit.svg";

const Triggers = () => {
  const [devices, setDevices] = useState([]);
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    DeviceService.getDevicesMini().then((data) => setDevices(data));
  }, []);

  const rowActionsBodyTemplate = (rowData) => {
    return (
      <div className="flex flex-row gap-3">
        <a
          href="/EditTriggers"
          tooltip="Enter your username"
          tooltipOptions={{ position: "bottom" }}
        >
          <EditIcon />
        </a>
        <DeleteIcon />
      </div>
    );
  };

  const switchTemplate = () => {
    return (
      <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
    );
  };

  return (
    <>
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-lg-block">
            <Menubar pageName="triggers" />
          </div>

          <div className="col-12 col-lg-11 ps-0">
            {/* Topbar */}
            <Navbar expand="lg" className="bg-body-tertiary w-100 pt-0 pb-0">
              <div className="topBar">
                <Container className="mw-100">
                  <Row>
                    <Col xs={4}>
                      <div className="d-lg-none d-sm-block d-block">
                        <Menubar2 />
                      </div>
                    </Col>
                    <Col xs={8} className="MobileMenu text-end">
                      <Navbar.Toggle aria-controls="navbarScroll" />
                    </Col>
                  </Row>
                </Container>

                <div className="row">
                  <Navbar.Collapse id="navbarScroll">
                    <div className="col-lg-4 title flex flex-row justify-content-start align-items-center gap-4">
                      <h4 className="text-primary">Triggers</h4>
                      <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                          placeholder="Search"
                          className="w-100"
                        />
                      </span>
                    </div>

                    <div className="col-lg-8 text-end flex align-items-center justify-content-end">
                      <a
                        href="/AddTriggers"
                        tooltip="Enter your username"
                        tooltipOptions={{ position: "bottom" }}
                      >
                        <Button
                          label="Add Trigger"
                          className="btn btn-primary addDashboard ms-4"
                          icon="pi pi-plus"
                        />
                      </a>

                      <Button
                        className="ms-4"
                        severity="secondary"
                        icon="pi pi-window-maximize"
                        text
                        outlined
                        rounded
                        raised
                        style={{ fontSize: "1.5rem" }}
                        tooltip="Fullscreen"
                        tooltipOptions={{ position: "bottom" }}
                      />
                    </div>
                  </Navbar.Collapse>
                </div>
              </div>
            </Navbar>

            {/* Main body */}
            <div className="MainBody p-4 mapBody">
              <Container className="mw-100">
                <Row>
                  <Col>
                    <Row className="p-3 bg-white border-bottom box">
                      <Col sm={12}>
                        <DataTable
                          value={devices}
                          tableStyle={{ minWidth: "50rem" }}
                          stripedRows
                        >
                          <Column field="id" header="ID"></Column>
                          <Column field="name" header="Title"></Column>
                          <Column
                            field="category"
                            header="Enable"
                            body={switchTemplate}
                          ></Column>
                          <Column
                            field=""
                            header="Operations"
                            body={rowActionsBodyTemplate}
                          ></Column>
                        </DataTable>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Triggers;
