import React, { useState,useEffect } from "react";
import { Button } from "primereact/button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TabView, TabPanel } from "primereact/tabview";
import { Dialog } from "primereact/dialog";
import Tab from "react-bootstrap/Tab";
import Clock from "./widgets/clock";
import Alarm from "./widgets/alarm";
import MapDataSelection from "./widgets/map";
import UploadImage from "./widgets/customImage";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import Image from "react-bootstrap/Image";
import LineChart from "./widgets/lineChart";
import ColumnChart from "./widgets/columnChart";
import PieChart from "./widgets/pieChart";
import WidgetList from "./widgetList";
import ListGroup from "react-bootstrap/ListGroup";
import { Checkbox } from "primereact/checkbox";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// All Images
import TempIcon from "../../assets/Images/client/widgets/temp.png";
import BatteryIcon from "../../assets/Images/client/widgets/battery.png";
import CameraIcon from "../../assets/Images/client/widgets/camera.png";
import ClockIcons from "../../assets/Images/client/widgets/clock.png";
import Co2Icon from "../../assets/Images/client/widgets/co2.png";
import HumidityIcon from "../../assets/Images/client/widgets/humidity.png";

import "./addWidgetPopup.css";
import axios from "axios";

const AddWidgetPopup = () => {
  //   Add Widget Modal Popup
  const [visible, setVisible] = useState(false);
  const [device_details, setDeviceDetails] = useState([]);
  const [device_details_filter, setDeviceDetailsFilter] = useState([]);
  const [parameter_data, setParameterData] = useState([]);
  const [device_list_name, setDeviceListName] = useState("")
  const [selected_device, setSelectedDeviceId] = useState("");
  const [selected_dashboard, setSelectedDashboard] = useState();


  useEffect(() => {
    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);
    getDeviceData(result.client_id)
  },[])

  const getDeviceData = (c_id) => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };
    
    var postData = {
      client_id: c_id,
    };

    axios.post(global.passUrl + 'get_client_device_data_active', postData, axiosConfig).then((res) => {
      setDeviceDetails(res.data.device_data)
      setDeviceDetailsFilter(res.data.device_data)
    });

    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);

    var postData1 = {
      client_id: result.client_id
    };

    axios.post(global.passUrl + 'get_dashbaord_data', postData1, axiosConfig).then((res) => {
      if (res.data.selected_dashbaord) {       
        setSelectedDashboard(res.data.selected_dashbaord[0].id); 
      }
    });
  }
  
  const searchMaterial = (val) => {
    const filtered_list = device_details_filter.filter((item) => {
      return item.device_name.toLowerCase().includes(val.toLowerCase());
    });
    setDeviceDetails(filtered_list);
  };

  const getDeviceParameter = (id) => {
    setSelectedDeviceId("");
    setIngredients([]);
    setDeviceListName("deviceList_"+id);

    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };
    var postData = {
      client_id: result.client_id,
      cd_id: id
    };

    axios.post(global.passUrl + 'getParameetrDataActive', postData, axiosConfig).then((res) => {
      setParameterData(res.data.parameter_data);
      setSelectedDeviceId(id);
    });
  }

  const submitDeviceData = () => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };
    var postData = {
      para_list: ingredients,
      selected_device: selected_device,
      selected_dashboard: selected_dashboard
    };
    axios.post(global.passUrl + 'addDeviceListsData', postData, axiosConfig).then((res) => {
      console.log(res);
      if (res.status === 200) {
        if (res.data.status === "success") {
          toast.dismiss();
          toast.success(res.data.message, { theme: "colored" });
          setInterval(() => {
            window.location.href = "";
          }, 2500);
        } else {
          toast.dismiss();
          toast.error(res.data.message, { theme: "colored" });
        }
      } else if (res.status === 400) {
        toast.dismiss();
        toast.error("Faill!!", { theme: "colored" });
      }
    });
  }

  /*const footerContent = (
    <div className="flex justify-content-end">
      <Button
        label="Cancle"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        severity="danger"
        rounded
      />
      <Button
        label="Confirm"
        icon="pi pi-check"
        onClick={() => setVisible(false)}
        className="ms-2"
        autoFocus
        rounded
      />
    </div>
  );*/

  // Device Dropdown in Custom Image tab
  const [selectedDevice, setSelectedDevice] = useState(null);
  const devices = [
    { name: "Device Name 01", code: "DV1" },
    { name: "Device Name 02", code: "DV2" },
    { name: "Device Name 03", code: "DV3" },
    { name: "Device Name 04", code: "DV4" },
  ];

  // Radio button  
  const [clock, setClock] = useState("");

  // Checkbox
  const [ingredients, setIngredients] = useState([]);

  const onIngredientsChange = (e) => {
    let _ingredients = [...ingredients];

    if (e.checked) _ingredients.push(e.value);
    else _ingredients.splice(_ingredients.indexOf(e.value), 1);

    setIngredients(_ingredients);
  };

  const submitClockData = () => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };
    var postData = {
      clock_type: clock,
      selected_dashboard: selected_dashboard
    };
    axios.post(global.passUrl + 'addClockData', postData, axiosConfig).then((res) => {
      console.log(res);
      if (res.status === 200) {
        if (res.data.status === "success") {
          toast.dismiss();
          toast.success(res.data.message, { theme: "colored" });
          setInterval(() => {
            window.location.href = "";
          }, 2500);
        } else {
          toast.dismiss();
          toast.error(res.data.message, { theme: "colored" });
        }
      } else if (res.status === 400) {
        toast.dismiss();
        toast.error("Faill!!", { theme: "colored" });
      }
    });
  }

  return (
    <>
      <ToastContainer />
      <Button
        label="Add Widget"
        className="btn btn-outlined"
        outlined
        rounded
        onClick={() => setVisible(true)}
      />

      <Dialog
        header="Add New Widget"
        visible={visible}
        style={{ width: "70vw" }}
        className="addWidget"
        onHide={() => setVisible(false)}
        maximizable
        // footer={footerContent}
      >
        <Tab.Container id="left-tabs-example" defaultActiveKey="clock">
          <Row className="h-100">
            <Col sm={3} className="AddWidget leftSide">
              <TabView>
                <TabPanel header="System Widget">
                  {/* Sidebar of Widget list */}
                  <WidgetList />
                </TabPanel>

                <TabPanel header="Device List" defaultActiveKey="deviceList">
                  <span className="p-input-icon-left w-100 mb-4">
                    <i className="pi pi-search" />
                    <InputText placeholder="Search" 
                    name="searchText"
                    onChange={(e) => searchMaterial(e.target.value)}/>
                  </span>

                  <ListGroup>
                    {
                      device_details.map(employee => {
                        var name = "deviceList_"+employee.cd_id;
                        return (
                          <ListGroup.Item className="border-0 cursor-pointer" key={employee.cd_id} eventKey={name} onClick={() => getDeviceParameter(employee.cd_id)}>
                            <h5>{employee.device_name}</h5>
                            <p className="mb-0">{employee.device_eui}</p>
                          </ListGroup.Item>
                        );
                      })
                    }
                  </ListGroup>
                </TabPanel>
              </TabView>
            </Col>
            <Col sm={9} className="p-4 border-left pe-0 pb-0 rightSide">
              <Tab.Content>
                {/* ---------------------- Clock Widget start Here ------------------------ */}
                <Tab.Pane eventKey="clock">
                  <Row>
                    <Col sm={4} className="clockSelect pt-3">
                      <div className="align-items-center">
                        <Checkbox 
                          inputId="ingredient1"
                          name="clockStyle"
                          value="DefaultClock"
                          onChange={(e) => setClock(e.value)}
                          checked={clock === "DefaultClock"}
                          className="mb-3"
                        />

                        <Clock
                          image="clock"
                          time="11:34:43"
                          day="Friday"
                          date="27 June 2023"
                          htmlFor="ingredient1"
                          widgetIcon={ClockIcons}
                        />
                      </div>
                    </Col>
                    <Col sm={4}  className="clockSelect pt-3">
                    <div className="align-items-center">
                        <Checkbox 
                          inputId="ingredient2"
                          name="clockStyle"
                          value="BlueClock"
                          onChange={(e) => setClock(e.value)}
                          checked={clock === "BlueClock"}
                          className="mb-3"
                        />

                        <Clock
                          image="clock blue"
                          time="11:34:43"
                          day="Friday"
                          date="27 June 2023"
                          htmlFor="ingredient2"
                          widgetIcon={ClockIcons}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="text-end tab-footer" fixed="bottom">
                    <div className="flex flex-wrap justify-content-end gap-3">
                    <Button label="Cancle" severity="danger" rounded onClick={() => setVisible(false)} />
                    <Button label="Confirm" className="ms-2" rounded onClick={submitClockData}/>
                  </div>
                  </Row>
                </Tab.Pane>
                {/* ---------------------- Clock Widget Ends Here ------------------------ */}

                {/* ---------------------- Map Widget start Here ------------------------ */}
                <Tab.Pane eventKey="map">
                  <MapDataSelection />
                </Tab.Pane>
                {/* ---------------------- Map Widget Ends Here ------------------------ */}

                {/* ---------------------- Custom Image Starts Here ------------------------- */}
                <Tab.Pane eventKey="image">
                  <Container>
                    <Row className="mb-4">
                      <Col sm={4}>
                        <div className="flex flex-row align-items-center gap-2 justify-content-start">
                          <label htmlFor="username">Name</label>
                          <InputText
                            id="name"
                            aria-describedby="username-help"
                            placeholder="Device Name"
                          />
                        </div>
                      </Col>
                      <Col sm={8} className="flex justify-content-end  gap-4">
                        <Dropdown
                          value={selectedDevice}
                          onChange={(e) => setSelectedDevice(e.value)}
                          options={devices}
                          optionLabel="name"
                          placeholder="Select a Device"
                          className="w-full md:w-14rem"
                        />
                        <span className="p-input-icon-left">
                          <i className="pi pi-search" />
                          <InputText placeholder="Search" />
                        </span>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={8}>
                        <UploadImage />
                      </Col>
                      <Col sm={4} className="p-3">
                        <div className=" flex flex-column">
                          <a href="/" className="deviceImageList">
                            <div className="flex flex-row align gap-4 align-items-center justify-content-start">
                              <Image src={TempIcon} width="30px" />
                              <span className="text-md">6136C48378951008</span>
                            </div>
                          </a>

                          <a href="/" className="deviceImageList">
                            <div className="flex flex-row align gap-4 align-items-center justify-content-start">
                              <Image src={HumidityIcon} width="30px" />
                              <span className="text-md">6136C48378951008</span>
                            </div>
                          </a>

                          <a href="/" className="deviceImageList">
                            <div className="flex flex-row align gap-4 align-items-center justify-content-start">
                              <Image src={ClockIcons} width="30px" />
                              <span className="text-md">6136C48378951008</span>
                            </div>
                          </a>

                          <a href="/" className="deviceImageList">
                            <div className="flex flex-row align gap-4 align-items-center justify-content-start">
                              <Image src={Co2Icon} width="30px" />
                              <span className="text-md">6136C48378951008</span>
                            </div>
                          </a>

                          <a href="/" className="deviceImageList">
                            <div className="flex flex-row align gap-4 align-items-center justify-content-start">
                              <Image src={BatteryIcon} width="30px" />
                              <span className="text-md">6136C48378951008</span>
                            </div>
                          </a>

                          <a href="/" className="deviceImageList">
                            <div className="flex flex-row align gap-4 align-items-center justify-content-start">
                              <Image src={CameraIcon} width="30px" />
                              <span className="text-md">6136C48378951008</span>
                            </div>
                          </a>
                        </div>
                      </Col>
                    </Row>

                    {/* <Row className="text-end tab-footer" fixed="bottom">
                      <div className="flex flex-wrap justify-content-end gap-3">
                        <Button label="Cancle" severity="danger" rounded />
                        <Button label="Confirm" className="ms-2" rounded />
                      </div>
                    </Row> */}
                  </Container>
                </Tab.Pane>
                {/* ---------------------- Custom Image Ends here ----------------------------- */}

                {/* -------------------- Line Chart Starts Here ------------------------- */}
                <Tab.Pane eventKey="lineChart">
                  <LineChart />
                </Tab.Pane>
                {/* -------------------- Line Chart Ends here ----------------------------- */}

                {/* -------------------- Column Chart Starts Here ---------------------------- */}
                <Tab.Pane eventKey="columnChart">
                  <ColumnChart />
                </Tab.Pane>
                {/* -------------------- Column Chart Ends Here ---------------------- */}

                {/* ------------------ Alarm Starts Here ---------------------- */}
                <Tab.Pane eventKey="alarm">
                  <Alarm />
                </Tab.Pane>
                {/* ----------------- Alarm Ends Here -------------------------- */}

                <Tab.Pane eventKey="pieChart">
                  <PieChart />
                </Tab.Pane>
                
                {/* ---------------- Device List Starts Here ----------------------- */}
                <Tab.Pane eventKey={device_list_name ? device_list_name : ''}>
                  <Container>
                    {
                      parameter_data.map((result, index) => {
                        var inpId = "ingredient_"+ index;
                        return (                          
                          <Row className="mb-3">
                            <Col sm={1}>
                              <Checkbox
                                inputId={inpId}
                                name="parameter_name"
                                value={result.parameter_name}
                                onChange={onIngredientsChange}
                                checked={ingredients.includes(result.parameter_name)}
                              />
                            </Col>

                            <Col sm={4}>
                              <label htmlFor="deviceName">{result.display_name}</label>
                            </Col>
                            <Col sm={4}>
                              <select className="form-select" disabled>
                                <option>{result.display_name}</option>
                              </select>
                            </Col>
                          </Row>
                        );
                      })
                    }
                    <Row className="text-end tab-footer" fixed="bottom">
                      <div className="flex flex-wrap justify-content-end gap-3">
                        <Button label="Cancle" severity="danger" rounded onClick={() => setVisible(false)}/>
                        <Button label="Confirm" className="ms-2" rounded onClick={submitDeviceData}/>
                      </div>
                    </Row> 
                  </Container>
                </Tab.Pane>
                {/* ---------------- Device List Ends Here -------------------------- */}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Dialog>
    </>
  );
};

export default AddWidgetPopup;
