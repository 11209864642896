import React from "react";
import Nav from "react-bootstrap/Nav";

// All Menu Icons
import { ReactComponent as ClockIcon } from "../../assets/Images/client/icons/clock.svg";
import { ReactComponent as Map } from "../../assets/Images/client/icons/map.svg";
import { ReactComponent as CustomImage } from "../../assets/Images/client/icons/image.svg";
import { ReactComponent as LineChartIcon } from "../../assets/Images/client/icons/lineChart.svg";
import { ReactComponent as ColumnChartIcon } from "../../assets/Images/client/icons/chart.svg";
import { ReactComponent as AlarmIcon } from "../../assets/Images/client/icons/alarm.svg";
import { ReactComponent as PieChartIcon } from "../../assets/Images/client/icons/pieChart.svg";

const WidgetList = () => {
  return (
    <>
      <Nav variant="pills" className="flex-column">
        {/* Clock */}
        <Nav.Item>
          <Nav.Link eventKey="clock">
            <ClockIcon /> Clock
          </Nav.Link>
        </Nav.Item>

        {/* Map */}
        <Nav.Item className="d-none">
          <Nav.Link eventKey="map">
            <Map /> Map
          </Nav.Link>
        </Nav.Item>

        {/* Custom Image */}
        <Nav.Item className="d-none">
          <Nav.Link eventKey="image">
            <CustomImage /> Custom Image
          </Nav.Link>
        </Nav.Item>

        {/* Line Chart */}
        <Nav.Item>
          <Nav.Link eventKey="lineChart">
            <LineChartIcon /> Line Chart
          </Nav.Link>
        </Nav.Item>

        {/* Column Chart */}
        <Nav.Item>
          <Nav.Link eventKey="columnChart">
            <ColumnChartIcon /> Column Chart
          </Nav.Link>
        </Nav.Item>

        {/* Alarm */}
        <Nav.Item>
          <Nav.Link eventKey="alarm">
            <AlarmIcon /> Alarm
          </Nav.Link>
        </Nav.Item>

        {/* Pie Chart */}
        <Nav.Item>
          <Nav.Link eventKey="pieChart">
            <PieChartIcon /> Pie Chart
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
};

export default WidgetList;
