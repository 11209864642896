import React, {useEffect,useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap"; 
import { Button } from "primereact/button";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const MemberCenter = () => {

  const [alarm_receivers, AlarmReceivers] = useState(0)
  const [alarm_mails, AlarmMails] = useState(0)
  const [devices, Devices] = useState(0)
  const [dashboard, Dashboards] = useState(0)
  const [device_sharing_contact, DeviceSharingContacts] = useState(0)
  const values = [{ label: 'Space used', value: 3 }];

  const navigate = useNavigate()
  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);
  
  useEffect(() => {
    if (result) {     
      
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };

      var AccessData = {
        client_id: result.client_id,
        usr_id: result.usr_id
      };     
      axios.post(global.passUrl + 'get_module_count', AccessData, axiosConfig).then((res) => {
        Devices(res.data.device_cnt)
        Dashboards(res.data.dashbaord_cnt)
        DeviceSharingContacts(res.data.device_sharing_contact_cnt)
      });
      
    } else {
      navigate("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const triggerTemplate = (value) => {
    return (
      <React.Fragment>
        {value}/<b>10</b>
      </React.Fragment>
    );
  };

  const deviceTemplate = (value) => {
    return (
      <React.Fragment>
        {value}/<b>100</b>
      </React.Fragment>
    );
  };

  const AlarmreceiversTemplate = (value) => {
    return (
      <React.Fragment>
        {value}/<b>100</b>
      </React.Fragment>
    );
  };
  
  const DashboardsTemplate = (value) => {
    return (
      <React.Fragment>
        {value}/ <b>10</b>
      </React.Fragment>
    );
  };

  const AlarmMailsTemplate = (value) => {
    return (
      <React.Fragment>
        {value}/<b>100</b>
      </React.Fragment>
    );
  };
  const DeviceSharingContactsTemplate = (value) => {
    return (
      <React.Fragment>
        {value}/<b>100</b>
      </React.Fragment>
    );
  };

  return (
    <>
      <Container>
        <Row className="gap-5">
          <Col sm={3}>
            <div className="memberPlan">
                <div className="plan">
                    <h3 className="text-primary">Membership Plan</h3>
                </div>
                <div className="planTitle">
                    <h3>Free</h3>
                </div>
                <div className="planDetails">
                <Button label="Upgrade Account" className="btn btn-secondary" rounded />
                </div>
            </div>
          </Col>

          <Col sm={6}>
            <div className="progressCard">
              <Row className="progressStatus">
                <Col sm={6} className="title">
                  <label className="font-bold w-50">Alarm receivers</label>
                </Col>

                <Col sm={6}  className="progressBar text-center">
                  0
                </Col>
              </Row>

              <Row className="progressStatus">
                <Col sm={6} className="title">
                  <label className="font-bold w-50">Alarm e-mails</label>
                </Col>

                <Col sm={6}  className="progressBar text-center">
                  0
                </Col>
              </Row>

              <Row className="progressStatus">
                <Col sm={6} className="title">
                  <label className="font-bold w-50">Devices</label>
                </Col>

                <Col sm={6}  className="progressBar text-center">
                  {devices}
                </Col>
              </Row>

              <Row className="progressStatus">
                <Col sm={6} className="title">
                  <label className="font-bold w-50">Triggers</label>
                </Col>

                <Col sm={6}  className="progressBar text-center">
                  0
                </Col>
              </Row>

              <Row className="progressStatus">
                <Col sm={6} className="title">
                  <label className="font-bold w-50">Dashboards</label>
                </Col>

                <Col sm={6}  className="progressBar text-center">
                  {dashboard}
                </Col>
              </Row>

              <Row className="progressStatus">
                <Col sm={6} className="title">
                  <label className="font-bold w-50">Device sharing contacts</label>
                </Col>

                <Col sm={6}  className="progressBar text-center">
                  {device_sharing_contact}
                </Col>
              </Row>

            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MemberCenter;
