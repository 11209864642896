import React, {useEffect,useState} from "react";
import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import { Col } from "react-bootstrap";
// import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
// import { InputText } from "primereact/inputtext";
// import Navbar from "react-bootstrap/Navbar";
import Topbar from '../topbar';
import Menubar from "../menubar";
// import Menubar2 from "../menubar2";

import ShareByMe from "./shareByMe";
import ShareWithMe from "./shareWithMe";
import Notifications from "./notifications";
import axios from "axios";

import { useNavigate } from 'react-router-dom';
const SharingCenter = () => {
  
  const navigate = useNavigate()
  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);
  
  const [shared_me_status, setSharedMeStatus] = useState(0)
  const [notification_status, setNotificationStatus] = useState(0)

  useEffect(() => {
    if (result) {
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };

      var AccessData = {
        role_id: result.role_id,
        menu_id: 6,
      };      
      axios.post(global.passUrl + 'get_access_details', AccessData, axiosConfig).then((res) => {
        if (res.data.access_data.length) {
          // eslint-disable-next-line array-callback-return
          res.data.access_data.map((data1) => {
            if (data1.privileges_key === "SharedMe") {
              if (data1.status) {
                setSharedMeStatus(1)
              } else {
                setSharedMeStatus(0)
              }
            }else if (data1.privileges_key === "Notification") {
              if (data1.status) {
                setNotificationStatus(1)
              } else {
                setNotificationStatus(0)
              }
            }
          })
        }
      });
    } else {
      navigate("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-lg-block">
            <Menubar pageName="sharing" />
          </div>

          <div className="col-12 col-lg-11 ps-0">
            {/* Topbar */}
            <Topbar />

            {/* Main body */}
            <div className="MainBody p-4 sharing">
              <Container className="mw-100">
                <TabView>
                  {/* -------------- Shared By Me Starts here --------------- */}
                  {
                    shared_me_status === 1 ?                    
                    <TabPanel header="Shared By Me">
                      <ShareByMe />
                    </TabPanel>
                    :
                    ""
                  }
                  {/* -------------- Shared By Me Ends here ----------------- */}

                  {/* -------------- Shared With Me Starts here --------------- */}
                  <TabPanel header="Shared With Me" className="d-none">
                    <ShareWithMe />
                  </TabPanel>
                  {/* -------------- Shared With Me Ends here --------------- */}

                  {/* -------------- Notifications Starts here --------------- */}
                  {
                    notification_status === 1 ?
                    <TabPanel header="Notifications">
                      <Notifications />
                    </TabPanel>
                    :
                    ""
                  }
                  {/* -------------- Notifications Ends here --------------- */}
                </TabView>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SharingCenter;
