import React, {useEffect,useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import Navbar from "react-bootstrap/Navbar";
import axios from "axios";

import Menubar from "../menubar";
import Menubar2 from "../menubar2";

import MemberCenter from "./memberCenter";
import ProfileBasicDetails from "./profileBasicDetails";
import DateTime from "./dateTime";
import UpdatePassword from "./updatePassword";
import GlobalSettings from "./globalSettings";
import AlarmRecipient from "./alarmRecipient";
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  
  const [user_name, setUserName] = useState("");
  const navigate = useNavigate()
  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);

  const [change_password_status, setChangePasswordStatus] = useState(0)
  const [member_info_status, setMemberInfoStatus] = useState(0)
  const [basic_info_status, setBasicInfoStatus] = useState(0)
  const [date_time_status, setDateTimeStatus] = useState(0)
  const [alaram_recipent_status, setAlaramRecipentStatus] = useState(0)

  useEffect(() => {
    if (result) {     
      setUserName(result.u_first_name) 
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };

      var AccessData = {
        role_id: result.role_id,
        menu_id: 8,
      };      
      axios.post(global.passUrl + 'get_access_details', AccessData, axiosConfig).then((res) => {
        if (res.data.access_data.length) {
          // eslint-disable-next-line array-callback-return
          res.data.access_data.map((data1) => {
            if (data1.privileges_key === "MemberInfo") {
              if (data1.status) {
                setMemberInfoStatus(1)
              } else {
                setMemberInfoStatus(0)
              }
            }else if (data1.privileges_key === "BasicInfo") {
              if (data1.status) {
                setBasicInfoStatus(1)
              } else {
                setBasicInfoStatus(0)
              }
            }else if (data1.privileges_key === "DateTime") {
              if (data1.status) {
                setDateTimeStatus(1)
              } else {
                setDateTimeStatus(0)
              }
            }else if (data1.privileges_key === "AlaramRecipent") {
              if (data1.status) {
                setAlaramRecipentStatus(1)
              } else {
                setAlaramRecipentStatus(0)
              }
            }else if (data1.privileges_key === "ChangePassword") {
              if (data1.status) {
                setChangePasswordStatus(1)
              } else {
                setChangePasswordStatus(0)
              }
            }
          })
        }
      });
      
    } else {
      setUserName("")
      navigate("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const [user_menu, setUserMenu] = useState(null);
  const userMenu = [
    { name: "Logout", code: "LDN" }
  ];
  const changeMenuTop = (e) => {
    setUserMenu(e.value);
    if(e.value.code === "LDN"){
      localStorage.clear();
      window.location.href = "/";
    }else{
      window.location.href = "/profile";
    }
  }

  return (
    <>
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-lg-block">
            <Menubar pageName="profile" />
          </div>

          <div className="col-12 col-lg-11 ps-0">
            {/* Topbar */}
            <Navbar expand="lg" className="bg-body-tertiary w-100 pt-0 pb-0">
              <div className="topBar">
                <Container className="mw-100">
                  <Row>
                    <Col xs={4}>
                      <div className="d-lg-none d-sm-block d-block">
                        <Menubar2 />
                      </div>
                    </Col>
                    <Col xs={8} className="MobileMenu text-end">
                      <Navbar.Toggle aria-controls="navbarScroll" />
                    </Col>
                  </Row>
                </Container>

                <div className="row pt-2">
                  <Navbar.Collapse id="navbarScroll">
                    <div className="col-lg-4 title flex flex-row justify-content-start align-items-center gap-4">
                      <h4 className="text-primary">Profile Management</h4>
                      <span className="p-input-icon-left d-none">
                        <i className="pi pi-search" />
                        <InputText placeholder="Search" className="w-100" />
                      </span>
                    </div>

                    <div className="col-lg-8 text-end flex align-items-center justify-content-end">
                      <Dropdown
                        value={user_menu}
                        onChange={(e) => changeMenuTop(e)}
                        options={userMenu}
                        className="ms-4"
                        optionLabel="name"
                        placeholder={user_name}
                      />
                    </div>
                  </Navbar.Collapse>
                </div>
              </div>
            </Navbar>

            {/* Main body */}
            <div className="MainBody p-4 sharing" >
              <Container className="mw-100">
                <TabView>
                  {
                    member_info_status === 1 ?
                    <TabPanel header="Member Center">
                      <MemberCenter />
                    </TabPanel>
                    :
                    ""  
                  }
                  {
                    basic_info_status === 1 ?
                    <TabPanel header="Basic Information">
                      <ProfileBasicDetails />
                    </TabPanel>
                    :
                    ""  
                  }
                  {
                    date_time_status === 1 ?
                    <TabPanel header="Date & Time">
                      <DateTime />
                    </TabPanel>

                    :
                    ""  
                  }
                  {
                    change_password_status === 1 ?
                    <TabPanel header="Change Password">
                      <UpdatePassword />
                    </TabPanel>

                    :
                    ""  
                  }
                  {
                    alaram_recipent_status === 1 ?
                    <TabPanel header="Alarm Recipient">
                      <AlarmRecipient />
                    </TabPanel>

                    :
                    ""  
                  }
                  <TabPanel header="Global Settings" className="d-none">
                    <GlobalSettings />
                  </TabPanel>
                </TabView>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
