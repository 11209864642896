import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";

import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom'
const moment = require('moment');

const DeviceChart = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const paramsData = useParams();
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);

  useEffect(() => {
    getChartDetails();
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };
    setChartOptions(options);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChartDetails = () => {
    var postData = {
      cd_id: paramsData.d_id,
      client_id: result.client_id
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "get_devices_chart_data", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        setChartData(res.data.device_data)
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const get_devices_chart_data_by_search = () => {
    var postData = {
      cd_id: paramsData.d_id,
      client_id: result.client_id,
      start_date: moment(startDate).utc('asia/kolkata').format('YYYY-MM-DD'),
      end_date: moment(endDate).utc('asia/kolkata').format('YYYY-MM-DD')
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "get_devices_chart_data_by_search", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        setChartData([])
        setChartData(res.data.device_data)
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  return (
    <>
      <Row className="mb-4">
        <Col sm={4} className="gap-3 flex">
          <Calendar
            className="w-75"
            value={startDate}
            onChange={(e) => setStartDate(e.value)}
            placeholder="Start Date"
            showIcon
          />
          <Calendar
            className="w-75"
            value={endDate}
            onChange={(e) => setEndDate(e.value)}
            placeholder="End Date"
            showIcon
          />
        </Col>
        <Col sm={3} className="gap-3 flex justify-content-center">
          <Button label="Submit" className="btn btn-primary" onClick={() => get_devices_chart_data_by_search()} rounded />
        </Col>
      </Row>
      <Row>
        <Chart type="line" data={chartData} options={chartOptions} />
      </Row>
    </>
  );
};

export default DeviceChart;
