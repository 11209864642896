import React, { useState, useEffect } from 'react';
import logo from '../../assets/Images/auth/logo-white.png';
import userIcon from '../../assets/Images/auth/userIcon.svg';
import colorLogo from '../../assets/Images/auth/logo.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../pages/global';
import '../../assets/css/client.css'
import Clock from 'react-live-clock';

export default function ForgotPassword() {
  const navigate = useNavigate();

  const [question_details, setQuestionDetails] = useState([]);

  useEffect(() => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + 'get_question_details', axiosConfig).then((res) => {
      setQuestionDetails([]);
      setQuestionDetails(res.data.question_details);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    var postData = {
      email: data.get('admin_signin_email'),
      security_answer: data.get('security_answer'),
      security_id: data.get('security_id'),
    };

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    if (postData.email && postData.security_id && postData.security_answer) {
      axios.post(global.passUrl + 'check_forgot_password', postData, axiosConfig)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === 'success') {              
              setTimeout(() => {
                navigate('/reset-password')
              }, 1500);
            } else {
              toast.dismiss()
              toast.error(res.data.msg, { theme: "colored", })
            }
          } else if (res.status === 400) {
            toast.dismiss()
            toast.error("Fail!!", { theme: "colored", })
          }
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
    } else if (data.get('admin_signin_email') === '' && data.get('security_answer') === '' && data.get('security_id') === '') {
      toast.dismiss()
      toast.error("All Fields are Required!", { theme: "colored", })
    } else if (data.get('admin_signin_email') === '') {
      toast.dismiss()
      toast.error("Email'Id is Required!", { theme: "colored", })
    } else if (data.get('security_answer') === '') {
      toast.dismiss()
      toast.error("Security Answer is Required!", { theme: "colored", })
    } else if (data.get('security_id') === '') {
      toast.dismiss()
      toast.error("Security Question is Required!", { theme: "colored", })
    }
  }

  return (
    <div className="login">
      <ToastContainer />
      <div className="row g-0 h-100 ">
        <div className="col-12 col-lg-6">
          <div className="row align-items-center clientLoginLeft g-0">
            <div className="col p-5">
              <img src={logo} className='loginLogo' alt="Logo" />
              <div className="row pt-5 pl-0">
                <h3>Ceryle Innovation Technologies IoT Operations Dashboard</h3>
                <h5>Accelerate industrial digitization</h5>
                <p>Enable cloud-delivered operational technology (OT) services on your industrial networks to drive simplicity and scale.</p>
              </div>
            </div>
          </div>
        </div>

        {/* Right Side Login Form */}
        <div className="col-12 col-lg-6 ">
          <div className="row align-items-center loginRight g-0 h-100 justify-content-center">
            <div className="col-12 col-lg-8 p-5">
              <p>Good Morning!</p>
              <h4>Welcome Back!</h4>
              <p className='subTitle'>Forgot Password</p>
              <form className="form-auth-small" id='login-form' method='POST' onSubmit={handleSubmit}>
                <div className="mb-3">
                  <div className="input-group">
                    <input type="text" className="form-control border-end-0" placeholder="Email Id" aria-label="Email Id" aria-describedby="Email Id" id="admin_signin_email" name='admin_signin_email' />
                    <span className="input-group-text border-start-0" id="basic-addon2">
                      <img src={userIcon} className='loginLogo' alt="Logo" />
                    </span>
                  </div>

                  <div className="input-group">
                    <select className="form-select"  id="security_id" name='security_id' >
                      <option value="" >Select Security Question</option>
                      {question_details.map((data, index) => (
                        <option value={data.id} key={index}>
                          {data.question}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Security Answer" aria-label="Security Answer" aria-describedby="Security Answer" id="security_answer" name='security_answer' />
                  </div>

                  <div className="row d-none">
                    <a href="/forgot-password" className='forgot'>Forgot Password</a>
                  </div>

                </div>

                <div className="d-grid gap-2 mb-3">
                  <button className="btn btn-primary" type="submit">Continue</button>
                </div>
              </form>

              <p className='text-center'>Return to <a href="/" className=''>Login</a></p>
            </div>

            <div className="col-12 text-center">
              <img src={colorLogo} alt="Ceryletech Logo" className='mb-2' />
              <p>©<Clock format={'Y'} /> Ceryle Innovative Technologies. All Rights Reserved.</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
