import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
// import { log } from "handlebars";
import { useParams } from 'react-router-dom'

const Log = () => { 
  const paramsData = useParams();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);

    var postData = {
      user_id: result.usr_id,
      cd_id: paramsData.d_id
    };    
    console.log(postData)


    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "get_device_log_details", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        const deviceDetailsArray = res.data.device_log_details;
        const log_array = [];
        // eslint-disable-next-line array-callback-return
        deviceDetailsArray.map((data, index) => {
          var temp_array = {
            log_msg: data.log_msg,
            user_name: data.u_first_name+" "+data.u_last_name,
            updateTime: data.created_on,
          };
          log_array.push(temp_array);
        })
        setDevices(log_array)
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];

  return (
    <>
      <ToastContainer />
      <Row className="mb-4">
        <Col sm={6} className="gap-3 flex">
          <Dropdown
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.value)}
            options={cities}
            optionLabel="name"
            placeholder="Custom"
            className="w-full"
          />
          <Calendar
            value={startDate}
            onChange={(e) => setStartDate(e.value)}
            placeholder="Start Date"
            showIcon
          />
          <Calendar
            value={endDate}
            onChange={(e) => setEndDate(e.value)}
            placeholder="End Date"
            showIcon
          />
        </Col>


        <Col sm={6} className="gap-3 flex justify-content-end">
          <Dropdown
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.value)}
            options={cities}
            optionLabel="name"
            placeholder="SN"
            className="w-full"
          />
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText placeholder="Search" />
          </span>

          <Button
            label="Search"
            className="btn btn-primary"
            rounded
          />
        </Col>
      </Row>

      <Row>
        <DataTable value={devices} paginator rows={5} sortMode="multiple" rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
          <Column field="updateTime" header="Time" sortable style={{ width: '25%' }}></Column>
          <Column field="user_name" header="User/IP" sortable style={{ width: '25%' }}></Column>
          <Column field="log_msg" sortable header="Details" style={{ width: '25%' }}></Column>
        </DataTable>
      </Row>
    </>
  );
};

export default Log;
