import React, { useState, useEffect,useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import TopBar from "./topbar";
import Menubar from "./menubar";
// Icons
import { ReactComponent as AlarmRedIcon } from "../../assets/Images/client/icons/alarmRed.svg";
import { ReactComponent as InactiveIcon } from "../../assets/Images/client/icons/inactive.svg";
import { ReactComponent as NormalIcon } from "../../assets/Images/client/icons/normal.svg";
import { ReactComponent as OfflineIcon } from "../../assets/Images/client/icons/offline.svg";

import { useNavigate } from 'react-router-dom';
import axios from "axios";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import '../../assets/css/map.css';
import 'leaflet/dist/leaflet.css';

import osm from "./osm-providers";

const markerIcon = new L.Icon({
  iconUrl: require("../../assets/Images/marker.png"),
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

const MarkersMap = () => {
  const navigate = useNavigate()

  const [online_count, setOnlineCount] = useState("0");
  const [offline_count, setOfflineCount] = useState("0");
  const [in_active_count, setInActiveCount] = useState("0");
  const [set_device_data, setDeviceData] = useState([]);

  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);
  const [center, setCenter] = useState([20.5937, 78.9629]);
  const ZOOM_LEVEL = 5;
  const mapRef = useRef();
 

  useEffect(() => {
    if (result) {
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };

      var postData1 = {
        usr_id: result.usr_id,
        client_id: result.client_id,
      };

      axios.post(global.passUrl + 'get_devices_map_data', postData1, axiosConfig).then((res) => {
        setOnlineCount(res.data.online_cnt);
        setOfflineCount(res.data.offline_cnt);
        setInActiveCount(res.data.in_active_cnt);
        setDeviceData(res.data.device_map_data)
        var longDataType = res.data.device_map_data[0];
        if (longDataType) {         
          setCenter([longDataType.lat, longDataType.long]); 
        } else {
          setCenter([20.5937, 78.9629])
        }
      });

      updateAuditLog()
    } else {
      navigate("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  const  updateAuditLog = () => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    var postData1 = {
      usr_id: result.usr_id,
      user_action: "Map Open",
      sys_remark : "Map Open",
      device_eui: ""
    };

    axios.post(global.passUrl + 'call_audit_log', postData1, axiosConfig).then((res) => {      
    });
  }


  return (
    <>
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-lg-block">
            <Menubar pageName="map" />
          </div>

          <div className="col-12 col-lg-11 ps-0">
            {/* Topbar */}
            <TopBar />

            {/* Main body */}
            <div className="MainBody p-4 mapBody">
              <Container className="mw-100">
                <Row>
                  <Col>
                    <Row className="p-3 bg-white border-bottom box">
                      <Col sm={12}>
                        <Row className="gap-4 mb-4">
                          <Col
                            sm={2}
                            className="deviceStatus col-sm-2 flex gap-3 p-3 border align-items-center "
                          >
                            <NormalIcon width={30} height={30} />
                            <div className="flex flex-column align-items-center ">
                              <h2 className="text-success">{online_count}</h2>
                              <p className="mb-0">Normal</p>
                            </div>
                          </Col>
                          <Col
                            sm={2}
                            className="deviceStatus col-sm-2 flex gap-3 p-3 border align-items-center "
                          >
                            <AlarmRedIcon width={30} height={30} />
                            <div className="flex flex-column align-items-center ">
                              <h2 className="text-danger">01</h2>
                              <p className="mb-0">Alarm</p>
                            </div>
                          </Col>
                          <Col
                            sm={2}
                            className="deviceStatus col-sm-2 flex gap-3 p-3 border align-items-center "
                          >
                            <OfflineIcon width={30} height={30} />
                            <div className="flex flex-column align-items-center ">
                              <h2 className="text-secondary">{offline_count}</h2>
                              <p className="mb-0">Offline</p>
                            </div>
                          </Col>
                          <Col
                            sm={2}
                            className="deviceStatus col-sm-2 flex gap-3 p-3 border align-items-center "
                          >
                            <InactiveIcon width={30} height={30} />
                            <div className="flex flex-column align-items-center ">
                              <h2 className="text-primary">{in_active_count}</h2>
                              <p className="mb-0">Inactive</p>
                            </div>
                          </Col>
                        </Row>
                        <Row>                         
                          <div className="col">
                            <MapContainer center={center} zoom={ZOOM_LEVEL} ref={mapRef} >
                              <TileLayer
                                url={osm.maptiler.url}
                                attribution={osm.maptiler.attribution}
                              />
                              { 
                                set_device_data.map((city, idx) => (
                                  <Marker
                                    position={[city.lat, city.long]}
                                    icon={markerIcon}
                                    key={idx}
                                  >
                                    <Popup>
                                      <table className="table table-striped table-bordered table-responsive">
                                        <tr>
                                          <td>Name:</td>
                                          <td>{city.name}</td>
                                        </tr>
                                        <tr>
                                          <td>EUI:</td>
                                          <td>{city.eui}</td>
                                        </tr>
                                        <tr>
                                          <td>Status:</td>
                                          <td>{city.status}</td>
                                        </tr>
                                      </table>
                                    </Popup>
                                  </Marker>
                                ))
                              }
                            </MapContainer>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarkersMap;
