import React, { useRef } from "react";
import Card from "react-bootstrap/Card";
import {ReactComponent as Dots} from "../../../assets/Images/client/dots.svg";
import {ReactComponent as Ellipse} from "../../../assets/Images/client/widgets/Ellipse.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const Temprature = (props) => {
  const deleteData = (id) => {
    var postData = {
      dc_id: id
    };

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + 'delete_widgets', postData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 'success') {
            toast.dismiss()
            toast.success(res.data.message, { theme: "colored", })
            setInterval(() => {
              window.location.href = '';
            }, 2500);
          } else {
            toast.dismiss()
            toast.error(res.data.message, { theme: "colored", })
          }
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Fail!!", { theme: "colored", })
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }
  
  const op = useRef(null);

  return (
    <>
      <ToastContainer/>
      <Card className={`infoBox ${props.status}`}>
        <Card.Title className="mb-0 w-100">
          {props.Name}{" "}
          {
            `${props.widgetDeleteIcon}` === "1" ? 
            <span onClick={(e) => op.current.toggle(e)}>
              <Dots />
            </span> 
            : 
            ""
          }
          <OverlayPanel ref={op}>
            <Button
              onClick={() => deleteData(`${props.widgetPID}`)}
              icon="pi pi-trash"
              className="p-button-danger p-button-outlined"
            ></Button>
          </OverlayPanel>
        </Card.Title>
        <Card.Body>
            <div className="CardImage flex" variant="top">
              <img src={require(`../../../../../server/parameter_images/${props.widgetIcon}`)} style={{ width: '64px',height: '64px'}} alt={props.widgetIcon} />
              <Ellipse/>
            </div>
          <Card.Text>
            <span className="widgetValue" style={{ marginTop: '-60px', fontSize: '3.0rem', color: "#"+props.widgetColorCode }}>{props.widgetValue} <small>{props.widgetUnitSymbol}</small> </span>
            <span className="widgetTitle">{props.widgetTitle}</span>
            <span className="widgetID d-none">{props.widgetID}</span>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default Temprature;
