/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import logoIMG from "../../assets/Images/client/logoImg.png";
import {ReactComponent as DashboardIcon}from "../../assets/Images/client/sidebar/dashboard.svg";
import {ReactComponent as Devices} from "../../assets/Images/client/sidebar/devices.svg";
import {ReactComponent as Map} from "../../assets/Images/client/sidebar/map.svg";
import {ReactComponent as Triggers} from "../../assets/Images/client/sidebar/triggers.svg";
import {ReactComponent as Reports} from "../../assets/Images/client/sidebar/reports.svg";
import {ReactComponent as Event} from "../../assets/Images/client/sidebar/events.svg";
import {ReactComponent as Share} from "../../assets/Images/client/sidebar/sharing.svg";
import {ReactComponent as Users} from "../../assets/Images/client/sidebar/user.svg";
import {ReactComponent as TicketLogo}from "../../assets/Images/client/sidebar/ticket.svg";
import {ReactComponent as SignOut} from "../../assets/Images/client/sidebar/logout.svg";
import axios from "axios";

const menubar = (props) => {

  const [user_name, setUserName] = useState("");

  const [dashbaord_status, setDashboardStatus] = useState(false)
  const [role_status, setRoleStatus] = useState(false)
  const [user_status, setUserStatus] = useState(false)
  const [devices_status, setDeviceStatus] = useState(false)
  const [map_status, setMapStatus] = useState(false)
  const [site_map_status, setSiteMapStatus] = useState(false)
  const [reports_status, setReportsStatus] = useState(false)
  const [events_status, setEventsStatus] = useState(false)
  const [sharing_status, setSharingStatus] = useState(false)
  const [support_ticket_status, setSupportTicketStatus] = useState(false)
  const [profile_status, setProfileStatus] = useState(false)
  const [triggers_status, setTriggersStatus] = useState(false)

  useEffect(() => {
    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);
    if (result) {     
      setUserName(result.u_first_name) 
      setAccessRights()
    } else {
      setUserName("")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);

  function setAccessRights() {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    var AccessData = {
      role_id: result.role_id,
    };      
    axios.post(global.passUrl + 'get_access_details_by_menu', AccessData, axiosConfig).then((res) => {
      if (res.data.access_data.length) {
        // eslint-disable-next-line array-callback-return
        res.data.access_data.map((data1) => {
          if (data1.menu_name === "Dashbaord") {
            if (data1.show_status) {
              setDashboardStatus(true)
            } else {
              setDashboardStatus(false)
            }
          }else if (data1.menu_name === "Devices") {
            if (data1.show_status) {
              setDeviceStatus(true)
            } else {
              setDeviceStatus(false)
            }
          }else if (data1.menu_name === "Map") {
            if (data1.show_status) {
              setMapStatus(true)
            } else {
              setMapStatus(false)
            }
          }else if (data1.menu_name === "Site Map") {
            if (data1.show_status) {
              setSiteMapStatus(true)
            } else {
              setSiteMapStatus(false)
            }
          }else if (data1.menu_name === "Reports") {
            if (data1.show_status) {
              setReportsStatus(true)
            } else {
              setReportsStatus(false)
            }
          }else if (data1.menu_name === "Events") {
            if (data1.show_status) {
              setEventsStatus(true)
            } else {
              setEventsStatus(false)
            }
          }else if (data1.menu_name === "Sharing") {
            if (data1.show_status) {
              setSharingStatus(true)
            } else {
              setSharingStatus(false)
            }
          }else if (data1.menu_name === "support-ticket") {
            if (data1.show_status) {
              setSupportTicketStatus(true)
            } else {
              setSupportTicketStatus(false)
            }
          }else if (data1.menu_name === "Profile") {
            if (data1.show_status) {
              setProfileStatus(true)
            } else {
              setProfileStatus(false)
            }
          }else if (data1.menu_name === "Users") {
            if (data1.show_status) {
              setUserStatus(true)
            } else {
              setUserStatus(false)
            }
          }else if (data1.menu_name === "Role") {
            if (data1.show_status) {
              setRoleStatus(true)
            } else {
              setRoleStatus(false)
            }
          }else if (data1.menu_name === "Triggers") {
            if (data1.show_status) {
              setTriggersStatus(true)
            } else {
              setTriggersStatus(false)
            }
          }
        })
      }
    });
  }

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  }


  return (
    <div className="sideMenu">
      <div className="d-flex flex-column flex-shrink-0 menu">
        <a
          href="/"
          className="d-none d-lg-block p-3 link-dark text-decoration-none"
          title=""
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          data-bs-original-title="Icon-only"
        >
          <img src={logoIMG} className="w-100" alt="CompanyLogo"/>
          <span className="visually-hidden">Icon-only</span>
        </a>


        <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
          {/* Dashboard */}
          <li className={`nav-item ${props.pageName} ${dashbaord_status ? "" : "d-none"} `}>
            <a
              href="/dashboard"
              className={`nav-link py-3 dashboard active`}
              aria-current="page"
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Home"
            >
              <DashboardIcon />
              <p>Dashboard</p>
            </a>
          </li>

          {/* <MenuLink 
            pageLink="Dashboard"
            isActive="active"
            pageTitle="Dashboard"
          /> */}


          <li className={`nav-item ${props.pageName} ${devices_status ? "" : "d-none"} `}>
            <a
              href="/devices"
              className={`nav-link py-3 devices active`}
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Dashboard"
            >
              <Devices />
              <p>Devices</p>
            </a>
          </li>

          <li className={`nav-item ${props.pageName} ${map_status ? "" : "d-none"} `}>
            <a
              href="/map"
              className="nav-link py-3 map active"
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Orders"
            >
              <Map />
              <p>Map</p>
            </a>
          </li>

          <li className={`nav-item ${props.pageName} ${site_map_status ? "" : "d-none"} `}>
            <a
              href="/sitemap-view"
              className="nav-link py-3 sitemap-view active"
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Orders"
            >
              <img src={require('../../assets/Images/admin/sidebar/placeholder.png')} alt='image_name' style={{ width: 35 , height: 35}} />
              <p>Site Map</p>
            </a>
          </li>

          <li className={`nav-item ${props.pageName} ${triggers_status ? "" : "d-none"} `}>
            <a
              href="/triggers"
              className="nav-link py-3 triggers active"
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Triggers"
            >
              <Triggers />
              <p>Triggers</p>
            </a>
          </li>

          <li className={`nav-item ${props.pageName} ${reports_status ? "" : "d-none"} `}>
            <a
              href="/reports"
              className="nav-link py-3 reports active"
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Reports"
            >
              <Reports />
              <p>Reports</p>
            </a>
          </li>

          <li className={`nav-item ${props.pageName} ${events_status ? "" : "d-none"} `}>
            <a
              href="/events"
              className="nav-link py-3 events active"
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Events"
            >
              <Event />
              <p>Events</p>
            </a>
          </li>

          <li className={`nav-item ${props.pageName} ${sharing_status ? "" : "d-none"} `}>
            <a
              href="/sharing"
              className="nav-link py-3 sharing active "
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Sharing"
            >
              <Share />
              <p>Sharing</p>
            </a>
          </li>

          <li className={`nav-item ${props.pageName} ${support_ticket_status ? "" : "d-none"} `} >
            <a
              href="/support-ticket"
              className="nav-link py-3 support-ticket active"
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Support Request"
            >
              <TicketLogo />
              <p>Support Request</p>
            </a>
          </li>
            
          <li className={`nav-item ${props.pageName} ${role_status ? "" : "d-none"} `}>
            <a
              href="/role"
              className={`nav-link py-3 role active`}
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Configuration"
            >
              <Devices />
              <p>Role</p>
            </a>
          </li>

          <li className={`nav-item ${props.pageName} ${user_status ? "" : "d-none"} `}>
            <a
              href="/users"
              className={`nav-link py-3 users active`}
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Configuration"
            >
              <Devices />
              <p>Users</p>
            </a>
          </li>
          
          <li className={`nav-item ${props.pageName} ${profile_status ? "" : "d-none"} `}>
            <a
              href="/profile"
              className="nav-link py-3 profile active"
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Profile"
            >
              <Users />
              <p>{user_name}</p>
            </a>
          </li>

          {/* Signout */}
          <li className="nav-item">
            <div
              onClick={handleLogout}
              className="nav-link py-3 logout cursor-pointer d-none"
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Customers"
            >
              <SignOut />
              <p>Logout</p>
            </div>
          </li>
        </ul>
        


      </div>
    </div>
  );
};

export default menubar;
