/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import Card from "react-bootstrap/Card";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import { ReactComponent as Dots } from "../../../assets/Images/client/dots.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const chartdelete = (props) => {
  const op_alram = React.useRef(null);

  const deleteAlaramDetails = (id, type) => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };
    if (type === "alaram") {
      var postData = {
        alaram_id: id
      };
      axios.post(global.passUrl + 'delete_alaram', postData, axiosConfig)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === 'success') {
              toast.dismiss()
              toast.success(res.data.message, { theme: "colored", })
              setInterval(() => {
                window.location.href = '';
              }, 2500);
            } else {
              toast.dismiss()
              toast.error(res.data.message, { theme: "colored", })
            }
          } else if (res.status === 400) {
            toast.dismiss()
            toast.error("Fail!!", { theme: "colored", })
          }
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
    } else {
      var postData1 = {
        line_id: id,
        chart_type: type
      };
      axios.post(global.passUrl + 'delete_charts', postData1, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 'success') {
            toast.dismiss()
            toast.success(res.data.message, { theme: "colored", })
            setInterval(() => {
              window.location.href = '';
            }, 2500);
          } else {
            toast.dismiss()
            toast.error(res.data.message, { theme: "colored", })
          }
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Fail!!", { theme: "colored", })
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
    }

  }

  return (
    <>
      <ToastContainer />
      <Card.Title className="mb-0 w-100">
        {props.widgetName}
        {
          `${props.widgetDeleteIcon}` === "1" ? 
            <span onClick={(al) => op_alram.current.toggle(al)} className="cursor-pointer">
              <Dots />
            </span>
          : 
            ""
        }
        
        <OverlayPanel ref={op_alram}>
          <Button
            onClick={() => deleteAlaramDetails(`${props.widgetPID}`, `${props.widgetType}`)}
            icon="pi pi-trash"
            className="p-button-danger p-button-outlined"
          ></Button>
        </OverlayPanel>
      </Card.Title>
    </>
  )
}

export default chartdelete