import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import { ReactComponent as DeleteIcon } from "../../../assets/Images/client/icons/delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/Images/client/icons/edit.svg";
import { error } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/Material.css";
import "@pnotify/core/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";
const AlarmRecipient = () => {
  const [devices, setDevices] = useState([]);

  // Add Recipient
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmailId] = useState("");

  const [unit_check, setUnitAddCheck] = useState(true);
  
  const [name_edit, setEditName] = useState("");
  const [email_edit, setEditEmailId] = useState("");
  const [visible_edit, setEditVisible] = useState(false);
  const [edit_check, setEditCheck] = useState();
  const [sh_id, setSharingId] = useState();

  useEffect(() => {
    getSharingDetails();
  }, []);

  const getSharingDetails = () => {
    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);

    var postData = {
      usr_id: result.usr_id
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "get_alarm_details", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        console.log(res.data)
        setDevices(res.data.alarm_details)
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const handleSubmit = () => {
    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);

    var postData = {
      usr_id: result.usr_id,
      name: name,
      email_id: email,      
      is_status: unit_check ? 1 : 0,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "add_alarm_receipent", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        setVisible(false)
        toast.dismiss()
        toast.success(res.data.msg, { theme: "colored", })
        getSharingDetails();
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const editDetails = (id) => {
    var postData = {
      id: id
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "edit_alarm_receipent", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        setEditVisible(true)
        setEditName(res.data.sharing_details[0].name);
        setEditEmailId(res.data.sharing_details[0].email_id);
        if (res.data.sharing_details[0].is_status === 1) {
          setEditCheck(true);
        } else {
          setEditCheck(false);
        }
        setSharingId(id);
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const deleteDetails = (id) => {
    error({
      hide: false,
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Details!",
      modules: new Map([
        [
          Confirm,
          {
            confirm: true,
            buttons: [
              {
                text: "submit",
                primary: false,
                click: notice => {
                  notice.close();
                  deleteRecord(id)
                }
              },
              {
                text: "cancel",
                click: notice => {
                  notice.close();
                }
              }
            ]
          }
        ]
      ])
    });
  }

  const deleteRecord = (id) => {
    var postData = {
      id: id
    };

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + 'delete_alarm_receipent', postData, axiosConfig)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.status === 'success') {
            toast.dismiss()
            toast.success(res.data.message, { theme: "colored", })
            getSharingDetails();
          } else {
            toast.dismiss()
            toast.error(res.data.message, { theme: "colored", })
          }
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Fail!!", { theme: "colored", })
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  const handleUpdate = () => {
    var postData = {
      id: sh_id,
      name: name_edit,
      email: email_edit,
      is_status: edit_check ? 1 : 0,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "update_alarm_receipent", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        console.log(res.data.status)
        if (res.data.status === "success") {
          setEditVisible(false)
          toast.dismiss()
          toast.success(res.data.msg, { theme: "colored", })
          getSharingDetails();
        } else {
          toast.dismiss()
          toast.error(res.data.msg, { theme: "colored", })
        }
        
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const handleChangeSwitch = (e) => {
    const checked = e.target.checked;
    const lightLabel = checked ? true : false;
    setUnitAddCheck(lightLabel);
  };

  const handleEditSwitch = (e) => {
    const checked = e.target.checked;
    const lightLabel = checked ? true : false;
    setEditCheck(lightLabel);
  };
  
  const rowActionsBodyTemplate = (rowData) => {
    return (
      <div className="flex flex-row gap-3">
        <EditIcon onClick={() => editDetails(rowData.id) } />
        <DeleteIcon onClick={() => deleteDetails(rowData.id) } />
      </div>
    );
  };

  const StatusBody = (rowData) => {
    return(
      rowData.is_status ? <Badge bg="success">Active</Badge> : <Badge bg="danger">In-Active</Badge>
    )
  }


  return (
    <>
      <ToastContainer/>
      <Row className="mb-4">
        <Col sm={12} className="gap-3 flex justify-content-end">
          <Button
            label="Add Recipient"
            className="btn btn-primary ms-4"
            icon="pi pi-plus"
            rounded
            onClick={() => setVisible(true)}
          />
        </Col>
      </Row>

      <DataTable value={devices} tableStyle={{ minWidth: "50rem" }} stripedRows>
        <Column field="id" header="ID"></Column>
        <Column field="name" header="Name"></Column>
        <Column field="email_id" header="Email"></Column>
        <Column field="is_status" header="Status" body={StatusBody}></Column>
        <Column
          field=""
          header="Operations"
          body={rowActionsBodyTemplate}
        ></Column>
      </DataTable>

      {/* ----------- Modal of Add Device ------------ */}
      <Dialog
        className="rightPanel"
        header="Add Sharing Details"
        visible={visible}
        style={{ width: "30vw" }}
        onHide={() => setVisible(false)}
      >
        <Row className="mb-4">
          <Col sm={12}>
            <div className="flex flex-column gap-2 mb-4">
              <label htmlFor="Name">Name</label>
              <InputText id="name" className="w-100" value={name} onChange={(e) => setName(e.target.value)} />
            </div>

            <div className="flex flex-column gap-2 mb-4">
              <label htmlFor="email">Email</label>
              <InputText id="email" className="w-100" value={email} onChange={(e) => setEmailId(e.target.value)} />
            </div>
            <Form.Group
              className="mb-4 mandatory"
            >
              <Form.Label>Active </Form.Label>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                checked={unit_check}
                onChange={handleChangeSwitch}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="text-right">
          <Button
            label="Cancle"
            icon="pi pi-times"
            onClick={() => setVisible(false)}
            severity="danger"
            rounded
          />
          <Button
            label="Confirm"
            icon="pi pi-check"
            onClick={() => handleSubmit()}
            className="ms-2"
            autoFocus
            rounded
          />
        </div>
      </Dialog>
      
      {/* ----------- Modal of Edit Device ------------ */}
      <Dialog
        className="rightPanel"
        header="Edit Sharing Details"
        visible={visible_edit}
        style={{ width: "30vw" }}
        onHide={() => setEditVisible(false)}
      >
        <Row className="mb-4">
          <Col sm={12}>
            <div className="flex flex-column gap-2 mb-4">
              <label htmlFor="Name">Name</label>
              <InputText id="name" className="w-100" value={name_edit} onChange={(e) => setEditName(e.target.value)} />
            </div>

            <div className="flex flex-column gap-2 mb-4">
              <label htmlFor="email">Email</label>
              <InputText id="email" className="w-100" value={email_edit} onChange={(e) => setEditEmailId(e.target.value)} />
            </div>

            <Form.Group
              className="mb-4 mandatory"
            >
              <Form.Label>Active </Form.Label>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                checked={edit_check}
                onChange={handleEditSwitch}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="text-right">
          <Button
            label="Cancle"
            icon="pi pi-times"
            onClick={() => setEditVisible(false)}
            severity="danger"
            rounded
          />
          <Button
            label="Confirm"
            icon="pi pi-check"
            onClick={() => handleUpdate()}
            className="ms-2"
            autoFocus
            rounded
          />
        </div>
      </Dialog>
    </>
  );
};

export default AlarmRecipient;
