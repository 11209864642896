import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import { Col } from "react-bootstrap";
// import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
// import { InputText } from "primereact/inputtext";
// import Navbar from "react-bootstrap/Navbar";
// import { Tag } from 'primereact/tag';
import Topbar from '../topbar';
import Menubar from "../menubar";
// import Menubar2 from "../menubar2";
import AlarmReporting from "./alarmReporting";
import TriggerLog from "./triggerLog";
import ReportingRecords from "./reportingRecords";
import AuditLogDetails from "./AuditLogDetails";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const Events = () => {

  // const tab1HeaderTemplate = (options) => {
  //   return (
  //       <button type="button" onClick={options.onClick} className={options.className} style={{padding: "0.8rem 3rem"}}>
  //       {options.titleElement}
  //         <Tag severity="warning" value="23" className="ms-2" style={{fontSize: "12px", fontWeight: "normal"}}></Tag>
  //       </button>
  //   );
  // };

  const [alaram_status, setAlaramStatus] = useState(0)
  const [tigger_log_status, setTiggerLogStatus] = useState(0)
  const [reporting_records_status, setReportingRecordsStatus] = useState(0)
  const [audit_log_status, setAuditLogStatus] = useState(0)
  
  const navigate = useNavigate()

  
  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);

  useEffect(()=>{
    if (result) {
      
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };

      var AccessData = {
        role_id: result.role_id,
        menu_id: 5,
      };      
      axios.post(global.passUrl + 'get_access_details', AccessData, axiosConfig).then((res) => {
        if (res.data.access_data.length) {
          // eslint-disable-next-line array-callback-return
          res.data.access_data.map((data1) => {
            if (data1.privileges_key === "ALARAM") {
              if (data1.status) {
                setAlaramStatus(1)
              } else {
                setAlaramStatus(0)
              }
            }else if (data1.privileges_key === "TL") {
              if (data1.status) {
                setTiggerLogStatus(1)
              } else {
                setTiggerLogStatus(0)
              }
            }else if (data1.privileges_key === "ReportingLog") {
              if (data1.status) {
                setReportingRecordsStatus(1)
              } else {
                setReportingRecordsStatus(0)
              }
            }else if (data1.privileges_key === "AuditLog") {
              if (data1.status) {
                setAuditLogStatus(1)
              } else {
                setAuditLogStatus(0)
              }
            }
          })
        }
      });
     

    } else {
      navigate("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <>
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-lg-block">
            <Menubar pageName="events" />
          </div>

          <div className="col-12 col-lg-11 ps-0">
            {/* Topbar */}
            <Topbar />

            {/* Main body */}
            <div className="MainBody p-4 deviceSettings">
              <Container className="mw-100">
                <TabView>
                  {/* -------------- Alarm Reporting Starts here --------------- add headerTemplate={tab1HeaderTemplate} Tag load */}
                  { 
                    alaram_status === 1 ?
                    <TabPanel header="Alarm Reporting">
                      <AlarmReporting />
                    </TabPanel>
                    :
                    ""
                  }
                  {/* -------------- Alarm Reporting Ends here ----------------- */}

                  {/* -------------- Trigger Log Starts here --------------- */}
                  {
                    tigger_log_status === 1 ?
                    <TabPanel header="Trigger Log">
                      <TriggerLog />
                    </TabPanel>
                    :
                    ""
                  }                  
                  {/* -------------- Trigger Log Ends here --------------- */}

                  {/* -------------- Reporting Records Starts here --------------- */}
                  {
                    reporting_records_status === 1 ?
                    <TabPanel header="Reporting Records">
                      <ReportingRecords />
                    </TabPanel>
                    :
                    ""
                  }  
                  {/* -------------- Reporting Records Ends here --------------- */}

                  {/* -------------- Reporting Records Starts here --------------- */}
                  {
                    audit_log_status === 1 ?
                    <TabPanel header="Audit Log">
                      <AuditLogDetails />
                    </TabPanel>
                    :
                    ""
                  }                    
                  {/* -------------- Reporting Records Ends here --------------- */}
                  
                </TabView>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
