import React, { useState ,useEffect} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Dropdown } from "primereact/dropdown";
import Card from "react-bootstrap/Card";
import { ReactComponent as Bell } from "../../../assets/Images/client/icons/bell.svg";
import "primeicons/primeicons.css";
import axios from "axios";
import { Button } from "primereact/button";
import { ToastContainer, toast } from "react-toastify";

const Alarm = (props) => {

  const [selectedDevice, setSelectedDevice] = useState(null);
  const [device_details, setDeviceDetails] = useState([]);
  const [selected_dashboard, setSelectedDashboard] = useState();
  useEffect(() => {
    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);
    getDeviceData(result.client_id)
  },[])

  const getDeviceData = (c_id) => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };
    
    var postData = {
      client_id: c_id,
    };

    axios.post(global.passUrl + 'get_client_device_data_active', postData, axiosConfig).then((res) => {
      setDeviceDetails(res.data.device_data)
    });

    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);

    var postData1 = {
      client_id: result.client_id
    };

    axios.post(global.passUrl + 'get_dashbaord_data', postData1, axiosConfig).then((res) => {
      setSelectedDashboard(res.data.selected_dashbaord[0].id);
    });
  }

  const submitDeviceData = () => {
    if (selectedDevice) {
      var postData = {
        client_id: selectedDevice.client_id,
        device_eui: selectedDevice.device_eui,
        selected_dashboard: selected_dashboard
      };
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };
      axios.post(global.passUrl + 'add_alarm_data', postData, axiosConfig)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === 'success') {
              toast.dismiss()
              toast.success(res.data.message, { theme: "colored", })
              setInterval(() => {
                window.location.href = '';
              }, 2500);
            } else {
              toast.dismiss()
              toast.error(res.data.message, { theme: "colored", })
            }
          } else if (res.status === 400) {
            window.location.href = '';
          }
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
      console.log(postData)
    }else{
      toast.dismiss()
      toast.error("Please select a device.", { theme: "colored", })
    }
  }

  return (
    <>
      <ToastContainer/>
      <Container>
        <Row className="mb-4">
          <Col sm={8}>
            <div className="flex flex-row gap-2 align-items-center justify-content-start">
              <label htmlFor="record">Record information content</label>
              <Dropdown
                value={selectedDevice}
                onChange={(e) => setSelectedDevice(e.value)}
                options={device_details}
                optionLabel="device_eui"
                placeholder="Select a Device"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={7}>
            <Card className="alarm">
              <Card.Header>Alarm Name</Card.Header>
              <Card.Body>
                <Row className="p-3 row border-bottom">
                  <Col sm={3}>
                    <Bell />
                  </Col>
                  <Col sm={9}>
                    <h4>n1(641193115035)</h4>
                    <h5 className="text-success">
                      <i className="pi pi-circle-fill me-2"></i>
                      The Device is Online
                    </h5>
                    <p>2023-02-08 16:04:00</p>
                  </Col>
                </Row>

                <Row className="p-3 row border-bottom">
                  <Col sm={3}>
                    <Bell />
                  </Col>
                  <Col sm={9}>
                    <h4>n1(641193115035)</h4>
                    <h5 className="text-danger">
                      <i className="pi pi-circle-fill me-2"></i>
                      The Device is Offline
                    </h5>
                    <p>2023-02-08 16:04:00</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="text-end tab-footer" fixed="bottom">
          <div className="flex flex-wrap justify-content-end gap-3">
            <Button label="Confirm" className="ms-2" rounded onClick={submitDeviceData}/>
          </div>
        </Row> 
      </Container>
    </>
  );
};

export default Alarm;
