import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "@mui/material";
import InterfaceSettings from "./interfaceSettings";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import { Dialog } from "primereact/dialog";

import { useParams } from 'react-router-dom'
import Log from "./log";
import Calibration from "./Calibration";
import Menubar from "../menubar";
import TopBar from "../deviceManagement/myDeviceTopbar";
import { useNavigate } from 'react-router-dom';


const DeviceSettings = () => {
  const paramsData = useParams();

  const navigate = useNavigate()

  const [visible, setVisible] = useState(false);
  const [device_name, setDeviceName] = useState();
  const [device_eui, setDeviceEUI] = useState();
  const [device_description_val, setDeviceDescrption] = useState("");
  const [reporting_interval, setReportingInterval] = useState("");
  const [device_alram_alert, setDeviceAlramAlert] = useState(false);
  const [threshold_alarm, setThresholdAlarm] = useState(false);
  const [low_battery_alarm, setLowBatteryAlarm] = useState(false);
  const [device_longitude, setDeviceLongitude] = useState("");
  const [device_latitude, setDevicelatitude] = useState("");

  
  const [device_eui_new, setNewDeviceEUI] = useState("");
  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);

  
  const  updateAuditLog = (device_data) => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    var postData1 = {
      usr_id: result.usr_id,
      user_action: "Device",
      sys_remark : "Device Setting Open",
      device_eui: device_data
    };

    axios.post(global.passUrl + 'call_audit_log', postData1, axiosConfig).then((res) => {
      
    });
  }
  

  useEffect(() => {
    if (result) {
      var postData = {
        cd_id: paramsData.d_id,
        user_id: result.usr_id
      };
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        }
      };
  
      axios.post(global.passUrl + "get_device_details", postData, axiosConfig).then((res) => {
        if (res.status === 200) {
          const deviceDetailsArray = res.data.device_details[0];
          if (deviceDetailsArray.device_offline_alarm) {
            setDeviceAlramAlert(true)
          }
          if (deviceDetailsArray.low_battery_alarm) {
            setLowBatteryAlarm(true)
          }
          if (deviceDetailsArray.threshold_alarm) {
            setThresholdAlarm(true)
          }
  
          setDeviceName(deviceDetailsArray.device_name)
          setDeviceEUI(deviceDetailsArray.device_eui)
          setDeviceDescrption(deviceDetailsArray.device_description_val === null ? "" : deviceDetailsArray.device_description_val)
          setReportingInterval(deviceDetailsArray.reporting_interval === null ? 0 : deviceDetailsArray.reporting_interval)
          setDeviceLongitude(deviceDetailsArray.device_long === null ? "" : deviceDetailsArray.device_long)
          setDevicelatitude(deviceDetailsArray.device_lat === null ? "" : deviceDetailsArray.device_lat)

          updateAuditLog(deviceDetailsArray.device_eui)
      
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Faill!!", { theme: "colored", })
        }
      }).catch((err) => { console.log("AXIOS ERROR: ", err); });  
      
    } else {
      navigate("/")
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const items = [
    { label: "My Devices", url: "/devices" },
    { label: device_eui },
    { label: "Settings" },
  ];
  const home = { icon: "pi pi-home", url: "/dashboard" };

  const handleSubmit = () => {
    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);

    var postData = {
      cd_id: paramsData.d_id,
      device_name: device_name,
      device_eui: device_eui,
      device_description: device_description_val,
      reporting_interval: reporting_interval,
      device_lat: device_latitude,
      device_long: device_longitude,
      device_alram_alert: device_alram_alert ? 1 : 0,
      low_battery_alarm: low_battery_alarm ? 1 : 0,
      threshold_alarm: threshold_alarm ? 1 : 0,
      user_id: result.usr_id
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + "update_device_basic_details", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        if (res.data.status === "success") {
          toast.dismiss()
          toast.success(res.data.message, { theme: "colored", })
        } else {
          toast.dismiss()
          toast.error(res.data.message, { theme: "colored", })
        }
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const handleDeviceEUISubmit = () => {
    if (device_eui_new === "") {
      toast.dismiss()
      toast.error("Please enter device id.", { theme: "colored", })
    } else {
      
      var data = localStorage.getItem('userData');
      var result = JSON.parse(data);

      var postData = {
        cd_id: paramsData.d_id,
        device_eui: device_eui_new,
        user_id: result.usr_id
      };
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        }
      };
  
      axios.post(global.passUrl + "update_device_eui", postData, axiosConfig).then((res) => {
        if (res.status === 200) {
          if (res.data.status === "success") {
            setVisible(false);
            setDeviceEUI(device_eui_new);
            setNewDeviceEUI("");
            toast.dismiss()
            toast.success(res.data.message, { theme: "colored", })
          } else {
            toast.dismiss()
            toast.error(res.data.message, { theme: "colored", })
          }
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Faill!!", { theme: "colored", })
        }
      }).catch((err) => { console.log("AXIOS ERROR: ", err); });

      var postData1 = {
        usr_id: result.usr_id,
        user_action: "Device",
        sys_remark : "Update Device EUI "+ device_eui_new,
        device_eui: ""
      };
  
      axios.post(global.passUrl + 'call_audit_log', postData1, axiosConfig).then((res) => {
        
      });
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-lg-block">
            <Menubar pageName="devices" />
          </div>

          <div className="col-12 col-lg-11 ps-0">
            <TopBar />

            {/* Main body */}
            <div className="MainBody p-4 deviceSettings">
              <Container className="mw-100">
                <Row>
                  <Col sm={6}>
                    {/* Breadcrum here */}
                    <BreadCrumb model={items} home={home} />
                  </Col>
                  <Col sm={6} className="text-end">
                    <Button
                      label="Refresh"
                      className="btn btn-primary ms-4"
                      rounded
                      size="small"
                    />
                    <Button
                      label="Share"
                      className="btn btn-primary ms-4"
                      rounded
                      icon="pi pi-share-alt"
                      size="small"
                      outlined
                    />
                  </Col>
                </Row>

                <TabView>
                  {/* -------------- Basic Settings Starts here --------------- */}
                  <TabPanel header="Basic Settings">
                    <Container>
                      <Row>
                        <Col sm={7}>
                          <div className="flex flex-row gap-2 align-items-center mb-4">
                            <label htmlFor="name" className="font-bold w-50">
                              Name
                            </label>
                            <InputText id="name" className="w-100" value={device_name}
                              onChange={(e) => setDeviceName(e.target.value)} />
                          </div>

                          <div className="flex flex-row gap-2 align-items-center mb-4">
                            <label htmlFor="key" className="font-bold w-50">
                              Application Key
                            </label>
                            <InputText id="key" className="w-100" />
                          </div>

                          <div className="flex flex-row gap-2 mb-4">
                            <label htmlFor="desc" className="font-bold w-50">
                              Description
                            </label>
                            <InputTextarea
                              placeholder="Description"
                              rows={5}
                              cols={30}
                              className="w-100"
                              value={device_description_val}
                              onChange={(e) => setDeviceDescrption(e.target.value)}
                            />
                          </div>

                          <div className="flex flex-row gap-2 mb-4">
                            <label
                              htmlFor="interval"
                              className="font-bold w-50"
                            >
                              Latitude
                            </label>
                            <InputText
                              className="w-100"
                              value={device_latitude}
                              onChange={(e) => setDevicelatitude(e.target.value)}
                            />
                          </div>
                          <div className="flex flex-row gap-2 mb-4">
                            <label
                              htmlFor="interval"
                              className="font-bold w-50"
                            >
                              Longitude
                            </label>
                            <InputText
                              className="w-100"
                              value={device_longitude}
                              onChange={(e) => setDeviceLongitude(e.target.value)}
                            />
                          </div>

                          <div className="flex flex-row gap-2 mb-4">
                            <label
                              htmlFor="interval"
                              className="font-bold w-50"
                            >
                              Reporting Interval
                            </label>
                            <InputNumber
                              inputId="interval"
                              className="w-100"
                              suffix=" min"
                              value={reporting_interval}
                              onChange={(e) => setReportingInterval(e.value)}
                            />
                          </div>

                          <div className="flex flex-row gap-2 mb-4">
                            <label
                              htmlFor="offlineAlarm"
                              className="font-bold w-50"
                            >
                              Device Offline Alarm
                            </label>
                            <Checkbox
                              onChange={(e) => setDeviceAlramAlert(e.checked)}
                              checked={device_alram_alert}
                              className="w-100"
                            ></Checkbox>
                          </div>

                          <div className="flex flex-row gap-2 mb-4">
                            <label
                              htmlFor="offlineAlarm"
                              className="font-bold w-50"
                            >
                              Threshold Alarm
                            </label>
                            <Checkbox
                              onChange={(e) => setThresholdAlarm(e.checked)}
                              checked={threshold_alarm}
                              className="w-100"
                            ></Checkbox>
                          </div>

                          <div className="flex flex-row gap-2 mb-4">
                            <label
                              htmlFor="offlineAlarm"
                              className="font-bold w-50"
                            >
                              Low Battery Alarm
                            </label>
                            <Checkbox
                              onChange={(e) => setLowBatteryAlarm(e.checked)}
                              checked={low_battery_alarm}
                              className="w-100"
                            ></Checkbox>
                          </div>
                          <Divider />
                          <Row className="mt-4">
                            <Col>
                              <Button
                                label="Cancle"
                                icon="pi pi-times"
                                severity="danger"
                                rounded
                              />
                              <Button
                                label="Save"
                                icon="pi pi-check"
                                className="ms-4"
                                autoFocus
                                rounded
                                onClick={() => handleSubmit()}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </TabPanel>
                  {/* -------------- Basic Settings Ends here ----------------- */}

                  {/* -------------- Interface Settings Starts here --------------- */}
                  <TabPanel header="Interface Settings">
                    <InterfaceSettings />
                  </TabPanel>
                  {/* -------------- Interface Settings Ends here --------------- */}

                  {/* -------------- Maintenance Starts here --------------- */}
                  <TabPanel header="Maintenance">
                    <Row className="mh-100 justify-content-center">
                      <Col sm={4} className="text-center pt-5">
                        <Button
                          label="Replace"
                          className="btn btn-primary mb-2"
                          rounded
                          onClick={() => setVisible(true)}
                        />
                        <p>Replace Device ID :</p>
                      </Col>
                    </Row>
                  </TabPanel>
                  {/* -------------- Maintenance Ends here --------------- */}

                  {/* ----------- Log Starts here -------------------- */}
                  <TabPanel header="Log">
                    <Log />
                  </TabPanel>
                  {/* ----------- Log Ends here ---------------------- */}

                  {/* ----------- Calibration Starts here ---------------------- */}
                  <TabPanel header="Calibration">
                    <Calibration />
                  </TabPanel>
                  {/* ----------- Calibration Ends here ----------------------- */}
                </TabView>

                {/* ----------- Modal of Add Device ------------ */}
                <Dialog
                  className="rightPanel"
                  header="Replace the Device EUI"
                  visible={visible}
                  style={{ width: "30vw" }}
                  onHide={() => setVisible(false)}
                >
                  <Row className="mb-4">
                    <Col sm={12}>
                      <div className="flex flex-column gap-2 mb-4">
                        <label htmlFor="Name">Old EUI ID</label>
                        <InputText id="name" className="w-100" value={device_eui} disabled={true} />
                      </div>

                      <div className="flex flex-column gap-2 mb-4">
                        <label>Device EUI ID</label>
                        <InputText id="device_eui_id_new" className="w-100" value={device_eui_new} onChange={(e) => setNewDeviceEUI(e.target.value) } />
                      </div>

                    </Col>
                  </Row>
                  <div className="text-right">
                    <Button
                      label="Cancle"
                      icon="pi pi-times"
                      onClick={() => setVisible(false)}
                      severity="danger"
                      rounded
                    />
                    <Button
                      label="Confirm"
                      icon="pi pi-check"
                      onClick={() => handleDeviceEUISubmit()}
                      className="ms-2"
                      autoFocus
                      rounded
                    />
                  </div>
                </Dialog>

              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceSettings;
