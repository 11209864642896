import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from '../../../assets/Images/default.gif';
import moment from 'moment'

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import Excel from '../../../assets/Images/xls.png';
// import Pdf from '../../../assets/Images/pdf.png';
var fileDownload = require('js-file-download');

const Notifications = () => {

  const navigate = useNavigate()
  const [selectedDevicesList, setSelectedDeviceList] = useState(null);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [devices, setDevices] = useState([]);
  const [selectedDeviceParameter, setSelectedDeviceParameter] = useState({ dp_id: '0', parameter_name: 'All', display_name: 'All', });

  const [loading, setLoadding] = useState(false);
  const [pdf_laoding, setPdfLoadding] = useState(false);

  const [report_data_filter, setReportDataFilter] = useState([]);
  const [parameter_data, setParameterData] = useState([]);
  const [report_data_get, setReportDataGet] = useState(false);

  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);

  useEffect(() => {
    if (result) {
      // getSharingDetails();
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };
  
      var postData = {
        client_id: result.client_id,
      };
  
      axios.post(global.passUrl + 'get_client_device_data_active', postData, axiosConfig).then((res) => {
        setDevices(res.data.device_data)
      });
    } else {
      navigate("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /*
    const getSharingDetails = () => {
      var postData = {
        usr_id: result.usr_id
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        }
      };

      axios.post(global.passUrl + "get_notification_details", postData, axiosConfig).then((res) => {
        if (res.status === 200) {
          setReportDataGet(true);
          const newArray = [];
          var not_id = 0;
          var color_code = "";
          // eslint-disable-next-line array-callback-return            
          res.data.alarm_details.map((data) => {
            if (data.type === "upper") {
              color_code = 'red';
            } else {
              color_code = '#bdbd28';
            }

            var temp_array = {
              id: not_id + 1,
              limit_value: data.limit_value,
              device_name: data.device_name,
              device_value: <p style={{color: color_code, 'fontWeight': 600, fontSize: '13px'}}>{data.device_value}</p>,
              message: data.message,
              received_datetime: moment(data.received_datetime).format('DD MMMM, YYYY HH:mm:ss')
            }
            not_id = not_id + 1;
            newArray.push(temp_array);
          });
          setReportDataFilter(newArray)
        } else if (res.status === 400) {
          setReportDataGet(false);
          toast.dismiss()
          toast.error("Faill!!", { theme: "colored", })
        }
      }).catch((err) => { console.log("AXIOS ERROR: ", err); });
    }
  */

  const handleSubmit = () => {
  
    const startDateVal = (new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(startDate));

    const endDateVal = (new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(endDate));
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    if (selectedDevice) {
      var postData = {
        start_date: moment(startDateVal).format('YYYY-MM-DD'),
        end_date: moment(endDateVal).format('YYYY-MM-DD'),
        device_id: selectedDevice ? selectedDevice.cd_id : "",
        client_id: selectedDevice ? selectedDevice.client_id : "",
        parameter_id: selectedDeviceParameter.parameter_name === "All" ? "0" : selectedDeviceParameter.dp_id,
        usr_id: result.usr_id,
        type: 'date'
      };
      setLoadding(true);
      axios.post(global.passUrl + "get_notification_by_search_details", postData, axiosConfig).then((res) => {
        setLoadding(false)
        if (res.status === 200) {
          if (res.data.status === 'error') {
            setReportDataGet(false);
            setReportDataFilter([])
            toast.dismiss()
            toast.error(res.data.msg, { theme: "colored", })
          } else {
            const newArray = [];
            // 
            var not_id = 0;
            var color_code = "";
            // eslint-disable-next-line array-callback-return            
            res.data.alarm_details.map((data) => {
              if (data.type === "upper") {
                color_code = 'red';
              } else {
                color_code = '#bdbd28';
              }

              var temp_array = {
                id: not_id + 1,
                limit_value: data.limit_value,
                device_name: data.device_name,
                device_value: <p style={{color: color_code, 'fontWeight': 600, fontSize: '13px'}}>{data.device_value}</p>,
                message: data.message,
                received_datetime: moment(data.received_datetime).format('DD MMMM, YYYY HH:mm:ss')
              }
              not_id = not_id + 1;
              newArray.push(temp_array);
            });
            setReportDataGet(true);
            setReportDataFilter([])
            setReportDataFilter(newArray)
          }
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Faill!!", { theme: "colored", })
        }
      }).catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
    }else{
      var postData1 = {
        start_date: moment(startDateVal).format('YYYY-MM-DD'),
        end_date: moment(endDateVal).format('YYYY-MM-DD'),
        usr_id: result.usr_id,
        type: 'all_filter'
      };
      setLoadding(true);
      axios.post(global.passUrl + "get_notification_by_search_details", postData1, axiosConfig).then((res) => {
        setLoadding(false)
        if (res.status === 200) {
          if (res.data.status === 'error') {
            setReportDataGet(false);
            setReportDataFilter([])
            toast.dismiss()
            toast.error(res.data.msg, { theme: "colored", })
          } else {
            const newArray = [];
            // 
            var not_id = 0;
            var color_code = "";
            // eslint-disable-next-line array-callback-return            
            res.data.alarm_details.map((data) => {
              if (data.type === "upper") {
                color_code = 'red';
              } else {
                color_code = '#bdbd28';
              }

              var temp_array = {
                id: not_id + 1,
                limit_value: data.limit_value,
                device_name: data.device_name,
                device_value: <p style={{color: color_code, 'fontWeight': 600, fontSize: '13px'}}>{data.device_value}</p>,
                message: data.message,
                received_datetime: moment(data.received_datetime).format('DD MMMM, YYYY HH:mm:ss')
              }
              not_id = not_id + 1;
              newArray.push(temp_array);
            });
            setReportDataGet(true);
            setReportDataFilter([])
            setReportDataFilter(newArray)
          }
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Faill!!", { theme: "colored", })
        }
      }).catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
    }    
  }

  // var userData = localStorage.getItem('userData');
  // var userDataResult = JSON.parse(userData);


  const getExcelReport = () => {

    setPdfLoadding(true);

    const startDateVal = (new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(startDate));

    const endDateVal = (new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(endDate));

    if(selectedDevice){
      var postData = {
        start_date: moment(startDateVal).format('YYYY-MM-DD'),
        end_date: moment(endDateVal).format('YYYY-MM-DD'),
        device_id: selectedDevice ? selectedDevice.cd_id : "",
        client_id: selectedDevice ? selectedDevice.client_id : "",
        parameter_id: selectedDeviceParameter.parameter_name === "All" ? "0" : selectedDeviceParameter.dp_id,
        usr_id: result.usr_id,
        type: 'date'
      };
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        responseType: 'blob'
      };
  
      var fileName = "alaram_reporting_" + moment(new Date()).format('HHmmss_DDMMYYYY') + ".xlsx";
  
      axios.post(global.passUrl + "get_excel_alaram_report", postData, axiosConfig).then((res) => {
        if (res.status === 200) {
          setPdfLoadding(false);
          fileDownload(res.data, fileName);
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Faill!!", { theme: "colored", })
        }
      }).catch((err) => { console.log("AXIOS ERROR: ", err); });
    }else{
      var postData1 = {
        start_date: moment(startDateVal).format('YYYY-MM-DD'),
        end_date: moment(endDateVal).format('YYYY-MM-DD'),
        usr_id: result.usr_id,
        type: 'all_filter'
      };
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        responseType: 'blob'
      };
  
      var fileName1 = "alaram_reporting_" + moment(new Date()).format('HHmmss_DDMMYYYY') + ".xlsx";
  
      axios.post(global.passUrl + "get_excel_alaram_report", postData1, axiosConfig).then((res) => {
        if (res.status === 200) {
          setPdfLoadding(false);
          fileDownload(res.data, fileName1);
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Faill!!", { theme: "colored", })
        }
      }).catch((err) => { console.log("AXIOS ERROR: ", err); });
    }
    
  }

  /*
    const getPdfReport = () => {

      setPdfLoadding(true);

      const startDateVal = (new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(startDate));

      const endDateVal = (new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(endDate));

      var postData = {
        start_date: moment(startDateVal).format('YYYY-MM-DD'),
        end_date: moment(endDateVal).format('YYYY-MM-DD'),
        device_eui: selectedDevice ? selectedDevice.device_eui : "",
        device_id: selectedDevice ? selectedDevice.cd_id : "",
        device_name: selectedDevice ? selectedDevice.device_name : "",
        client_id: selectedDevice ? selectedDevice.client_id : "",
        parameter_id: selectedDeviceParameter.parameter_name === "All" ? "0" : selectedDeviceParameter.parameter_name
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        responseType: 'blob'
      };

      var fileName = "historical_data_" + moment(new Date()).format('HHmmss_DDMMYYYY') + ".pdf";

      let mailAxiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      var postData2 = {
        fileNameVal: "historical_data_" + moment(new Date()).format('HHmmss_DDMMYYYY'),
        usr_id: userDataResult.usr_id,
        type: "pdf",
        user_name: userDataResult.u_first_name + " " + userDataResult.u_last_name
      };


      axios.post(global.passUrl + "get_pdf_report", postData, axiosConfig).then((res) => {
        if (res.status === 200) {
          // setPdfLoadding(false)
          fileDownload(res.data, fileName);
          axios.post(global.passUrl + "sent_email_excel", postData2, mailAxiosConfig).then((res) => {
            if (res.status === 200) {
              setPdfLoadding(false)
            } else if (res.status === 400) {
              toast.dismiss()
              toast.error("Faill!!", { theme: "colored", })
            }
          }).catch((err) => {
            setPdfLoadding(false);
            console.log("AXIOS ERROR: ", err);
          });
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Faill!!", { theme: "colored", })
        }
      }).catch((err) => { console.log("AXIOS ERROR: ", err); });
    }
  */

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    d_eui: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    'option_key': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
  });

  const getParameterData = (value) => {

    var postData = {
      device_data: value
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };

    // let new_array = [];
    axios.post(global.passUrl + "get_parameter_details", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        if (res.data.status === 'error') {
          setParameterData([])
          toast.dismiss()
          toast.error(res.data.msg, { theme: "colored", })
        } else {
          setSelectedDevice(value)
          setParameterData(res.data.paramete_data)
        }
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => {
      console.log("AXIOS ERROR: ", err);
    });
  }

  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
  };

  const renderHeader = () => {
    const value = filters['global'] ? filters['global'].value : '';

    return (
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Search" />
      </span>
    );
  };

  const header = renderHeader();
  return (
    <>
      <ToastContainer />
      <Col>
        <Row>
          <Col>
            <Row
              className="p-3 bg-white border-bottom box"
              style={{ height: "calc(100vh - 155px)" }}
            >
              <Col sm={12}>
                <Row className="mb-4">
                  <Col sm={6} className="gap-3 flex">
                    <Calendar
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      placeholder="Start Date"
                      showIcon
                      dateFormat="dd/mm/yy"
                      name="start_date"
                      className="w-50"
                      showButtonBar
                      maxDate={new Date()}
                    />

                    <Calendar
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      placeholder="End Date"
                      showIcon
                      minDate={startDate}
                      dateFormat="dd/mm/yy"
                      name="end_date"
                      className="w-50"
                      showButtonBar
                    />

                    <Dropdown
                      value={selectedDevice}
                      onChange={(e) => getParameterData(e.value)}
                      options={devices}
                      optionLabel="device_name"
                      placeholder="Select Device"
                      className="w-50"
                    />
                    <Dropdown
                      value={selectedDeviceParameter}
                      onChange={(e) => setSelectedDeviceParameter(e.value)}
                      options={parameter_data}
                      optionLabel="parameter_name"
                      placeholder="Select Parameter"
                      className="w-50"
                    />
                  </Col>

                  <Col
                    sm={2}
                    className="gap-3 flex"
                  >
                    <Button
                      label="Fetch Data"
                      className="btn btn-primary w-50"
                      rounded
                      onClick={handleSubmit}
                    />
                  </Col>

                  <Col
                    sm={1}
                  >
                    {
                      pdf_laoding ?
                        <div className="col-sm-12 text-center">
                          <img src={Loader} alt="logo" style={{ width: 30 }} /> <br />
                          <span>Loading...</span>
                        </div>
                        :
                        ""
                    }
                  </Col>

                  <Col
                    sm={3}
                    className="gap-3 flex justify-content-end"
                  >
                    {
                      report_data_filter.length > 0 ?
                        <>
                          <img src={Excel} alt="logo" className="cursor-pointer" style={{ width: 40, height: 40 }} onClick={getExcelReport} />
                        </>
                        :
                        ""
                    }
                  </Col>


                </Row>

                <Row className="datatable">
                  {loading ?
                    <div className="col-sm-12 mt-4 text-center">
                      <img src={Loader} alt="logo" style={{ width: 30 }} /> <br />
                      <span>Fetching Data...</span>
                    </div>
                    :
                    <div className="col-sm-12 mt-4 mb-5">
                      {
                        report_data_get ?
                          <DataTable
                            value={report_data_filter}
                            selection={selectedDevicesList}
                            onSelectionChange={(e) => setSelectedDeviceList(e.value)}
                            dataKey="id"
                            paginator
                            rows={10}
                            sortMode="multiple"
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            tableStyle={{ minWidth: "50rem" }}
                            filters={filters}
                            onFilter={(e) => setFilters(e.filters)}
                            header={header}
                          >
                            <Column field="id" header="Sr.No" x="id" style={{ width: '7%' }} sortable></Column>
                            <Column field="device_name" header="Device Name" headerStyle={{ width: "20%" }} sortable></Column>
                            <Column field="limit_value" header="Device Limit" headerStyle={{ width: "20%" }} sortable></Column>
                            <Column field="device_value" header="Device Value" headerStyle={{ width: "20%" }} sortable></Column>
                            <Column field="message" header="Message" headerStyle={{ width: "20%" }} sortable></Column>                            
                            <Column field="received_datetime" header="Date/Time" headerStyle={{ width: "30%" }} sortable></Column>
                          </DataTable>
                          :
                          ""
                      }
                    </div>
                  }
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Notifications;
