import React, { useState } from 'react';
import logo from '../../assets/Images/auth/logo-white.png';
import userIcon from '../../assets/Images/auth/userIcon.svg';
import colorLogo from '../../assets/Images/auth/logo.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../pages/global';
import EyeIcon from '../../assets/Images/auth/eye-svgrepo-com 1.svg';
import hiddenEye from '../../assets/Images/auth/eyeIcon.svg';
import '../../assets/css/client.css'
import Clock from 'react-live-clock';

export default function Login() {
  const navigate = useNavigate();
  const [isPasswordShow, setIsPasswordShow] = useState(false);

  const toggleIsPasswordShowValue = () => {
    setIsPasswordShow(!isPasswordShow);
  };


  
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    var postData = {
      email: data.get('admin_signin_email'),
      password: data.get('admin_signin_password'),
    };

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };
    
    if (postData.email && postData.password) {
      axios.post(global.passUrl + 'login', postData, axiosConfig)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === 'success') {
              toast.dismiss()
              toast.success(res.data.msg, { theme: "colored", })

              localStorage.setItem('userData', JSON.stringify(res.data.user_data));
              
              setTimeout(() => {
                navigate('/dashboard')
              }, 1500);
            } else {
              toast.dismiss()
              toast.error(res.data.msg, { theme: "colored", })
            }
          } else if (res.status === 400) {
            toast.dismiss()
            toast.error("Fail!!", { theme: "colored", })
          }
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
    } else if (data.get('admin_signin_email') === '' && data.get('admin_signin_password') === '') {
      toast.dismiss()
      toast.error("All Fields are Required!", { theme: "colored", })
    } else if (data.get('admin_signin_email') === '') {
      toast.dismiss()
      toast.error("Email'Id is Required!", { theme: "colored", })
    } else if (data.get('admin_signin_password') === '') {
      toast.dismiss()
      toast.error("Password is Required!", { theme: "colored", })
    }
  }
  
  return (
    <div className="login">    
      <ToastContainer />
      <div className="row g-0 h-100 ">
        <div className="col-12 col-lg-6">
          <div className="row align-items-center clientLoginLeft g-0">
            <div className="col p-5">
              <img src={logo} className='loginLogo' alt="Logo"/>
              <div className="row pt-5 pl-0">
                <h3>Ceryle Innovation Technologies IoT Operations Dashboard</h3>
                <h5>Accelerate industrial digitization</h5>
                <p>Enable cloud-delivered operational technology (OT) services on your industrial networks to drive simplicity and scale.</p>
              </div>              
            </div>            
          </div>
        </div>

        {/* Right Side Login Form */}
        <div className="col-12 col-lg-6 ">
          <div className="row align-items-center loginRight g-0 h-100 justify-content-center">
            <div className="col-12 col-lg-8 p-5">
              <p>Good Morning!</p>
              <h4>Welcome Back!</h4>
              <p className='subTitle'>Enter your credentials to get started!</p>
              <form className="form-auth-small" id='login-form' method='POST' onSubmit={handleSubmit}>
                <div className="mb-3">
                  <div className="input-group">
                    <input type="text" className="form-control border-end-0" placeholder="Email Id" aria-label="Email Id" aria-describedby="Email Id" id="admin_signin_email" name='admin_signin_email'/>
                    <span className="input-group-text border-start-0" id="basic-addon2">
                      <img src={userIcon} className='loginLogo' alt="Logo"/>
                    </span>
                  </div>

                  <div className="input-group">
                    <input type={isPasswordShow ? 'text' : 'password'} className="form-control border-end-0" placeholder="Password" aria-label="Password" aria-describedby="Password" name='admin_signin_password' id='admin_signin_password'/>
                    <span className="input-group-text border-start-0" id="basic-addon2" onClick={toggleIsPasswordShowValue}>
                      {isPasswordShow ? <img className='loginLogo' src={EyeIcon} alt="logo" /> : <img className='loginLogo' src={hiddenEye} alt="logo" />}
                    </span>
                  </div>

                  <div className="row text-end">
                    <a href="/forgot-password" className='forgot'>Forgot Password</a>
                  </div>

                </div>
                
                <div className="d-grid gap-2 mb-3">
                  <button className="btn btn-primary" type="submit">Login</button> 
                </div>
              </form>

              <p className='text-center d-none'>Not a Customer? <a href="/" className=''>Register Now</a></p>
            </div>

            <div className="col-12 text-center">
              <img src={colorLogo} alt="Ceryletech Logo" className='mb-2' />
              <p>©<Clock format={'Y'} /> Ceryle Innovative Technologies. All Rights Reserved.</p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  )
}
