import React from "react";
import Menubar from "../menubar";
import TopBar from "../topbar";

const Layout = ({ children }) => {
  
  // window.location.pathname

  return (
    <>
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-lg-block">
            <Menubar pageName="dashboard"/>
          </div>

          <div className="col-12 col-lg-11 ps-0">
            <TopBar />

            {/* Main body */}
            <div className="MainBody p-4">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
