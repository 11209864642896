import React, { useState, useEffect } from "react";
import Menubar2 from "./menubar2";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AddWidgetPopup from "./addWidgetPopup";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TopBar = () => {
  // Add Dashboard
  const [visible, setVisible] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const [boards, setBoards] = useState([])

  const [user_name, setUserName] = useState("");

  const [add_status, setAddStatus] = useState(false);

  useEffect(() => {
    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);


    if (result) {     
      setUserName(result.u_first_name) 
    } else {
      setUserName("")
    }

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    var postData = {
      usr_id: result.usr_id,
      client_id: result.client_id
    };

    axios.post(global.passUrl + 'get_dashbaord_data', postData, axiosConfig).then((res) => {      
      setBoards(res.data.dashboard_data)    
      if (res.data.selected_dashbaord) {        
        const selectedDaat = {"id":res.data.selected_dashbaord[0].id,"dashbaord_name":res.data.selected_dashbaord[0].dashbaord_name};
        setSelectedDashboard(selectedDaat);
      }  
    });
    var AccessData = {
      role_id: result.role_id,
      menu_id: 1,
    };      
    axios.post(global.passUrl + 'get_access_details', AccessData, axiosConfig).then((res) => {
      // eslint-disable-next-line array-callback-return
      res.data.access_data.map((data1, index) => {
        if (data1.privileges_key === "DELETE") {
          if (data1.status) {
            setAddStatus(true)
          } else {
            setAddStatus(false)
          }
        }
      })
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);

  const handleSubmit = (e) => {
    
    e.preventDefault();
    const get_data = new FormData(e.currentTarget);

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };
    var postData = {
      dashboard_name: get_data.get("dashboard_name"),
      usr_id: result.usr_id,
      client_id: result.client_id
    };
    axios.post(global.passUrl + 'addClientDashbaordName', postData, axiosConfig).then((res) => {
      console.log(res);
      if (res.status === 200) {
        if (res.data.status === "success") {
          toast.dismiss();
          toast.success(res.data.message, { theme: "colored" });
          setInterval(() => {
            window.location.href = "";
          }, 2500);
        } else {
          toast.dismiss();
          toast.error(res.data.message, { theme: "colored" });
        }
      } else if (res.status === 400) {
        toast.dismiss();
        toast.error("Faill!!", { theme: "colored" });
      }
    });
  }

  const  updateAuditLog = () => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    var postData1 = {
      usr_id: result.usr_id,
      user_action: "User Logged out",
      sys_remark : "User Logged out",
      device_eui: ""
    };

    axios.post(global.passUrl + 'call_audit_log', postData1, axiosConfig).then((res) => {
      
    });
  }
  

  const setDropDownValue = (e) => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    var postData = {
      dashboard_data: e.value,
      usr_id: result.usr_id,
      client_id: result.client_id
    };
    axios.post(global.passUrl + 'setDashbaord', postData, axiosConfig).then((res) => {
      setSelectedDashboard(e.value)
      window.location.href = "";
    });
    
  }
  const [user_menu, setUserMenu] = useState(null);
  const userMenu = [
    { name: "Logout", code: "LDN" }
  ];
  
  const changeMenuTop = (e) => {
    setUserMenu(e.value);
    if(e.value.code === "LDN"){
      
      updateAuditLog()
  
      localStorage.clear();
      window.location.href = "/";
    }else{
      window.location.href = "/profile";
    }
  }

  return (
    <Navbar expand="lg" className="bg-body-tertiary w-100 pt-0">
      <ToastContainer />
      <div className="topBar">
        <Container className="mw-100">          
          <Row>
            <Col xs={4}>
              <div className="d-lg-none d-sm-block d-block">
                <Menubar2 />
              </div>
            </Col>
            <Col xs={8} className="MobileMenu text-end">
              <Navbar.Toggle aria-controls="navbarScroll" />
            </Col>
          </Row>
        </Container>

        <div className="row">
          <Navbar.Collapse id="navbarScroll">
            <div className="col-lg-4 title flex-wrap justify-content-center gap-3">
              <Dropdown
                className={window.location.pathname === "/dashboard" ? "" : "d-none"}
                value={selectedDashboard}
                onChange={setDropDownValue}
                options={boards}
                optionLabel="dashbaord_name"
                placeholder="Select a Dashboard"
              />   

              {
                add_status ?
                  <Button
                    label="Add Dashboard"
                    className={window.location.pathname === "/dashboard" ? "btn btn-primary addDashboard ms-4" : "btn btn-primary addDashboard ms-4 d-none"}
                    icon="pi pi-plus"
                    onClick={() => setVisible(true)}
                  />
                :
                  ""
              }
              

              <Dialog
                header="Add or Edit Dashboard"
                visible={visible}
                style={{ width: "30vw" }}
                onHide={() => setVisible(false)}
              >
                <Form
                  className="Details"
                  method="POST"
                  onSubmit={handleSubmit}
                >
                  <Row className="mb-4">
                    <Col sm={12}>
                      <div className="flex flex-column gap-2">
                        <label htmlFor="username">Name of Dashboard</label>
                        <InputText
                          name="dashboard_name"
                          placeholder="Add Dashboard"
                        />
                      </div>
                    </Col>
                  </Row>
                  
                  <Row>
                    <div className="flex flex-wrap justify-content-end gap-3">                
                      <Button label="Confirm" className="ms-2" rounded type="submit"/>
                    </div>
                  </Row> 
                </Form>
              </Dialog>
            </div>

            <div className="col-lg-8 text-end ">
              {/* Add Widget Popup */}              
              { window.location.pathname === "/dashboard" ? 
                add_status ?
                  <AddWidgetPopup /> 
                  :
                  ""
                : 
                "" 
              }

              <Dropdown
                value={user_menu}
                onChange={(e) => changeMenuTop(e)}
                options={userMenu}
                className="ms-4"
                optionLabel="name"
                placeholder={user_name}
              />
            </div>
          </Navbar.Collapse>
        </div>
      </div>
    </Navbar>
  );
};

export default TopBar;
