import React, { useRef } from "react";
import Card from "react-bootstrap/Card";
import { ReactComponent as Dots } from "../../../assets/Images/client/dots.svg";
import { ReactComponent as Ellipse } from "../../../assets/Images/client/widgets/Ellipse.svg";
import Image from "react-bootstrap/Image";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";


const Clock = (props) => {
  
  const deleteData = (id) => {
    var postData = {
      dc_id: id
    };

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + 'delete_widgets', postData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 'success') {
            toast.dismiss()
            toast.success(res.data.message, { theme: "colored", })
            setInterval(() => {
              window.location.href = '';
            }, 2500);
          } else {
            toast.dismiss()
            toast.error(res.data.message, { theme: "colored", })
          }
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Fail!!", { theme: "colored", })
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  const op = useRef(null);

  return (
    <>
      <ToastContainer />
      <Card className={`infoBox ${props.image}`}>
        <Card.Title className="mb-0 w-100">
          <span></span>
          {
            `${props.widgetDeleteIcon}` === "1" ? 
              <span onClick={(e) => op.current.toggle(e)} className="cursor-pointer" >
                <Dots />
              </span> 
            : 
              ""
          }
          <OverlayPanel ref={op}>
            <Button
              onClick={() => deleteData(`${props.widgetPID}`)}
              icon="pi pi-trash"
              className="p-button-danger p-button-outlined"
            ></Button>            
          </OverlayPanel>
        </Card.Title>
        <Card.Body>
          <div className="CardImage" variant="top">
            <Image src={props.widgetIcon} style={{ width: "50px", marginLeft: "13%" }} />
            <Ellipse />
          </div>
          <Card.Text>
            <span className="widgetValue">{props.time}</span>
            <span className="widgetTitle">{props.day}</span>
            <span className="widgetID">{props.date}</span>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default Clock;
