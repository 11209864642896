import React, { useState,useEffect } from "react";

import { Divider } from "primereact/divider";
import { ColorPicker } from "primereact/colorpicker";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ColumnChart = () => {

  const [device_details, setDeviceDetails] = useState();
  const [selected_dashboard, setSelectedDashboard] = useState();
  
  const [chart_name, setChartName] = useState("");
  const [x_value, setXaxisValue] = useState("1");

  useEffect(() => {
    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);
    getDeviceData(result.client_id)
  }, [])
  const getDeviceData = (c_id) => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };
    
    var postData = {
      client_id: c_id,
    };

    axios.post(global.passUrl + 'get_client_device_data_active', postData, axiosConfig).then((res) => {
      console.log(res.data.device_data)
      setDeviceDetails(res.data.device_data);
    });

    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);

    var postData1 = {
      client_id: result.client_id
    };

    axios.post(global.passUrl + 'get_dashbaord_data', postData1, axiosConfig).then((res) => {
      setSelectedDashboard(res.data.selected_dashbaord[0].id);
    });
  }

  // Cascading Line Chart
  const [formValues, setFormValues] = useState([{ series_name: "", device_id: "", color_code: "6466f1", parameter_id: "", parameter_name: [],seriesNameCheck:"",  }]);

  let addFormFields = () => {
    setFormValues([...formValues, { series_name: "", device_id: "", color_code: "6466f1", parameter_id: "", parameter_name: [],seriesNameCheck:"",  }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const formValidation = (formVal) => {
    const data = [...formVal]

    let valid = true
    for (let index = 0; index < data.length; index++) {
      if (data[index].device_id === "") {
        data[index].deviceIdCheck = "Please select Device."
        valid = false
      } else {
        data[index].deviceIdCheck = ""
        valid = true
      }

      if (data[index].series_name === "") {
        data[index].seriesNameCheck = "Series name is required"
        valid = false
      } else {
        data[index].seriesNameCheck = ""
        if (valid) {
          valid = true
        }
      }

      if (data[index].parameter_id === "") {
        data[index].parameterIdCheck = "Please select Parameter"
        valid = false
      } else {
        data[index].parameterIdCheck = ""
        if (valid) {
          valid = true
        }
      }
    }

    data.forEach(element => {
      if (element.deviceIdCheck === "Please select Device" || element.parameterIdCheck === "Please select Parameter." || element.seriesNameCheck === "Series name is required") {
        valid = false
      }
    });
    setFormValues(data)
    return valid;
  }

  let handleSubmit = (e) => {
    e.preventDefault();
    const errorRes = formValidation(formValues)
    if (errorRes) {
      var postData = {
        chart_name: chart_name,
        x_axis_name: x_value,
        selected_dashboard:selected_dashboard,
        paramete_data: formValues,
        type: "column"
      };

      if (postData.chart_name === "") {
        toast.dismiss()
        toast.error("Chart name is required!", { theme: "colored", })
      } else if (postData.x_axis_name === "") {
        toast.dismiss()
        toast.error("X axis Value is required!", { theme: "colored", })
      }else {
        let axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
          }
        };
        axios.post(global.passUrl + 'add_line_chart_data', postData, axiosConfig)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.status === 'success') {
                toast.dismiss()
                toast.success(res.data.message, { theme: "colored", })
                setInterval(() => {
                  window.location.href = '';
                }, 2500);
              } else {
                toast.dismiss()
                toast.error(res.data.message, { theme: "colored", })
              }
            } else if (res.status === 400) {
              window.location.href = '';
            }
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
          })
      }
    }
  };

  const handleColorChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  }

  const handleSeriesNameChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;    
    newFormValues[i]['seriesNameCheck'] = "";
    setFormValues(newFormValues);
  }

  const handleParameterChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i]['parameter_id'] = e.target.value;      
    newFormValues[i]['parameterIdCheck'] = "";
    setFormValues(newFormValues);
  }

  const handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;            
    newFormValues[i]['deviceIdCheck'] = "";
    setFormValues(newFormValues);
    
    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };
    var postData = {
      client_id: result.client_id,
      cd_id: e.target.value
    };

    axios.post(global.passUrl + 'getParameetrDataActive', postData, axiosConfig).then((res) => {      
      console.log(res.data.parameter_data)
      let newFormValues = [...formValues];
      newFormValues[i]['parameter_name'] = res.data.parameter_data;
      setFormValues(newFormValues);
    });
  }

  return (
    <>
      <ToastContainer />
      <Container>
        <Row>
          <Col sm={4}>
            <div className="flex flex-column gap-2">
              <label htmlFor="username">Chart Name</label>
              <InputText id="chart_name" name="chart_name" placeholder="Line Chart" onChange={e => setChartName(e.target.value)} />
            </div>
          </Col>
          <Col sm={2}>
            <div className="flex flex-column gap-2">
              <label htmlFor="username">X Axis</label>
              <InputText name="x_axis_name" id="x_axis_name" placeholder="Enter X Axis Value"  onChange={e => setXaxisValue(e.target.value)} value={x_value} disabled/>
            </div>
          </Col>
          <Col sm={2} className="mt-auto mb-auto">
            <div className="flex flex-column gap-2">
              <label className="mt-4">Hours</label>
            </div>
          </Col>
        </Row>

        <Divider className=" d-none"/>

        <Row className="mb-4 d-none">
          <Col sm={3}>
            <div className="flex flex-column gap-2">
              <label htmlFor="username">The Name of Y1</label>
              <InputText id="name" placeholder="Enter Value Here..." />
            </div>
          </Col>
          <Col sm={3}>
            <div className="flex flex-column gap-2">
              <label htmlFor="username">The minimum of Y1</label>
              <InputText id="name" placeholder="Enter Value Here..." />
            </div>
          </Col>
          <Col sm={3}>
            <div className="flex flex-column gap-2">
              <label htmlFor="username">The Name of Y1</label>
              <InputText id="name" placeholder="Enter Value Here..." />
            </div>
          </Col>
          <Col sm={3}>
            <div className="flex flex-column gap-2">
              <label htmlFor="username">The unit of Y1</label>
              <InputText id="name" placeholder="Enter Value Here..." />
            </div>
          </Col>
        </Row>

        <Row className="mb-4 d-none">
          <Col sm={3}>
            <div className="flex flex-column gap-2">
              <label htmlFor="username">The Name of Y2</label>
              <InputText id="name" placeholder="Enter Value Here..." disabled />
            </div>
          </Col>
          <Col sm={3}>
            <div className="flex flex-column gap-2">
              <label htmlFor="username">The minimum of Y2</label>
              <InputText id="name" placeholder="Enter Value Here..." disabled />
            </div>
          </Col>
          <Col sm={3}>
            <div className="flex flex-column gap-2">
              <label htmlFor="username">The Name of Y2</label>
              <InputText id="name" placeholder="Enter Value Here..." disabled />
            </div>
          </Col>
          <Col sm={3}>
            <div className="flex flex-column gap-2">
              <label htmlFor="username">The unit of Y2</label>
              <InputText id="name" placeholder="Enter Value Here..." disabled />
            </div>
          </Col>
        </Row>

        <Divider align="left">
          <b>Line Chart</b>
        </Divider>

        <Form.Group
          className="mb-4 mandatory"
          controlId="ParameterDetails"
        >
          {formValues.map((element, index) => (
            <div className="form-inline" key={index}>
              <Row>
                <Col sm={10}>
                  <Row className="mb-4 align-items-center">                  
                    <Col sm={3}>
                      <Form.Control type="text" placeholder="Enter Series Name" name="series_name" value={element.series_name || ""} onChange={e => handleSeriesNameChange(index, e)} />
                      <Form.Text className="text-danger">
                        {element.seriesNameCheck}
                      </Form.Text>
                    </Col>
                    <Col sm={3}>                    
                      <Form.Select
                        aria-label="Default select example"
                        id="device_id"
                        name="device_id"
                        value={element.device_id || ""} onChange={e => handleChange(index, e)}
                      >
                        <option value="" >Select Device</option>
                        {device_details?.map((data, index) => (
                          <option value={data.cd_id} key={index}>
                            {data.device_name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Text className="text-danger">
                        {element.deviceIdCheck}
                      </Form.Text>
                      
                    </Col>
                    <Col sm={3}>                    
                      <Form.Select
                        aria-label="Default select example"
                        id="parameter_id"
                        name="parameter_id"
                        value={element.parameter_id || ""} onChange={e => handleParameterChange(index, e)}
                      >
                        <option value="" >Select Device</option>
                        {element.parameter_name?.map((data, index) => (
                          <option value={data.parameter_name} key={index}>
                            {data.display_name}
                          </option>
                        ))}
                      </Form.Select>

                      <Form.Text className="text-danger">
                        {element.parameterIdCheck}
                      </Form.Text>
                    </Col>
                    <Col sm={2} className="d-flex">                    
                      <Form.Control type="text" placeholder="Enter Series Name" name="color_code" value={element.color_code || ""} />
                      <ColorPicker
                        style={{ marginLeft: '25px'}}
                        className="mt-2"
                        name="color_code"
                        value={element.color_code}
                        onChange={e => handleColorChange(index, e)}
                      />
                    </Col>
                    <Col sm={1} className="text-end">
                      {index ? (
                        <Button
                          icon="pi pi-minus"
                          rounded
                          outlined
                          aria-label="Filter"
                          size="small"
                          severity="danger"
                          onClick={() => removeFormFields(index)}
                        />
                      ) : (
                        <Button
                          icon="pi pi-plus"
                          rounded
                          outlined
                          aria-label="Filter"
                          size="small"
                          className="ms-3"
                          severity="success"
                          onClick={() => addFormFields(index)}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          ))}
        </Form.Group>
      </Container>

      <Row className="text-end tab-footer" fixed="bottom">
        <div className="flex flex-wrap justify-content-end gap-3">
          <Button label="Confirm" className="ms-2" rounded onClick={handleSubmit}/>
        </div>
      </Row>
    </>
  );
};

export default ColumnChart;
