import React, { useState,useEffect } from "react";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import { Password } from "primereact/password";
import { ToastContainer, toast } from "react-toastify";
// import { log } from "handlebars";
import { useParams } from 'react-router-dom'
import Container from "react-bootstrap/Container";
import { Divider } from "@mui/material";

const Calibration = () => {
    const paramsData = useParams();
    const [value, setValue] = useState("");
    const [devices, setDevices] = useState();
    const [calibration_test, setCalibrationTest] = useState(false);

    useEffect(() => {
        getSettingData()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getSettingData = () => {
        var data = localStorage.getItem('userData');
        var result = JSON.parse(data);

        var postData = {
            cd_id: paramsData.d_id,
            user_id: result.usr_id
        };

        let axiosConfig = {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            }
        };

        axios.post(global.passUrl + "get_interface_settings_details", postData, axiosConfig).then((res) => {
            if (res.status === 200) {
                setDevices(res.data.parameter_details)
            } else if (res.status === 400) {
                toast.dismiss()
                toast.error("Faill!!", { theme: "colored", })
            }
        }).catch((err) => { console.log("AXIOS ERROR: ", err); });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);

        var postData = {
            email: data.get('admin_signin_email'),
            password: data.get('admin_signin_password'),
        };

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        };

        if (postData.email && postData.password) {
            axios.post(global.passUrl + 'calibration_login', postData, axiosConfig)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.status === 'success') {
                            setCalibrationTest(true)
                            toast.dismiss()
                            toast.success(res.data.msg, { theme: "colored", })
                        } else {
                            setCalibrationTest(false)
                            toast.dismiss()
                            toast.error(res.data.msg, { theme: "colored", })
                        }
                    } else if (res.status === 400) {
                        toast.dismiss()
                        toast.error("Fail!!", { theme: "colored", })
                    }
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        } else if (data.get('admin_signin_email') === '' && data.get('admin_signin_password') === '') {
            toast.dismiss()
            toast.error("All Fields are Required!", { theme: "colored", })
        } else if (data.get('admin_signin_email') === '') {
            toast.dismiss()
            toast.error("Email'Id is Required!", { theme: "colored", })
        } else if (data.get('admin_signin_password') === '') {
            toast.dismiss()
            toast.error("Password is Required!", { theme: "colored", })
        }
    }

    const onHandle = (e, i) => {
        let newForm = [...devices];
        newForm[i][e.target.name] = e.target.value;
        setDevices(newForm);
    }
    
    const SubmitData = () => {       
        var postData = {
            devices_date: devices
        };

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        };

        axios.post(global.passUrl + 'submit_calibration_data', postData, axiosConfig).then((res) => {
            if (res.status === 200) {
                if (res.data.status === 'success') {
                    getSettingData()
                    toast.dismiss()
                    toast.success(res.data.msg, { theme: "colored", })
                } else {
                    toast.dismiss()
                    toast.error(res.data.msg, { theme: "colored", })
                }
            } else if (res.status === 400) {
                toast.dismiss()
                toast.error("Fail!!", { theme: "colored", })
            }
        })
        .catch((err) => {
            console.log("AXIOS ERROR: ", err);
        })
    }

    return (
        <>
            <ToastContainer />
            <Container>
                {calibration_test ? 
                    <Row>
                        <Col sm={6} className="m-auto">
                            {
                                devices?.map((c, d) => (
                                    <div className="flex flex-row gap-2 align-items-center mb-4" style={{ marginLeft: "2%"}}>
                                        <label
                                            htmlFor="Dielectric Const"
                                            className="font-bold w-50"
                                        >
                                            {c.param_name.charAt(0).toUpperCase() + c.param_name.slice(1)}
                                        </label>
                                        <InputText type="text" id="calibration_value" name="calibration_value" className="w-100" value={c.calibration_value || ""} onChange={e => onHandle(e, d)} />
                                    </div>                                        
                                ))
                            }
                        </Col>
                        <Col sm={12} className="text-center">
                            <Divider />
                            <Row className="mt-4">
                                <Col>
                                    <Button
                                        label="Cancle"
                                        icon="pi pi-times"
                                        severity="danger"
                                        rounded
                                    />
                                    <Button
                                        type="submit"
                                        label="Save"
                                        icon="pi pi-check"
                                        className="ms-4"
                                        autoFocus
                                        rounded
                                        onClick={() => SubmitData()}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    : 
                    <Row>
                        <form className="form-auth-small" id='login-form' method='POST' onSubmit={handleSubmit}>
                            <Col sm={6}>
                                <div className="flex flex-row gap-2 align-items-center mb-4">
                                    <label
                                        htmlFor="nameOfSensor"
                                        className="font-bold w-50"
                                    >
                                        Email
                                    </label>
                                    <InputText type="email" id="admin_signin_email" name='admin_signin_email' className="w-100" />
                                </div>

                                <div className="flex flex-row gap-2 align-items-center mb-4">
                                    <label htmlFor="key" className="font-bold w-50">
                                        Password
                                    </label>
                                    <div className="w-100">
                                        <Password
                                            name='admin_signin_password' 
                                            id='admin_signin_password'
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                            feedback={false}
                                            toggleMask
                                        />
                                    </div>
                                </div>
                                <Divider />
                                <Row className="mt-4">
                                    <Col>
                                        <Button
                                            label="Cancle"
                                            icon="pi pi-times"
                                            severity="danger"
                                            rounded
                                        />
                                        <Button
                                            type="submit"
                                            label="Save"
                                            icon="pi pi-check"
                                            className="ms-4"
                                            autoFocus
                                            rounded
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </form>
                    </Row>
                }
                
            </Container>
        </>
    );
};
export default Calibration;
