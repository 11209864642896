import React, { useState, useEffect } from "react";
import Menubar from "../menubar";
import TopBar from "../topbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/css/custom.css";
import { error } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/Material.css";
import "@pnotify/core/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";
import "datatables.net-dt/css/jquery.dataTables.css"
import { useNavigate } from 'react-router-dom';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import shield_img from '../../../assets/Images/user.png';

const UserManagement = () => {
  const [problem_data_new, setProblemDataNew] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState(null);
  const [edit_save_state, setSaveState] = useState(false);
  const [edit_state, setEditState] = useState(false);
  // const [data, setDate] = useState([]);
  const [client_details, setClientDetails] = useState([]);
  const [role_data, setRole] = useState([]);
  const [contact_number, setContactNumber] = useState();
  const [check_password, setCheckPassword] = useState();
  const [user_check, setClientAddCheck] = useState(true);
  const [question_details, setQuestionDetails] = useState([]);

  const [edit_usr_check, setUserEditCheck] = useState();
  const [edit_usr_first_name, setUserFirstName] = useState();
  const [edit_usr_last_name, setUserLastName] = useState();
  const [edit_emaid_id, setUseremail] = useState();
  const [edit_mobile_no, setUserMobileNo] = useState();
  const [edit_role_id, setUserRole] = useState();
  const [edit_user_id, setUserId] = useState();
  const [edit_security_answer, setSecurityAnswer] = useState();
  const [edit_security_id, setSecurityQuestionId] = useState();

  
  const [add_status, setAddStatus] = useState(0)
  const [edit_status, setEditStatus] = useState(0)
  const [delete_status, setDeleteStatus] = useState(0)

  const handleChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setContactNumber(value);
  };

  const handleChangeEditNumber = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setUserMobileNo(value);
  };

  const navigate = useNavigate()
  var data1 = localStorage.getItem('userData');
  var result1 = JSON.parse(data1);

  useEffect(() => {
    if (result1) {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      var postData = {
        client_id: result1.client_id
      };  
      //Get all users details in bootstrap table
      let new_array = [];
      axios.post(global.passUrl + "get_client_user_data", postData, axiosConfig).then((res) => {
        // setDate(res.data);
        console.log(res.data)
        res.data.forEach(element => {
          new_array.push({
            usr_id: element.usr_id,
            usr_name: element.u_first_name+" "+element.u_last_name,
            usr_email: element.usr_email,
            usr_mobile_no: element.usr_mobile_no,
            role_name: element.role_name,
            client_name: element.client_name,
            is_status: element.is_status
          });
        });

        setProblemDataNew(new_array);
        setDeviceDetailsFilter(new_array);
      });

      axios
        .post(global.passUrl + "get_client_active_data", axiosConfig)
        .then((res) => {
          setClientDetails(res.data);
        });
        
      var postRoleData = {
        client_id: result1.client_id
      };  
      axios.post(global.passUrl + "get_client_role", postRoleData, axiosConfig).then((res) => {
        setRole(res.data);
      });

      
      axios.post(global.passUrl + 'get_question_details', axiosConfig).then((res) => {
        setQuestionDetails([]);
        setQuestionDetails(res.data.question_details);
      });

      var AccessData = {
        role_id: result1.role_id,
        menu_id: 9,
      };      
      axios.post(global.passUrl + 'get_access_details', AccessData, axiosConfig).then((res) => {
        if (res.data.access_data.length) {
          // eslint-disable-next-line array-callback-return
          res.data.access_data.map((data1) => {
            if (data1.privileges_key === "ADD") {
              if (data1.status) {
                setAddStatus(1)
              } else {
                setAddStatus(0)
              }
            }else if (data1.privileges_key === "EDIT") {
              if (data1.status) {
                setEditStatus(1)
              } else {
                setEditStatus(0)
              }
            }else if (data1.privileges_key === "DELETE") {
              if (data1.status) {
                setDeleteStatus(1)
              } else {
                setDeleteStatus(0)
              }
            }
          })
        }
      });
    } else {
      navigate("/admin-login")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editClientDetails = (id) => {
    setEditState(true);
    var postData = {
      usr_id: id,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post(global.passUrl + "edit_user", postData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          setSecurityAnswer("")
          setUserFirstName(res.data[0].u_first_name);
          setUserLastName(res.data[0].u_last_name);
          setUseremail(res.data[0].usr_email);
          setUserMobileNo(res.data[0].usr_mobile_no);
          setUserRole(res.data[0].role_id);
          setSecurityAnswer(res.data[0].security_answer === null ? "" : res.data[0].security_answer)
          setSecurityQuestionId(res.data[0].security_question_id)
          setUserId(id);
          if (res.data[0].is_status === "1") {
            setUserEditCheck(true);
          } else {
            setUserEditCheck(false);
          }
        } else if (res.status === 400) {
          toast.dismiss();
          toast.error("Faill...", { theme: "colored" });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const editFormCanceld = () => {
    setEditState(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    var postData = {
      u_first_name: data.get("usr_first_name"),
      u_last_name: data.get("usr_last_name"),
      u_email: data.get("usr_email"),
      u_mobile_no: data.get("usr_mobile_no"),
      u_password: data.get("usr_password"),
      question_id: data.get("question_id"),  
      role_id: data.get("role_id"),  
      security_answer: data.get("security_answer"),
      c_id: result1.client_id,
      user_status: user_check,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    
    if (
      postData.u_first_name === "" &&
      postData.u_last_name === "" &&
      postData.u_email === "" &&
      postData.u_mobile_no === "" &&
      postData.u_password === "" &&
      postData.c_id === "" &&
      postData.role_id === "" &&
      postData.question_id === "" &&
      postData.security_answer === ""
    ) {
      toast.dismiss();
      toast.error("All fields are required!", { theme: "colored" });
    } else if (postData.u_first_name === "") {
      toast.dismiss();
      toast.error("User First Name is required!", { theme: "colored" });
    } else if (postData.u_last_name === "") {
      toast.dismiss();
      toast.error("User Last Name is required!", { theme: "colored" });
    } else if (postData.u_email === "") {
      toast.dismiss();
      toast.error("User Email is required!", { theme: "colored" });
    } else if (postData.u_mobile_no === "") {
      toast.dismiss();
      toast.error("Mobile No is required!", { theme: "colored" });
    } else if (postData.u_password === "") {
      toast.dismiss();
      toast.error("Password is required!", { theme: "colored" });
    }else if (postData.c_id === "") {
      toast.dismiss();
      toast.error("Please select client role.", { theme: "colored" });
    } else if (postData.question_id === "") {
      toast.dismiss();
      toast.error("Please select Security Question.", { theme: "colored" });
    } else if (postData.security_answer === "") {
      toast.dismiss();
      toast.error("Please select Security Answer.", { theme: "colored" });
    } else if (postData.role_id === "") {
      toast.dismiss();
      toast.error("Please Select Role", { theme: "colored" });
    } else if(check_password){
      // toast.dismiss();
      // toast.error("Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters", { theme: "colored" });
    }else{
      axios
        .post(global.passUrl + "add_client_users", postData, axiosConfig)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === "success") {
              toast.dismiss();
              toast.success(res.data.message, { theme: "colored" });
              setInterval(() => {
                window.location.href = "";
              }, 2500);
            } else {
              toast.dismiss();
              toast.error(res.data.message, { theme: "colored" });
            }
          } else if (res.status === 400) {
            toast.dismiss();
            toast.error("Faill!!", { theme: "colored" });
          }
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        });
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    var postData = {
      u_first_name: data.get("edit_usr_first_name"),
      u_last_name: data.get("edit_usr_last_name"),
      u_email: data.get("edit_usr_mail"),
      u_mobile_no: data.get("edit_usr_mobile_no"),
      u_role: data.get("edit_role_id"),
      user_status: edit_usr_check,
      usr_id: edit_user_id,
      question_id: edit_security_id,  
      security_answer: edit_security_answer,
    };
    
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };

    if (
      postData.u_first_name === "" &&
      postData.u_last_name === "" &&
      postData.u_email === "" &&
      postData.u_mobile_no === "" &&
      postData.u_role === "" &&
      postData.question_id === "" &&
      postData.security_answer === ""
    ) {
      toast.dismiss();
      toast.error("All fields are required!", { theme: "colored" });
    } else if (postData.u_last_name === "") {
      toast.dismiss();
      toast.error("User Last Name is required!", { theme: "colored" });
    } else if (postData.u_first_name === "") {
      toast.dismiss();
      toast.error("User First Name is required!", { theme: "colored" });
    } else if (postData.u_email === "") {
      toast.dismiss();
      toast.error("User Email is required!", { theme: "colored" });
    } else if (postData.u_mobile_no === "" || postData.u_mobile_no === "null") {
      toast.dismiss();
      toast.error("Mobile No is required!", { theme: "colored" });
    } else if (postData.u_role === "") {
      toast.dismiss();
      toast.error("Please select user role.", { theme: "colored" });
    } else if (postData.question_id === "") {
      toast.dismiss();
      toast.error("Please select security question.", { theme: "colored" });
    } else if (postData.security_answer === "") {
      toast.dismiss();
      toast.error("Please select security answer.", { theme: "colored" });
    } else {
      axios
        .post(global.passUrl + "update_users", postData, axiosConfig)
        .then((res) => {
          if (res.status === 200) {
            toast.dismiss()
            toast.success("User Updated Successfully...", { theme: "colored", })
            setInterval(() => {
              window.location.href = '';
            }, 2500);
          } else if (res.status === 400) {
            toast.dismiss();
            toast.error("Faill!!", { theme: "colored" });
            setInterval(() => {
              window.location.href = "";
            }, 2500);
          }
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        });
    }
  };

  const handleChangeSwitch = (e) => {
    const checked = e.target.checked;
    const lightLabel = checked ? true : false;
    setClientAddCheck(lightLabel);
  };
  const handleEditChangeSwitch = (e) => {
    const checked = e.target.checked;
    const lightLabel = checked ? true : false;
    setUserEditCheck(lightLabel);
  };

  const deleteClientDetails = (id) => {
    error({
      hide: false,
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this User!",
      modules: new Map([
        [
          Confirm,
          {
            confirm: true,
            buttons: [
              {
                text: "submit",
                primary: false,
                click: notice => {
                  notice.close();
                  deleteRecord(id)
                }
              },
              {
                text: "cancel",
                click: notice => {
                  notice.close();
                }
              }
            ]
          }
        ]
      ])
    });
  }

  const deleteRecord = (id) => {
    var postData = {
      usr_id: id
    };

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + 'delete_user', postData, axiosConfig)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.dismiss()
          toast.success(res.data.message, { theme: "colored", })
          setInterval(() => {
            window.location.href = '';
          }, 2500);
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Fail!!", { theme: "colored", })
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  const getNoUserCount = (id) => {

    if (id === "") {
      setSaveState(false)
    } else {
      var postData = {
        client_id: id,
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      axios
        .post(global.passUrl + "getNoUserCount", postData, axiosConfig)
        .then((res) => {
          if (res.status === 200) {
            if (parseInt(res.data.total_users) === parseInt(res.data.client_data)) {
              setSaveState(true)
            } else {
              setSaveState(false)
            }
          } else if (res.status === 400) {
            toast.dismiss();
            toast.error("Faill...", { theme: "colored" });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  }

  const rowActiveBodyTemplate = (rowData) => {
    return (
      rowData.is_status === "1" ? <Badge bg="success">Active</Badge> : <Badge bg="danger">In-Active</Badge>
    );
  }

  const setPermission = (id) => {
    window.location.href = "/set-permission/" + id;
  }

  const rowActionsBodyTemplate = (element) => {
    return (
      <div className="flex flex-row gap-3">
        { 
          edit_status === 1 ?
            <div
              onClick={() =>
                editClientDetails(element.usr_id)
              }
              className="cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.2847 2.12264L17.7759 3.32139C17.9199 3.45821 18.0011 3.64932 18 3.84896C17.9989 4.0486 17.9157 4.2388 17.7702 4.37402L16.6371 5.74292L12.7815 10.3851C12.714 10.463 12.6242 10.5178 12.5244 10.5418L10.5247 10.9994C10.2623 11.0126 10.0339 10.8201 10 10.5572V8.48266C10.0068 8.38259 10.0467 8.2878 10.1133 8.21349L13.8975 3.89433L15.1963 2.32836C15.4532 1.98833 15.9233 1.8995 16.2847 2.12264Z"
                  stroke="#55A1B2"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 4.53846C10.9265 4.53846 11.2723 4.19407 11.2723 3.76923C11.2723 3.3444 10.9265 3 10.5 3V4.53846ZM7.61658 3.76923V3H7.61473L7.61658 3.76923ZM3.77236 7.61542H4.5447V7.61368L3.77236 7.61542ZM3.77236 13.3846L4.5447 13.3863V13.3846H3.77236ZM7.61658 17.2308L7.61473 18H7.61658V17.2308ZM13.3834 17.2308V18H13.3853L13.3834 17.2308ZM17.2276 13.3846H16.4553V13.3863L17.2276 13.3846ZM18 9.53849C18 9.11357 17.6542 8.76926 17.2276 8.76926C16.8011 8.76926 16.4553 9.11357 16.4553 9.53849H18ZM14.4681 3.58032C14.3053 3.18763 13.8537 3.00071 13.4594 3.16282C13.0651 3.32493 12.8775 3.77468 13.0403 4.16737L14.4681 3.58032ZM16.8836 6.59314C17.3 6.50144 17.563 6.09083 17.471 5.67601C17.3789 5.26119 16.9667 4.99924 16.5501 5.09095L16.8836 6.59314ZM6.65578 13.5774C6.22923 13.5774 5.88344 13.9218 5.88344 14.3467C5.88344 14.7715 6.22923 15.1159 6.65578 15.1159V13.5774ZM14.3452 15.1159C14.7718 15.1159 15.1176 14.7715 15.1176 14.3467C15.1176 13.9218 14.7718 13.5774 14.3452 13.5774V15.1159ZM10.5 3H7.61658V4.53846H10.5V3ZM7.61473 3C5.06042 3.00611 2.99454 5.07303 3.00001 7.61706L4.5447 7.61368C4.54106 5.91924 5.91709 4.54253 7.61843 4.53846L7.61473 3ZM3.00001 7.61542V13.3846H4.5447V7.61542H3.00001ZM3.00001 13.3829C2.99454 15.9269 5.06042 17.9938 7.61473 18L7.61843 16.4615C5.91709 16.4574 4.54106 15.0807 4.5447 13.3863L3.00001 13.3829ZM7.61658 18H13.3834V16.4615H7.61658V18ZM13.3853 18C15.9396 17.9938 18.0054 15.9269 18 13.3829L16.4553 13.3863C16.4589 15.0807 15.0829 16.4574 13.3816 16.4615L13.3853 18ZM18 13.3846V9.53849H16.4553V13.3846H18ZM13.0403 4.16737C13.241 4.6516 13.6504 5.33794 14.2625 5.86826C14.8884 6.41073 15.7879 6.83438 16.8836 6.59314L16.5501 5.09095C16.0713 5.19637 15.6552 5.03593 15.2764 4.70764C14.8835 4.36717 14.599 3.89608 14.4681 3.58032L13.0403 4.16737ZM6.65578 15.1159H14.3452V13.5774H6.65578V15.1159Z"
                  fill="#55A1B2"
                />
              </svg>
            </div>
          :
              ""
        }
        
        { 
          delete_status === 1 ?
            <div
              onClick={() =>
                deleteClientDetails(element.usr_id)
              }
              className="cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M8.3335 10V14.1667"
                  stroke="#FF0000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.6665 10V14.1667"
                  stroke="#FF0000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.3335 5.83334H16.6668"
                  stroke="#FF0000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5 8.33334V15C5 16.3808 6.11929 17.5 7.5 17.5H12.5C13.8807 17.5 15 16.3808 15 15V8.33334"
                  stroke="#FF0000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 4.16667C7.5 3.24619 8.24619 2.5 9.16667 2.5H10.8333C11.7538 2.5 12.5 3.24619 12.5 4.16667V5.83333H7.5V4.16667Z"
                  stroke="#FF0000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          :
            ""
        }

        <div
          onClick={() =>
            setPermission(element.usr_id)
          }
          className="cursor-pointer d-none"
        >
          <img src={shield_img} alt="Logo" width={20} height={20} />
        </div>
           
      </div>
    );
  };

  const [device_details_filter, setDeviceDetailsFilter] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
    const filtered_list = device_details_filter.filter((item) => {
      return item.usr_name.toLowerCase().includes(value.toLowerCase());
    });
    setProblemDataNew(filtered_list);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search by Full Name"
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();
  
  const handlePasswordStrong = (pass) => {
    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/;
     
    if(!regex.test(pass)){
      setCheckPassword(true)
    }else{
      setCheckPassword(false)
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-sm-block">
            <Menubar pageName="users" />
          </div>

          <div className="col-12 col-lg-11 ps-0">
            <TopBar />

            <div className="mainContent">
              <div className="row">
                <div className="col-lg-8">
                  <div className="leftPanel">
                    <div className="row tableTitle">Users</div>
                      <DataTable
                        header={header}
                        value={problem_data_new}
                        selection={selectedDevices}
                        onSelectionChange={(e) => setSelectedDevices(e.value)}
                        tableStyle={{ minWidth: "50rem" }} 
                        stripedRows 
                        showGridlines 
                        paginator 
                        rows={10} 
                        rowsPerPageOptions={[5, 10, 25, 50]} 
                      >
                        <Column
                          field="usr_name"
                          header="Full Name"
                          sortable
                        ></Column>
                        <Column
                          field="usr_email"
                          header="User Email"
                          sortable
                        ></Column>
                        <Column
                          field="usr_mobile_no"
                          header="User Mobile"
                          sortable
                        ></Column>
                        <Column
                          field="role_name"
                          header="User Role"
                          sortable
                        ></Column>
                        <Column
                          field="client_name"
                          header="Client Name"
                          sortable
                        ></Column>
                        <Column
                          field=""
                          header="Active"
                          body={rowActiveBodyTemplate}
                        ></Column>
                        <Column
                          field=""
                          header="Operations"
                          body={rowActionsBodyTemplate}
                        ></Column>
                      </DataTable>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="rightPanel">
                    <Form
                      className="Details"
                      style={{ display: edit_state ? "none" : "block" }}
                      method="POST"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="row tableTitle">Add User Details</div>
                      </div>
                      <Form.Group className="mb-4 mandatory">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter First Name"
                          id="usr_first_name"
                          name="usr_first_name"
                        />
                      </Form.Group>

                      <Form.Group className="mb-4 mandatory">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Last Name"
                          id="usr_last_name"
                          name="usr_last_name"
                        />
                      </Form.Group>

                      <Form.Group className="mb-4 mandatory">
                        <Form.Label>User Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter User Email"
                          id="usr_email"
                          name="usr_email"
                        />
                      </Form.Group>

                      <Form.Group className="mb-4 mandatory">
                        <Form.Label>User Mobile Number </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter User Mobile Number"
                          id="usr_mobile_no"
                          name="usr_mobile_no"
                          maxLength={10}
                          value={contact_number}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <Form.Group className="mb-4 mandatory">
                        <Form.Label>User Password </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter User Password"
                          id="usr_password"
                          name="usr_password"
                          onChange={(e) => handlePasswordStrong(e.target.value)}
                        />
                        <small style={{color: 'red'}}>{check_password ? "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" : ""}</small>
                      </Form.Group>

                      <Form.Group className="mb-4 mandatory">
                        <Form.Label>Security Question </Form.Label>
                        <Form.Select
                          aria-label="Default Security Question"
                          id="question_id"
                          name="question_id"
                        >
                          <option value="">Security Question</option>
                          {question_details.map((data, index) => (
                            <option value={data.id} key={index}>
                              {data.question}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                     
                      <Form.Group className="mb-4 mandatory">
                        <Form.Label>Security Answer</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Security Answer"
                          id="security_answer"
                          name="security_answer"
                        />
                      </Form.Group>


                      <Form.Group className="mb-4 mandatory">
                        <Form.Label >User Role </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          id="role_id"
                          name="role_id"
                        >
                          <option value="">Select Role</option>
                          {role_data.map((data, index) => (
                            <option value={data.role_id} key={index}>
                              {data.role_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="mb-4 mandatory">
                        <Form.Label className="d-none">Client Name </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          id="client_id"
                          name="client_id"
                          className="d-none"
                          onChange={(e) => getNoUserCount(e.target.value)}
                        >
                          <option value="">Select Client</option>
                          {client_details.map((data, index) => (
                            <option value={data.client_id} key={index}>
                              {data.client_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="mb-4 mandatory">
                        <Form.Label>Active </Form.Label>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                          checked={user_check}
                          onChange={handleChangeSwitch}
                        />
                      </Form.Group>

                      <div className="btnFooter">
                        <Button
                          variant="danger"
                          className="btn btn-danger"
                          type="button"
                          style={{ display: "none" }}
                        >
                          Cancel
                        </Button>
                        
                        
                        {edit_save_state ? 
                          <Form.Text className="text-danger" style={{ fontSize: '15px' }}> The user creation limit has been reached. </Form.Text> 
                          : 
                          add_status === 1 ?
                            <Button variant="primary" className="btn btn-primary" type="submit" > Save </Button>
                          :
                          ""
                        }
                       

                      </div>
                    </Form>

                    <Form
                      className="Details"
                      style={{ display: edit_state ? "block" : "none" }}
                      method="POST"
                      onSubmit={handleUpdate}
                    >
                      <div className="row">
                        <div className="row tableTitle">Edit User Details</div>
                      </div>
                      <Form.Group className="mb-4 mandatory">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter First Name"
                          id="edit_usr_first_name"
                          name="edit_usr_first_name"
                          value={edit_usr_first_name}
                          onChange={(e) => setUserFirstName(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4 mandatory">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Last Name"
                          id="edit_usr_last_name"
                          name="edit_usr_last_name"
                          value={edit_usr_last_name}
                          onChange={(e) => setUserLastName(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-4 mandatory">
                        <Form.Label>User Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter User Email"
                          id="edit_usr_mail"
                          name="edit_usr_mail"
                          value={edit_emaid_id}
                          onChange={(e) => setUseremail(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-4 mandatory">
                        <Form.Label>User Mobile Number </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter User Mobile Number "
                          id="edit_usr_mobile_no"
                          name="edit_usr_mobile_no"
                          maxLength={10}
                          value={edit_mobile_no}
                          onChange={handleChangeEditNumber}
                        />
                      </Form.Group>


                      <Form.Group className="mb-4 mandatory">
                        <Form.Label>Security Question </Form.Label>
                        <Form.Select
                          aria-label="Default Security Question"
                          id="edit_question_id"
                          name="edit_question_id"
                          onChange={(e) => setSecurityQuestionId(e.target.value)}
                        >
                          <option>Security Question</option>
                          {question_details.map((data, index) => (
                            <option
                              value={data.id}
                              key={index}
                              selected={
                                edit_security_id === data.id
                                  ? "selected"
                                  : false
                              }
                            >
                              {data.question}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="mb-4 mandatory">
                        <Form.Label>Security Answer </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Security Answer"
                          id="edit_security_answer"
                          name="edit_security_answer"
                          value={edit_security_answer}
                          onChange={(e) => setSecurityAnswer(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-4 mandatory">
                        <Form.Label  >User Role </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          id="edit_role_id"
                          name="edit_role_id"
                          onChange={(e) => setUserRole(e.target.value)}
                        >
                          <option>Select Role</option>
                          {role_data.map((data, index) => (
                            <option
                              value={data.role_id}
                              key={index}
                              selected={
                                edit_role_id === data.role_id
                                  ? "selected"
                                  : false
                              }
                            >
                              {data.role_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="mb-4 mandatory">
                        <Form.Label>Active </Form.Label>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="edit_custom_switch"
                          checked={edit_usr_check}
                          onChange={handleEditChangeSwitch}
                        />
                      </Form.Group>

                      <div className="btnFooter">
                        <Button
                          variant="danger"
                          className="btn btn-danger"
                          type="button"
                          onClick={editFormCanceld}
                        >
                          Cancel
                        </Button>

                        <Button
                          variant="primary"
                          className="btn btn-primary"
                          type="submit"
                        >
                          Update
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagement;
