import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DeviceService } from "../services/DeviceList";

const TriggerLog = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState(null);

  useEffect(() => {
    DeviceService.getDevicesMini().then((data) => setDevices(data));
  }, []);

  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];
  
  const ReportNameTemplate = (device) => {
    return (
        <div className="text-center">
        <h5 className="text-primary">{device.name}</h5>
        <p>{device.subName}</p>
        </div>
    );
  };

  const readingTemplate = (device) => {
    return (
      <p>{getSeverity(device)}</p>
    );
  };

  const getSeverity = (device) => {
    switch (device.interfaceStatus.active) {
      case "Yes":
        return "The Device is Online";

      case "No":
        return "The Device is Offline";

      default:
        return null;
    }
  };

  return (
    <>
      <Row className="mb-4">
        <Col sm={8} className="gap-3 flex">
          <Dropdown
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.value)}
            options={cities}
            optionLabel="name"
            placeholder="Custom"
            className="w-full"
          />
          <Calendar
            value={startDate}
            onChange={(e) => setStartDate(e.value)}
            placeholder="Start Date"
            showIcon
          />
          <Calendar
            value={endDate}
            onChange={(e) => setEndDate(e.value)}
            placeholder="End Date"
            showIcon
          />

          <Dropdown
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.value)}
            options={cities}
            optionLabel="name"
            placeholder="Device Name"
            className="w-full"
          />
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText placeholder="Search" style={{ height: "43px" }} />
          </span>

          <Button label="Search" className="btn btn-primary" rounded />
        </Col>

        <Col sm={4} className="gap-3 flex justify-content-end">
        <Button label="Download" className="btn btn-primary" outlined rounded />
        </Col>
      </Row>

      <Row>
        <DataTable
          value={devices}
          selection={selectedDevices}
          onSelectionChange={(e) => setSelectedDevices(e.value)}
          dataKey="id"
          paginator
          rows={5}
          sortMode="multiple"
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            selectionMode="multiple" className="justify-content-center"
            headerStyle={{ width: "2rem" }}
          ></Column>
          <Column field="updateTime" header="Time"  className="justify-content-center text-center"
             sortable></Column>
          <Column
            field="interfaceStatus.title"
            sortable
            body={ReportNameTemplate}
            className="justify-content-center"
            header="Name"
          ></Column>
          
          <Column field="interfaceStatus.active" body={readingTemplate} header="Readings" sortable></Column>
        </DataTable>
      </Row>
    </>
  );
};

export default TriggerLog;
