import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Button } from "primereact/button";
// import Navbar from "react-bootstrap/Navbar";
import { InputText } from "primereact/inputtext";
import { BreadCrumb } from "primereact/breadcrumb";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from 'react-router-dom';
import Menubar from "./menubar";
// import Menubar2 from "./menubar2";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Topbar from './topbar';
import Loader from '../../assets/Images/default.gif';
import moment from 'moment'

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import Excel from '../../assets/Images/xls.png';
import Pdf from '../../assets/Images/pdf.png';
var fileDownload = require('js-file-download');

const Reports = () => {

  const navigate = useNavigate()
  const [selectedDevicesList, setSelectedDeviceList] = useState(null);

  const [pdf_status, setPDFStatus] = useState(0);
  const [excel_status, setExcelStatus] = useState(0);

  //   BreadCrumb
  const items = [
    { label: "Reports", url: "/Reports" },
    { label: "Report List" },
  ];
  const home = { icon: "pi pi-home", url: "/dashboard" };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [devices, setDevices] = useState([]);
  const [selectedDeviceParameter, setSelectedDeviceParameter] = useState({ dp_id: '0', parameter_name: 'All', display_name: 'All', });

  const [loading, setLoadding] = useState(false);
  const [pdf_laoding, setPdfLoadding] = useState(false);
  
  const [report_data_filter, setReportDataFilter] = useState([]);
  const [report_parameter_data, setReportParameterData] = useState([]);
  const [parameter_data, setParameterData] = useState([]);
  const [report_data_get, setReportDataGet] = useState(false);

  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);

  useEffect(() => {
    if (result) {
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };
  
      var postData = {
        client_id: result.client_id,
      };
  
      axios.post(global.passUrl + 'get_client_device_data_active', postData, axiosConfig).then((res) => {
        setDevices(res.data.device_data)
      });

      var AccessData = {
        role_id: result.role_id,
        menu_id: 4,
      };      
      axios.post(global.passUrl + 'get_access_details', AccessData, axiosConfig).then((res) => {
        if (res.data.access_data.length) {
          // eslint-disable-next-line array-callback-return
          res.data.access_data.map((data1) => {
            if (data1.privileges_key === "PDF") {
              if (data1.status) {
                setPDFStatus(1)
              } else {
                setPDFStatus(0)
              }
            }else if (data1.privileges_key === "EXCEL") {
              if (data1.status) {
                setExcelStatus(1)
              } else {
                setExcelStatus(0)
              }
            }
          })
        }
      });

    } else {
      navigate("/")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    
    if (selectedDevice) {
      const startDateVal = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(startDate));
      
      const endDateVal =(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(endDate));

      var postData = {
        start_date: moment(startDateVal).format('YYYY-MM-DD'),
        end_date: moment(endDateVal).format('YYYY-MM-DD'),
        device_eui: selectedDevice ? selectedDevice.device_eui : "",
        device_id: selectedDevice ? selectedDevice.cd_id : "",
        device_name: selectedDevice ? selectedDevice.device_name : "",
        client_id: selectedDevice ? selectedDevice.client_id : "",
        parameter_id: selectedDeviceParameter.parameter_name === "All" ? "0" : selectedDeviceParameter.parameter_name
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
  
      setLoadding(true);
      axios.post(global.passUrl + "get_report_details_by_search", postData, axiosConfig).then((res) => {
        setLoadding(false)
        if (res.status === 200) {
          if (res.data.status === 'error') {
            setReportDataFilter([])
            setReportParameterData([])
            toast.dismiss()
            toast.error(res.data.msg, { theme: "colored", })
          } else {
            const newArray = [];
            // eslint-disable-next-line array-callback-return
            res.data.report_data.map((data, index) => {
              
              var device_array_data = data.device_array;
              var temp_array = {
                id: data.id,
                d_eui: data.d_eui,
                received_datetime: data.received_datetime                
              }

              // eslint-disable-next-line array-callback-return
              res.data.ParameterData.map((data1, index) => {
                var element_name = data1.parameter_name;
                temp_array[element_name] =  <p style={{color: '#'+device_array_data['color_code_'+element_name], 'fontWeight': 600, fontSize: '13px'}}>{device_array_data[element_name]}</p>
              })
              newArray.push(temp_array);
            });
            setReportDataGet(true);
            setReportDataFilter([])
            setReportParameterData(res.data.ParameterData)
            setReportDataFilter(newArray)
          }
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Faill!!", { theme: "colored", })
        }
      }).catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });  
    } else {
      toast.dismiss()
      toast.error("Please select device.", { theme: "colored", })
    }    
  }

  
  var userData = localStorage.getItem('userData');
  var userDataResult = JSON.parse(userData);


  const getExcelReport = () => {

    setPdfLoadding(true);

    const startDateVal = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(startDate));
      
    const endDateVal =(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(endDate));

    var postData = {
      start_date: moment(startDateVal).format('YYYY-MM-DD'),
      end_date: moment(endDateVal).format('YYYY-MM-DD'),
      device_eui: selectedDevice ? selectedDevice.device_eui : "",
      device_id: selectedDevice ? selectedDevice.cd_id : "",
      device_name: selectedDevice ? selectedDevice.device_name : "",
      client_id: selectedDevice ? selectedDevice.client_id : "",
      parameter_id: selectedDeviceParameter.parameter_name === "All" ? "0" : selectedDeviceParameter.parameter_name
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      responseType: 'blob'
    };

    var fileName = "historical_data_"+moment(new Date()).format('HHmmss_DDMMYYYY')+".xlsx";

    let mailAxiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    var postData2 = {
      fileNameVal: "historical_data_"+moment(new Date()).format('HHmmss_DDMMYYYY'),
      usr_id: userDataResult.usr_id,
      type: "xlsx",
      user_name: userDataResult.u_first_name+" "+userDataResult.u_last_name
    };

    axios.post(global.passUrl + "get_excel_report", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        fileDownload(res.data, fileName);          
        axios.post(global.passUrl + "sent_email_excel", postData2, mailAxiosConfig).then((res) => {
          if (res.status === 200) {
            setPdfLoadding(false)
          } else if (res.status === 400) {
            toast.dismiss()
            toast.error("Faill!!", { theme: "colored", })
          }
        }).catch((err) => { 
          setPdfLoadding(false); 
          console.log("AXIOS ERROR: ", err); 
        });
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const getPdfReport = () => {

    setPdfLoadding(true);

    const startDateVal = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(startDate));
      
    const endDateVal =(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(endDate));

    var postData = {
      start_date: moment(startDateVal).format('YYYY-MM-DD'),
      end_date: moment(endDateVal).format('YYYY-MM-DD'),
      device_eui: selectedDevice ? selectedDevice.device_eui : "",
      device_id: selectedDevice ? selectedDevice.cd_id : "",
      device_name: selectedDevice ? selectedDevice.device_name : "",
      client_id: selectedDevice ? selectedDevice.client_id : "",
      parameter_id: selectedDeviceParameter.parameter_name === "All" ? "0" : selectedDeviceParameter.parameter_name
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      responseType: 'blob'
    };

    var fileName = "historical_data_"+moment(new Date()).format('HHmmss_DDMMYYYY')+".pdf";

    let mailAxiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    var postData2 = {
      fileNameVal: "historical_data_"+moment(new Date()).format('HHmmss_DDMMYYYY'),
      usr_id: userDataResult.usr_id,
      type: "pdf",
      user_name: userDataResult.u_first_name+" "+userDataResult.u_last_name
    };


    axios.post(global.passUrl + "get_pdf_report", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        // setPdfLoadding(false)
        fileDownload(res.data, fileName);
        axios.post(global.passUrl + "sent_email_excel", postData2, mailAxiosConfig).then((res) => {
          if (res.status === 200) {
            setPdfLoadding(false)
          } else if (res.status === 400) {
            toast.dismiss()
            toast.error("Faill!!", { theme: "colored", })
          }
        }).catch((err) => { 
          setPdfLoadding(false); 
          console.log("AXIOS ERROR: ", err); 
        });  
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => { console.log("AXIOS ERROR: ", err); });
  }

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    d_eui: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    'option_key': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
  });

  const getParameterData = (value) => {

    var postData = {
      device_data: value
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };

    // let new_array = [];
    axios.post(global.passUrl + "get_parameter_details", postData, axiosConfig).then((res) => {
      if (res.status === 200) {
        if (res.data.status === 'error') {
          setParameterData([])
          toast.dismiss()
          toast.error(res.data.msg, { theme: "colored", })
        } else {          
          setSelectedDevice(value)
          setParameterData(res.data.paramete_data)
        }
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!", { theme: "colored", })
      }
    }).catch((err) => {
      console.log("AXIOS ERROR: ", err);
    });
  }

  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
  };

  const renderHeader = () => {
    const value = filters['global'] ? filters['global'].value : '';

    return (
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Search" />
      </span>
    );
  };
  
  const header = renderHeader();
  return (
    <>
      <ToastContainer />
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-lg-block">
            <Menubar pageName="reports" />
          </div>

          <div className="col-12 col-lg-11 ps-0">
            {/* Topbar */}
            <Topbar />

            {/* Main body */}
            <div className="MainBody p-4 mapBody">
              <Container className="mw-100">
                <Row>
                  {/* Breadcrum here */}
                  <Col sm={12}>
                    <BreadCrumb model={items} home={home} />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Row
                      className="p-3 bg-white border-bottom box"
                      style={{ height: "calc(100vh - 155px)" }}
                    >
                      <Col sm={12}>
                        <Row className="mb-4">
                          <Col sm={6} className="gap-3 flex">
                            <Calendar
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                              placeholder="Start Date"
                              showIcon
                              dateFormat="dd/mm/yy" 
                              name="start_date"
                              className="w-50"
                              showButtonBar
                              maxDate={new Date()}
                            />
                                                        
                            <Calendar
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                              placeholder="End Date"
                              showIcon
                              minDate={startDate}
                              dateFormat="dd/mm/yy" 
                              name="end_date"
                              className="w-50"
                              showButtonBar
                            />

                            <Dropdown
                              value={selectedDevice}
                              onChange={(e) => getParameterData(e.value)}
                              options={devices}
                              optionLabel="device_name"
                              placeholder="Select Device"
                              className="w-50"
                            />
                            <Dropdown
                              value={selectedDeviceParameter}
                              onChange={(e) => setSelectedDeviceParameter(e.value)}
                              options={parameter_data}
                              optionLabel="parameter_name"
                              placeholder="Select Parameter"
                              className="w-50"
                            />
                          </Col>

                          <Col
                            sm={2}
                            className="gap-3 flex"
                          >
                            <Button
                              label="Fetch Data"
                              className="btn btn-primary w-50"
                              rounded
                              onClick={handleSubmit}
                            />
                          </Col>

                          <Col
                            sm={1}
                          >
                            {
                              pdf_laoding ?
                              <div className="col-sm-12 text-center">
                                <img src={Loader} alt="logo" style={{ width: 30 }} /> <br />
                                <span>Loading...</span>
                              </div>
                              :
                              ""
                            }
                          </Col>

                          <Col
                            sm={3}
                            className="gap-3 flex justify-content-end"
                          >
                            {
                              report_data_filter.length > 0 ?
                                <>
                                  {
                                    excel_status === 1 ?
                                    <img src={Excel} alt="logo" className="cursor-pointer" style={{ width: 40, height: 40 }} onClick={getExcelReport} />
                                    :
                                    ""
                                  }
                                  
                                  {
                                    pdf_status === 1 ?
                                    <img src={Pdf} alt="logo" className="cursor-pointer" style={{ width: 40, height: 40 }} onClick={getPdfReport} />
                                    :
                                    ""
                                  }
                                  
                                </> 
                                :
                                ""
                            }
                          </Col>

                          
                        </Row>

                        <Row className="datatable">
                          {loading ?
                            <div className="col-sm-12 mt-4 text-center">
                              <img src={Loader} alt="logo" style={{ width: 30 }} /> <br />
                              <span>Fetching Data...</span>
                            </div>
                            :
                            <div className="col-sm-12 mt-4 mb-5">
                              {
                                report_data_get ?
                                  <DataTable
                                    value={report_data_filter}
                                    selection={selectedDevicesList}
                                    onSelectionChange={(e) => setSelectedDeviceList(e.value)}
                                    dataKey="id"
                                    paginator
                                    rows={10}
                                    sortMode="multiple"
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    tableStyle={{ minWidth: "50rem" }}
                                    filters={filters} 
                                    onFilter={(e) => setFilters(e.filters)}
                                    header={header}
                                  >
                                      <Column field="id" header="Sr.No" x="id" style={{ width: '7%' }} sortable></Column>
                                      <Column field="d_eui" header="Device EUI" headerStyle={{ width: "20%" }} sortable></Column>
                                      {report_parameter_data.map((data, index) =>
                                        <Column field={data.parameter_name} header={data.display_name} headerStyle={{ width: "20%" }} sortable></Column>
                                      )}
                                      <Column field="received_datetime" header="Date/Time" headerStyle={{ width: "30%" }} sortable></Column>
                                  </DataTable>
                                  :
                                  ""
                              }
                            </div>
                          }
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
