/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import Menubar2 from "./adminMenubar2";
import Menubar from "./adminMenubar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/custom.css";
import { error } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/Material.css";
import "@pnotify/core/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useNavigate } from 'react-router-dom';

const Parametes = () => {
  const [unit_data, setUnitData] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState(null);
  const [edit_state, setEditState] = useState(false);
  const [unit_check, setUnitAddCheck] = useState(true);
  const [divide_factor_val, setDivideFactorVal] = useState(1);
  const [multi_factor_val, setMultiFactorVal] = useState(1);
  const [edit_image_name, setAddImageName] = useState();
  const [parameter_data, setParameterData] = useState([]);

  const [edit_name, setEditName] = useState();
  const [edit_unit_id, setEditUnitId] = useState();
  const [edit_divide_factor, setEditDivideFactor] = useState();
  const [edit_multi_factor, setEditMultiFactor] = useState();
  const [edit_check, setEditCheck] = useState();
  const [para_id, setParameterId] = useState();
  

  const onFileChange = e => {
    setAddImageName(e.target.files[0]);
  }
  
  const handleEditDivideFactor = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setEditDivideFactor(value);
  };

  const handleEditMultiFactor = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setEditMultiFactor(value);
  };

  const handleChangeDivideFactor = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setDivideFactorVal(value);
  };

  const handleChangeMultiFactor = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setMultiFactorVal(value);
  };

  const editDetails = (id) => {
    setEditState(true);
    var postData = {
      p_id: id,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post(global.passUrl + "edit_parameter_details", postData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          setEditName(res.data[0].parameter_name);
          setEditUnitId(res.data[0].unit_id);
          setEditDivideFactor(res.data[0].divide_factor);
          setEditMultiFactor(res.data[0].multi_factor);
          setParameterId(id);
          if (res.data[0].is_status == "1") {
            setEditCheck(true);
          } else {
            setEditCheck(false);
          }
        } else if (res.status === 400) {
          toast.dismiss();
          toast.error("Faill...", { theme: "colored" });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const deleteDetails = (id) => {
    error({
      hide: false,
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Parameter!",
      modules: new Map([
        [
          Confirm,
          {
            confirm: true,
            buttons: [
              {
                text: "submit",
                primary: false,
                click: notice => {
                  notice.close();
                  deleteRecord(id)
                }
              },
              {
                text: "cancel",
                click: notice => {
                  notice.close();
                }
              }
            ]
          }
        ]
      ])
    });
  }

  const deleteRecord = (id) => {
    var postData = {
      p_id: id
    };

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl + 'delete_parameter', postData, axiosConfig)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.status === 'success') {
            toast.dismiss()
            toast.success(res.data.message, { theme: "colored", })
            setInterval(() => {
              window.location.href = '';
            }, 2500);
          } else {
            toast.dismiss()
            toast.error(res.data.message, { theme: "colored", })
          }
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Fail!!", { theme: "colored", })
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  const editFormCanceld = () => {
    setEditState(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    var postData = {
      parameter_name: data.get("parameter_name"),
      unit_id: data.get("unit_id"),
      divide_factor: data.get("divide_factor"),
      multi_factor: data.get("multi_factor"),
      image_name: data.get("image_name_data"),
      is_status: unit_check ? 1 : 0,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    };

    if (
      postData.parameter_name === "" &&
      postData.unit_id === ""
    ) {
      toast.dismiss();
      toast.error("All fields are required!", { theme: "colored" });
    } else if (postData.parameter_name === "") {
      toast.dismiss();
      toast.error("Parameter Name is required!", { theme: "colored" });
    } else if (postData.unit_id === "") {
      toast.dismiss();
      toast.error("Please select unit!", { theme: "colored" });
    }else {
      /*
         else if (postData.image_name.name === undefined || postData.image_name.name === "") {
          toast.dismiss();
          toast.error("Image is required!", { theme: "colored" });
        } 
      */
      axios.post(global.passUrl + "add_parameter", postData, axiosConfig).then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.status === "success") {
              toast.dismiss();
              toast.success(res.data.message, { theme: "colored" });
              setInterval(() => {
                window.location.href = "";
              }, 2500);
            } else {
              toast.dismiss();
              toast.error(res.data.message, { theme: "colored" });
            }
          } else if (res.status === 400) {
            toast.dismiss();
            toast.error("Faill!!", { theme: "colored" });
          }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    var postData = {};

    if (edit_image_name === undefined) {
      postData = {
        parameter_name: data.get("edit_parameter_name"),
        unit_id: data.get("edit_unit_id"),
        divide_factor: data.get("edit_divide_factor"),
        multi_factor: data.get("edit_multi_factor"),
        image_name_edit_tye: "N",
        is_status: edit_check ? 1 : 0,
        p_id: para_id
      };
    }else{
      postData = {
        parameter_name: data.get("edit_parameter_name"),
        unit_id: data.get("edit_unit_id"),
        divide_factor: data.get("edit_divide_factor"),
        multi_factor: data.get("edit_multi_factor"),
        image_name: data.get("edit_image_name"),
        image_name_edit_tye: "Y",
        is_status: edit_check ? 1 : 0,
        p_id: para_id
      };
    }

    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    };

    if (
      postData.parameter_name === "" &&
      postData.unit_id === ""
    ) {
      toast.dismiss();
      toast.error("All fields are required!", { theme: "colored" });
    } else if (postData.parameter_name === "") {
      toast.dismiss();
      toast.error("Parameter Name is required!", { theme: "colored" });
    } else {
      axios
        .post(global.passUrl + "update_parameter", postData, axiosConfig)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.status === "success") {
              toast.dismiss();
              toast.success(res.data.message, { theme: "colored" });
              setInterval(() => {
                window.location.href = "";
              }, 2500);
            } else {
              toast.dismiss();
              toast.error(res.data.message, { theme: "colored" });
            }
          } else if (res.status === 400) {
            toast.dismiss();
            toast.error("Faill!!", { theme: "colored" });
          }
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        });
    }
  };

  const navigate = useNavigate()

  var data1 = localStorage.getItem('adminLoginData');
  var result1 = JSON.parse(data1);

  useEffect(() => {
    if(result1){
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      //Get all users details in bootstrap table
      axios.post(global.passUrl + "get_unit_data", axiosConfig).then((res) => {
        setUnitData(res.data);
      });

      let new_array = [];
      axios.post(global.passUrl + "getParameterData", axiosConfig).then((res) => {      
        res.data.forEach(element => {
          new_array.push({
            p_id: element.p_id,
            parameter_name: element.parameter_name,
            unit_name: element.unit_name,
            divide_factor: element.divide_factor,
            multi_factor: element.multi_factor,          
            parameter_image: element.image_name === null ? "" :  <img src={require('../../../../server/parameter_images/'+element.image_name)} alt={element.image_name} style={{ width: 35 , height: 35}} />,
            // parameter_image: element.image_name === null ? "" :  <img src={global.imageUrl+`parameter_images/`+element.image_name} alt={element.image_name} style={{ width: 35 , height: 35}} />,
            is_status: element.is_status ? <Badge bg="success">Active</Badge> : <Badge bg="danger">In-Active</Badge>,
            operation:
              <div className="action">
                <div
                  onClick={() =>
                    editDetails(element.p_id)
                  }
                  className="cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.2847 2.12264L17.7759 3.32139C17.9199 3.45821 18.0011 3.64932 18 3.84896C17.9989 4.0486 17.9157 4.2388 17.7702 4.37402L16.6371 5.74292L12.7815 10.3851C12.714 10.463 12.6242 10.5178 12.5244 10.5418L10.5247 10.9994C10.2623 11.0126 10.0339 10.8201 10 10.5572V8.48266C10.0068 8.38259 10.0467 8.2878 10.1133 8.21349L13.8975 3.89433L15.1963 2.32836C15.4532 1.98833 15.9233 1.8995 16.2847 2.12264Z"
                      stroke="#55A1B2"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.5 4.53846C10.9265 4.53846 11.2723 4.19407 11.2723 3.76923C11.2723 3.3444 10.9265 3 10.5 3V4.53846ZM7.61658 3.76923V3H7.61473L7.61658 3.76923ZM3.77236 7.61542H4.5447V7.61368L3.77236 7.61542ZM3.77236 13.3846L4.5447 13.3863V13.3846H3.77236ZM7.61658 17.2308L7.61473 18H7.61658V17.2308ZM13.3834 17.2308V18H13.3853L13.3834 17.2308ZM17.2276 13.3846H16.4553V13.3863L17.2276 13.3846ZM18 9.53849C18 9.11357 17.6542 8.76926 17.2276 8.76926C16.8011 8.76926 16.4553 9.11357 16.4553 9.53849H18ZM14.4681 3.58032C14.3053 3.18763 13.8537 3.00071 13.4594 3.16282C13.0651 3.32493 12.8775 3.77468 13.0403 4.16737L14.4681 3.58032ZM16.8836 6.59314C17.3 6.50144 17.563 6.09083 17.471 5.67601C17.3789 5.26119 16.9667 4.99924 16.5501 5.09095L16.8836 6.59314ZM6.65578 13.5774C6.22923 13.5774 5.88344 13.9218 5.88344 14.3467C5.88344 14.7715 6.22923 15.1159 6.65578 15.1159V13.5774ZM14.3452 15.1159C14.7718 15.1159 15.1176 14.7715 15.1176 14.3467C15.1176 13.9218 14.7718 13.5774 14.3452 13.5774V15.1159ZM10.5 3H7.61658V4.53846H10.5V3ZM7.61473 3C5.06042 3.00611 2.99454 5.07303 3.00001 7.61706L4.5447 7.61368C4.54106 5.91924 5.91709 4.54253 7.61843 4.53846L7.61473 3ZM3.00001 7.61542V13.3846H4.5447V7.61542H3.00001ZM3.00001 13.3829C2.99454 15.9269 5.06042 17.9938 7.61473 18L7.61843 16.4615C5.91709 16.4574 4.54106 15.0807 4.5447 13.3863L3.00001 13.3829ZM7.61658 18H13.3834V16.4615H7.61658V18ZM13.3853 18C15.9396 17.9938 18.0054 15.9269 18 13.3829L16.4553 13.3863C16.4589 15.0807 15.0829 16.4574 13.3816 16.4615L13.3853 18ZM18 13.3846V9.53849H16.4553V13.3846H18ZM13.0403 4.16737C13.241 4.6516 13.6504 5.33794 14.2625 5.86826C14.8884 6.41073 15.7879 6.83438 16.8836 6.59314L16.5501 5.09095C16.0713 5.19637 15.6552 5.03593 15.2764 4.70764C14.8835 4.36717 14.599 3.89608 14.4681 3.58032L13.0403 4.16737ZM6.65578 15.1159H14.3452V13.5774H6.65578V15.1159Z"
                      fill="#55A1B2"
                    />
                  </svg>
                </div>

                <div
                  onClick={() =>
                    deleteDetails(element.p_id)
                  }
                  className="cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M8.3335 10V14.1667"
                      stroke="#FF0000"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.6665 10V14.1667"
                      stroke="#FF0000"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.3335 5.83334H16.6668"
                      stroke="#FF0000"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5 8.33334V15C5 16.3808 6.11929 17.5 7.5 17.5H12.5C13.8807 17.5 15 16.3808 15 15V8.33334"
                      stroke="#FF0000"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.5 4.16667C7.5 3.24619 8.24619 2.5 9.16667 2.5H10.8333C11.7538 2.5 12.5 3.24619 12.5 4.16667V5.83333H7.5V4.16667Z"
                      stroke="#FF0000"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
          });
        });

        setParameterData(new_array);
        setDeviceDetailsFilter(new_array);
      });
    }else{
      navigate("/admin-login")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeSwitch = (e) => {
    const checked = e.target.checked;
    const lightLabel = checked ? true : false;
    setUnitAddCheck(lightLabel);
  };

  const handleEditSwitch = (e) => {
    const checked = e.target.checked;
    const lightLabel = checked ? true : false;
    setEditCheck(lightLabel);
  };

  const rowActiveBodyTemplate = (rowData) => {
    return (
      <div style={{ textAlign: "center"}}>
        {rowData.is_status}
      </div>
    );
  }

  const rowImageBodyTemplate = (rowData) => {
    return (
      <div style={{ textAlign: "center"}}>
        {rowData.parameter_image}
      </div>
    );
  }

  const rowActionsBodyTemplate = (element) => {
    return (
      <div className="flex flex-row gap-3">
        <div
          onClick={() =>
            editDetails(element.p_id)
          }
          className="cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.2847 2.12264L17.7759 3.32139C17.9199 3.45821 18.0011 3.64932 18 3.84896C17.9989 4.0486 17.9157 4.2388 17.7702 4.37402L16.6371 5.74292L12.7815 10.3851C12.714 10.463 12.6242 10.5178 12.5244 10.5418L10.5247 10.9994C10.2623 11.0126 10.0339 10.8201 10 10.5572V8.48266C10.0068 8.38259 10.0467 8.2878 10.1133 8.21349L13.8975 3.89433L15.1963 2.32836C15.4532 1.98833 15.9233 1.8995 16.2847 2.12264Z"
              stroke="#55A1B2"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.5 4.53846C10.9265 4.53846 11.2723 4.19407 11.2723 3.76923C11.2723 3.3444 10.9265 3 10.5 3V4.53846ZM7.61658 3.76923V3H7.61473L7.61658 3.76923ZM3.77236 7.61542H4.5447V7.61368L3.77236 7.61542ZM3.77236 13.3846L4.5447 13.3863V13.3846H3.77236ZM7.61658 17.2308L7.61473 18H7.61658V17.2308ZM13.3834 17.2308V18H13.3853L13.3834 17.2308ZM17.2276 13.3846H16.4553V13.3863L17.2276 13.3846ZM18 9.53849C18 9.11357 17.6542 8.76926 17.2276 8.76926C16.8011 8.76926 16.4553 9.11357 16.4553 9.53849H18ZM14.4681 3.58032C14.3053 3.18763 13.8537 3.00071 13.4594 3.16282C13.0651 3.32493 12.8775 3.77468 13.0403 4.16737L14.4681 3.58032ZM16.8836 6.59314C17.3 6.50144 17.563 6.09083 17.471 5.67601C17.3789 5.26119 16.9667 4.99924 16.5501 5.09095L16.8836 6.59314ZM6.65578 13.5774C6.22923 13.5774 5.88344 13.9218 5.88344 14.3467C5.88344 14.7715 6.22923 15.1159 6.65578 15.1159V13.5774ZM14.3452 15.1159C14.7718 15.1159 15.1176 14.7715 15.1176 14.3467C15.1176 13.9218 14.7718 13.5774 14.3452 13.5774V15.1159ZM10.5 3H7.61658V4.53846H10.5V3ZM7.61473 3C5.06042 3.00611 2.99454 5.07303 3.00001 7.61706L4.5447 7.61368C4.54106 5.91924 5.91709 4.54253 7.61843 4.53846L7.61473 3ZM3.00001 7.61542V13.3846H4.5447V7.61542H3.00001ZM3.00001 13.3829C2.99454 15.9269 5.06042 17.9938 7.61473 18L7.61843 16.4615C5.91709 16.4574 4.54106 15.0807 4.5447 13.3863L3.00001 13.3829ZM7.61658 18H13.3834V16.4615H7.61658V18ZM13.3853 18C15.9396 17.9938 18.0054 15.9269 18 13.3829L16.4553 13.3863C16.4589 15.0807 15.0829 16.4574 13.3816 16.4615L13.3853 18ZM18 13.3846V9.53849H16.4553V13.3846H18ZM13.0403 4.16737C13.241 4.6516 13.6504 5.33794 14.2625 5.86826C14.8884 6.41073 15.7879 6.83438 16.8836 6.59314L16.5501 5.09095C16.0713 5.19637 15.6552 5.03593 15.2764 4.70764C14.8835 4.36717 14.599 3.89608 14.4681 3.58032L13.0403 4.16737ZM6.65578 15.1159H14.3452V13.5774H6.65578V15.1159Z"
              fill="#55A1B2"
            />
          </svg>
        </div>

        <div
          onClick={() =>
            deleteDetails(element.p_id)
          }
          className="cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M8.3335 10V14.1667"
              stroke="#FF0000"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.6665 10V14.1667"
              stroke="#FF0000"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.3335 5.83334H16.6668"
              stroke="#FF0000"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 8.33334V15C5 16.3808 6.11929 17.5 7.5 17.5H12.5C13.8807 17.5 15 16.3808 15 15V8.33334"
              stroke="#FF0000"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.5 4.16667C7.5 3.24619 8.24619 2.5 9.16667 2.5H10.8333C11.7538 2.5 12.5 3.24619 12.5 4.16667V5.83333H7.5V4.16667Z"
              stroke="#FF0000"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>   
      </div>
    );
  };

  const [device_details_filter, setDeviceDetailsFilter] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
    const filtered_list = device_details_filter.filter((item) => {
      return item.parameter_name.toLowerCase().includes(value.toLowerCase());
    });
    setParameterData(filtered_list);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search by Parameter Name"
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();
  
  return (
    <>
      <ToastContainer />
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-lg-block">
            <Menubar />
          </div>

          <div className="col-12 col-lg-11 ps-0">
            <div className="topBar">
              <div className="row">
                <div className="col-lg-4 title">
                  <div className="d-lg-none d-sm-block d-block">
                    <Menubar2 />
                  </div>
                  <h3>Parameters</h3>
                </div>

                <div className="col-lg-4 search d-none d-sm-block">
                  <div className="input-group">
                    <span
                      className="input-group-text border-end-0"
                      id="basic-addon2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="33"
                        height="32"
                        viewBox="0 0 33 32"
                        fill="none"
                      >
                        <path
                          d="M28.1601 24.586L23.0831 19.509C24.1321 17.928 24.7461 16.035 24.7461 14C24.7461 8.486 20.2601 4 14.7461 4C9.23209 4 4.74609 8.486 4.74609 14C4.74609 19.514 9.23209 24 14.7461 24C16.7811 24 18.6741 23.386 20.2551 22.337L25.3321 27.414C26.1121 28.195 27.3801 28.195 28.1601 27.414C28.9411 26.633 28.9411 25.367 28.1601 24.586ZM7.74609 14C7.74609 10.14 10.8861 7 14.7461 7C18.6061 7 21.7461 10.14 21.7461 14C21.7461 17.86 18.6061 21 14.7461 21C10.8861 21 7.74609 17.86 7.74609 14Z"
                          fill="#55A1B2"
                        />
                      </svg>
                    </span>
                    <input
                      type="password"
                      className="form-control border-start-0"
                      placeholder="Search here..."
                      aria-label="Password"
                      aria-describedby="Password"
                    />
                  </div>
                </div>

                <div className="col-lg-4"></div>
              </div>
            </div>

            <div className="mainContent">
              <div className="row">
                <div className="col-lg-8">
                  <div className="leftPanel">
                    <div className="row tableTitle">Parameters</div>
                    <DataTable
                      header={header}
                      value={parameter_data}
                      selection={selectedDevices}
                      onSelectionChange={(e) => setSelectedDevices(e.value)}
                      stripedRows 
                      showGridlines 
                      paginator 
                      rows={10} 
                      scrollable scrollHeight="600px"
                      rowsPerPageOptions={[5, 10, 25, 50]} 
                    >
                      <Column
                        field="parameter_name"
                        header="Parameter Name"
                        sortable
                      ></Column>
                      <Column
                        field="unit_name"
                        header="Unit Name"
                        sortable
                      ></Column>
                      <Column
                        field="divide_factor"
                        header="Divide Factor"
                        sortable
                      ></Column>
                      <Column
                        field="multi_factor"
                        header="Multiplying Factor"
                        sortable
                      ></Column>
                      <Column
                        field="parameter_image"
                        header="Parameter Image"
                        body={rowImageBodyTemplate}
                        sortable
                      ></Column>
                      <Column
                        field=""
                        header="Active"
                        body={rowActiveBodyTemplate}
                      ></Column>
                      <Column
                        field=""
                        header="Operations"
                        body={rowActionsBodyTemplate}
                      ></Column>
                    </DataTable>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="rightPanel">
                    <Form 
                      className="Details"
                      style={{ display: edit_state ? "none" : "block" }}
                      method="POST"
                      onSubmit={handleSubmit}
                    >                      
                      <div className="row">  
                        <div className="row tableTitle">Add Parameters Details</div>
                      </div>
                      <Form.Group
                        className="mb-4 mandatory"
                      >
                        <Form.Label>Parameter Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Parameter Name"
                          id="parameter_name"
                          name="parameter_name"
                        />
                      </Form.Group>
                      
                      <Form.Group className="mb-4 mandatory">
                        <Form.Label>Unit Name </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          id="unit_id"
                          name="unit_id"
                        >
                          <option value="">Select Unit</option>    
                          {unit_data.map((data, index) => (
                            <option value={data.unit_id} key={index}>
                              {data.unit_name}
                            </option>
                          ))}                      
                        </Form.Select>
                      </Form.Group>

                      <Form.Group
                        className="mb-4 mandatory"
                      >
                        <Form.Label>Divide Factor</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Divide Factor"
                          id="divide_factor"
                          name="divide_factor"
                          value={divide_factor_val}
                          onChange={handleChangeDivideFactor}
                        />
                      </Form.Group>
                      
                      <Form.Group
                        className="mb-4 mandatory"
                      >
                        <Form.Label>multiplying</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter multiplying"
                          id="multi_factor"
                          name="multi_factor"
                          value={multi_factor_val}
                          onChange={handleChangeMultiFactor}
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-4"
                      >
                        <Form.Label>Image</Form.Label>
                        <Form.Control type="file" id="image_name_data" name="image_name_data"/>
                      </Form.Group>

                      <Form.Group
                        className="mb-4 mandatory"
                      >
                        <Form.Label>Active </Form.Label>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                          checked={unit_check}
                          onChange={handleChangeSwitch}
                        />
                      </Form.Group>

                      <div className="btnFooter">
                        <Button
                          variant="danger"
                          className="btn btn-danger"
                          type="submit"
                          style={{ display: "none" }}
                        >
                          Cancel
                        </Button>

                        <Button
                          variant="primary"
                          className="btn btn-primary"
                          type="submit"
                        >
                          Save
                        </Button>
                      </div>
                    </Form>

                    <Form 
                      className="Details"
                      style={{ display: edit_state ? "block" : "none" }}
                      method="POST"
                      onSubmit={handleUpdate}
                    >       
                      <div className="row">  
                        <div className="row tableTitle">Edit Parameters Details</div>
                      </div>               
                      <Form.Group
                        className="mb-4 mandatory"
                      >
                        <Form.Label>Parameter Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Parameter Name"
                          id="edit_parameter_name"
                          name="edit_parameter_name"
                          value={edit_name}
                          onChange={e => setEditName(e.target.value)}
                        />
                      </Form.Group>
                      
                      <Form.Group className="mb-4 mandatory">
                        <Form.Label>Unit Name </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          id="edit_unit_id"
                          name="edit_unit_id"
                          value={edit_unit_id}
                          onChange={e => setEditUnitId(e.target.value)}
                        >
                          <option value="0">Select Unit</option>    
                          {unit_data.map((data, index) => (
                            <option value={data.unit_id} key={index}>
                              {data.unit_name}
                            </option>
                          ))}                      
                        </Form.Select>
                      </Form.Group>

                      <Form.Group
                        className="mb-4 mandatory"
                      >
                        <Form.Label>Divide Factor</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Divide Factor"
                          id="edit_divide_factor"
                          name="edit_divide_factor"
                          value={edit_divide_factor}
                          onChange={handleEditDivideFactor}
                        />
                      </Form.Group>
                      
                      <Form.Group
                        className="mb-4 mandatory"
                      >
                        <Form.Label>multiplying</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter multiplying"
                          id="edit_multi_factor"
                          name="edit_multi_factor"
                          value={edit_multi_factor}
                          onChange={handleEditMultiFactor}
                        />
                      </Form.Group>

                      
                      
                      <Form.Group
                        className="mb-4 mandatory"
                      >
                        <Form.Label>Image</Form.Label>
                        <Form.Control type="file" id="edit_image_name" name="edit_image_name" onChange={onFileChange} />
                      </Form.Group>

                      <Form.Group
                        className="mb-4 mandatory"
                      >
                        <Form.Label>Active </Form.Label>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                          checked={edit_check}
                          onChange={handleEditSwitch}
                        />
                      </Form.Group>

                      <div className="btnFooter">
                        <Button
                          variant="danger"
                          className="btn btn-danger"
                          type="button"
                          onClick={editFormCanceld}
                        >
                          Cancel
                        </Button>

                        <Button
                          variant="primary"
                          className="btn btn-primary"
                          type="submit"
                        >
                          Save
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Parametes;
