import StatusDemo from "../widgets/status";

export const MapService = {
    getMapsData() {
        return [
            {
                id: '1000',
                name: 'Map Name',
                model: 'EM300-TH',
                description: 'Product Description',
                image: 'bamboo-watch.jpg',
                sn: '6136C48378951008',
                status: <StatusDemo status="success" text="Assigned"/>,
                quantity: 24,
                inventoryStatus: 'INSTOCK',
                rating: 5
            },
            {
                id: '1001',
                name: 'Map Name',
                model: 'EM300-TH',
                description: 'Product Description',
                image: 'bamboo-watch.jpg',
                sn: '6136C48378951008',
                status: <StatusDemo status="error" text="Unassigned"/>,
                quantity: 24,
                inventoryStatus: 'INSTOCK',
                rating: 5
            },
            {
                id: '1002',
                name: 'Map Name',
                model: 'EM300-TH',
                description: 'Product Description',
                image: 'bamboo-watch.jpg',
                sn: '6136C48378951008',
                status: <StatusDemo status="success" text="Assigned"/>,
                quantity: 24,
                inventoryStatus: 'INSTOCK',
                rating: 5
            }
        ];
    },

    getMapsMini() {
        return Promise.resolve(this.getMapsData().slice(0, 5));
    },

    getMapsSmall() {
        return Promise.resolve(this.getMapsData().slice(0, 10));
    },

    getMaps() {
        return Promise.resolve(this.getMapsData());
    },

    getMapsWithOrdersSmall() {
        return Promise.resolve(this.getMapsWithOrdersData().slice(0, 10));
    },

    getMapsWithOrders() {
        return Promise.resolve(this.getMapsWithOrdersData());
    }
};

