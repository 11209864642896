import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Menubar from "../menubar";
import TopBar from "../topbar";
import { OverlayPanel } from "primereact/overlaypanel";

import { Dialog } from "primereact/dialog";
import axios from "axios";

import { useNavigate } from 'react-router-dom';


import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import Loader from '../../../assets/Images/default.gif';

// Icons
import { ReactComponent as AlarmRedIcon } from "../../../assets/Images/client/icons/alarmRed.svg";
import { ReactComponent as InactiveIcon } from "../../../assets/Images/client/icons/inactive.svg";
import { ReactComponent as NormalIcon } from "../../../assets/Images/client/icons/normal.svg";
import { ReactComponent as OfflineIcon } from "../../../assets/Images/client/icons/offline.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/Images/client/icons/settings.svg";
import { ReactComponent as BarChartIcon } from "../../../assets/Images/client/icons/barChart.svg";
// import { ReactComponent as InfoIcon } from "../../../assets/Images/client/icons/info.svg";

const Devices = () => {
  const [Devices, setDevices] = useState([]);
  const [device_details_filter, setDeviceDetailsFilter] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [online_count, setOnlineCount] = useState("0");
  const [offline_count, setOfflineCount] = useState("0");
  const [in_active_count, setInActiveCount] = useState("0");
  const [data_laoding, setDataLaoding] = useState(true);
  const [device_info_data, setDataInfoData] = useState([]);
  const [device_name, setDeviceName] = useState("");
  const [device_data_found, setDeviceDataFound] = useState();

  const [edit_status, setEditStatus] = useState(0)
  const [view_status, setViewStatus] = useState(0)
  const [history_status, setHistoryStatus] = useState(0)
  const [action_status, setActionStatus] = useState(1)
  

  var data = localStorage.getItem('userData');
  var result = JSON.parse(data);

  const op = useRef(null);
  const navigate = useNavigate()
  
  
  const  updateAuditLog = () => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    var postData1 = {
      usr_id: result.usr_id,
      user_action: "Device",
      sys_remark : "Device List Open",
      device_eui: ""
    };

    axios.post(global.passUrl + 'call_audit_log', postData1, axiosConfig).then((res) => {
      
    });
  }

  useEffect(()=>{
    if (result) {
      
      updateAuditLog()

      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };

      var postData1 = {
        usr_id: result.usr_id,
        client_id: result.client_id,
      };

      axios.post(global.passUrl + 'get_devices_data', postData1, axiosConfig).then((res) => {        
        setDevices([]);
        setDevices(res.data.device_data);
        setDeviceDetailsFilter(res.data.device_data)
        setOnlineCount(res.data.online_cnt);
        setOfflineCount(res.data.offline_cnt);
        setInActiveCount(res.data.in_active_cnt);
      });

      var AccessData = {
        role_id: result.role_id,
        menu_id: 2,
      };      
      axios.post(global.passUrl + 'get_access_details', AccessData, axiosConfig).then((res) => {
        if (res.data.access_data.length) {
          setActionStatus(1)
          // eslint-disable-next-line array-callback-return
          res.data.access_data.map((data1) => {
            if (data1.privileges_key === "EDIT") {
              if (data1.status) {
                setEditStatus(1)
              } else {
                setEditStatus(0)
              }
            }else if (data1.privileges_key === "VIEW") {
              if (data1.status) {
                setViewStatus(1)
              } else {
                setViewStatus(0)
              }
            }else if (data1.privileges_key === "HISTORY") {
              if (data1.status) {
                setHistoryStatus(1)
              } else {
                setHistoryStatus(0)
              }
            }
          })
        } else {
          setActionStatus(0)
        }
      });
     

    } else {
      navigate("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
    const filtered_list = device_details_filter.filter((item) => {
      return item.name.toLowerCase().includes(value.toLowerCase());
    });
    setDevices(filtered_list);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();

  const interfaceBodyTemplate = (rowData) => {
    return (
      <div className="flex flex-row gap-3">
        {rowData.interfaceStatus?.map((data, index) => (
          <div className="flex flex-column align-items-center w-25">
            <h5>{data.value} {data.unit}</h5>
            <p className="mb-0">{data.p_name}</p>
          </div>
        ))}
      </div>
    );
  };

  const ReportNameTemplate = (device) => {
    return (
      <div className="text-center">
        <h5 className="text-primary">{device.name}</h5>
        <p>{device.subName}</p>
      </div>
    );
  };

  const editDetails = (id) => {
    window.location.href = "/devices/deviceSettings/" + id;
  }
  const getHistory = (id) => {
    window.location.href = "/devices/device-history/" + id;
  }


  const getDetails = (e,d_eui) => {
    op.current.toggle(e)
    setDataLaoding(true);
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    var postData1 = {
      d_eui: d_eui,
      client_id: result.client_id,
    };

    axios.post(global.passUrl + 'get_devices_info_data', postData1, axiosConfig).then((res) => {
      setDataLaoding(false);
      setDataInfoData(res.data.device_data)
      setDeviceName(res.data.device_name);
      setDeviceDataFound(res.data.device_data_found);
    });
  }

  const rowActionsBodyTemplate = (rowData) => {
    return (
      <div className="flex flex-row gap-3">

        {
          edit_status === 1 ?
          <div
            onClick={() => editDetails(rowData.id)}
            className="cursor-pointer"
            tooltip="Enter your username"
            tooltipOptions={{ position: "bottom" }}
          >
            <SettingsIcon />
          </div>
          :
          ""
        }
        
        {
          history_status === 1 ?
          <div
            onClick={() => getHistory(rowData.id)}
            className="cursor-pointer"
            tooltip="Enter your username"
            tooltipOptions={{ position: "bottom" }}
          >
            <BarChartIcon />
          </div>
          :
          ""
        }

        {
          view_status === 1 ?
            <Row>
              <Button
                className="infoIcon1"
                icon="pi pi-info"
                rounded
                outlined
                aria-label="Filter"
                size="small"
                onClick={(e) =>  getDetails(e,rowData.subName)}
                style={{ marginLeft: "10px"}}
              />

              <OverlayPanel ref={op} style={{ minWidth: "300px" }}>
                {
                  data_laoding ?
                  <div className="col-sm-12 text-center">
                    <img src={Loader} alt="logo" style={{ width: 30 }} /> <br />
                    <span>Loading...</span>
                  </div>
                  :
                  <Row>
                    <h5 className="text-primary">{device_name}</h5>
                    {
                      device_data_found ?
                      <Row>
                        <Col sm={6} className="col-sm-6 flex ">
                          <p>
                            <span className="text-primary">RSI :</span> {device_info_data.rssi}
                          </p>
                        </Col>
                        <Col sm={6} className="col-sm-6 flex ">
                          <p>
                            <span className="text-primary">SF :</span> {device_info_data.spread_factor}
                          </p>
                        </Col>
                        <Col sm={6} className="col-sm-6 flex ">
                          <p>
                            <span className="text-primary">SNR :</span> {device_info_data.snr}
                          </p>
                        </Col>
                        <Col sm={6} className="col-sm-6 flex ">
                          <p>
                            <span className="text-primary">Band Width :</span> {device_info_data.band_width}
                          </p>
                        </Col>
            
                      </Row>
                      :
                      <Row>
                        <p className="text-danger">Details not found..</p>
                      </Row>
                    }
                    
                  </Row>
                }
                
              </OverlayPanel>
            </Row>
          :
            ""
        }

      </div>
    );
  };

  // Add Devices
  const [visible, setVisible] = useState(false);
  const footerContent = (
    <div>
      <Button
        label="Cancle"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        severity="danger"
        rounded
      />
      <Button
        label="Confirm"
        icon="pi pi-check"
        onClick={() => setVisible(false)}
        className="ms-2"
        autoFocus
        rounded
      />
    </div>
  );

  return (
    <>
      <div className="conatainer otherPages">
        <div className="row">
          <div className="col-2 col-lg-1 pe-0 d-none d-lg-block">
            <Menubar pageName="devices" />
          </div>

          <div className="col-12 col-lg-11 ps-0">
            <TopBar />
            {/* Main body */}
            <div className="MainBody p-4">
              <Container className="mw-100">
                <Row>
                  <Col>
                    <Row className="p-3 bg-white border-bottom">
                      <Col sm={6}>
                        <Row className="gap-4">
                          <Col
                            sm={2}
                            className="deviceStatus col-sm-2 flex gap-3 p-3 border align-items-center "
                          >
                            <NormalIcon width={30} height={30} />
                            <div className="flex flex-column align-items-center ">
                              <h2 className="text-success">{online_count}</h2>
                              <p className="mb-0">Normal</p>
                            </div>
                          </Col>
                          <Col
                            sm={2}
                            className="deviceStatus col-sm-2 flex gap-3 p-3 border align-items-center "
                          >
                            <AlarmRedIcon width={30} height={30} />
                            <div className="flex flex-column align-items-center ">
                              <h2 className="text-danger">01</h2>
                              <p className="mb-0">Alarm</p>
                            </div>
                          </Col>
                          <Col
                            sm={2}
                            className="deviceStatus col-sm-2 flex gap-3 p-3 border align-items-center "
                          >
                            <OfflineIcon width={30} height={30} />
                            <div className="flex flex-column align-items-center ">
                              <h2 className="text-secondary">{offline_count}</h2>
                              <p className="mb-0">Offline</p>
                            </div>
                          </Col>
                          <Col
                            sm={2}
                            className="deviceStatus col-sm-2 flex gap-3 p-3 border align-items-center "
                          >
                            <InactiveIcon width={30} height={30} />
                            <div className="flex flex-column align-items-center ">
                              <h2 className="text-primary">{in_active_count}</h2>
                              <p className="mb-0">Inactive</p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        sm={6}
                        className="flex justify-content-end align-items-start"
                      >
                        {header}
                        <Button
                          label="Add Device"
                          className="btn btn-primary addDashboard ms-4 d-none"
                          icon="pi pi-plus"
                          onClick={() => setVisible(true)}
                        />
                      </Col>
                    </Row>
                    
                    <DataTable
                      value={Devices}
                      selection={selectedDevices}
                      onSelectionChange={(e) => setSelectedDevices(e.value)}
                      dataKey="id"
                      tableStyle={{ minWidth: "50rem" }}
                      stripedRows
                      globalFilterFields={[
                        "status",
                        "name",
                        "interfaceStatus",
                        "updateTime",
                      ]}
                    >
                      <Column field="name" header="Name" body={ReportNameTemplate} className="justify-content-center" sortable></Column>
                      <Column
                        field="interfaceStatus.title"
                        header="Interface Status"
                        body={interfaceBodyTemplate}
                        className="justify-content-center"
                        headerStyle={{ align: "center" }}
                        sortable
                      ></Column>
                      <Column
                        field="updateTime"
                        header="Updated Time"
                        sortable
                      ></Column>
                      {
                        action_status ?
                        <Column
                          field=""
                          header="Action"
                          body={rowActionsBodyTemplate}
                        ></Column>
                        :
                        ""
                      }
                      
                    </DataTable>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>

      {/* ----------- Modal of Add Device ------------ */}
      <Dialog
        header="Add Device"
        visible={visible}
        style={{ width: "30vw" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        <Row className="mb-4">
          <Col sm={12}>
            <div className="flex flex-column gap-2 mb-4">
              <label htmlFor="dashboardName">Name of Dashboard</label>
              <InputText id="dashboardName" className="w-100" />
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="sn">SN</label>
              <InputText id="sn" className="w-100" />
            </div>
          </Col>
        </Row>
      </Dialog>
    </>
  );
};

export default Devices;
