import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginReg from "./component/auth/login";
import ForgotPassword from "./component/auth/ForgotPassword";
import ResetPassword from "./component/auth/ResetPassword.js";
import Layout from "./component/nav_src/Layout";
import Dashboard from "./component/pages/Dashboard";
import FirstLogin from "./component/pages/FirstLoginPage";
import Devices from './component/pages/deviceManagement/devices';
import DevicesList from './component/pages/deviceManagement/list';
import DeviceSettings from './component/pages/deviceManagement/deviceSettings';
import DeviceHistory from './component/pages/deviceManagement/deviceHistory';
import GatewaysSettings from './component/pages/deviceManagement/gatewaysSettings';
import Map from './component/pages/MarkersMap';
import Triggers from './component/pages/triggers';
import AddTriggers from './component/pages/addTriggers';
import Reports from './component/pages/reports';
import Events from './component/pages/eventManagement/events';
import Gateways from './component/pages/deviceManagement/gateways';
import SharingCenter from './component/pages/sharingCenter/sharingCenter';
import Profile from './component/pages/profile/profile';
import SupportRequestClient from './component/pages/TroubleShooting';
import Role from './component/pages/Role';
import SetPermission from './component/pages/SetPermission';
import UsersDetails from './component/pages/Users/UserManagement';
import SiteMapData from './component/pages/SiteMapData';


// Admin Login Components
import SuperAdminLogin from './component/SuperAdmin/SuperAdminLogin';
import AdminDashbaordScreen from "./component/SuperAdmin/AdminDashbaord";
import AdminClientScreen from "./component/SuperAdmin/adminClients";
import AdminUsersScreen from "./component/SuperAdmin/AdminUsers";
import UnitScreen from "./component/SuperAdmin/Unit";
import DeviceManagementsScreen from "./component/SuperAdmin/DeviceManagements";
import Parameters from './component/SuperAdmin/Parameters';
import Troubleshooting from './component/SuperAdmin/Troubleshooting';
import SupportRequest from './component/SuperAdmin/SupportRequest';
import PrivilegesRole from './component/SuperAdmin/PrivilegesRole';
import ModulesData from './component/SuperAdmin/Modules';
import RoleType from './component/SuperAdmin/Role';
import SiteMap from './component/SuperAdmin/SiteMap';

function App() {  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LoginReg />} />
          <Route path="/admin-login" element={<SuperAdminLogin />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>       
        <Route path="/">
          <Route path="/dashboard" element={<Dashboard />} />
          <Route exact path="/first-login" element={ <FirstLogin/>}></Route>
          {/* Device Management */}
          <Route exact path="/role" element={ <Role/>}></Route>
          <Route exact path="/devices" element={ <Devices/>}></Route>
          <Route exact path="/users" element={ <UsersDetails/>}></Route>
          <Route exact path="/devices_list" element={ <DevicesList/>}></Route>
          <Route exact path="/devices/deviceSettings/:d_id" element={ <DeviceSettings />}></Route>
          <Route exact path="/devices/device-history/:d_id" element={ <DeviceHistory />}></Route>
          {/* Gateways */}
          <Route exact path="/gateways" element={ <Gateways/>}></Route>
          <Route exact path="/gateways/gatewaysSetting" element={ <GatewaysSettings />}></Route>
          {/* Map */}
          <Route exact path="/map" element={ <Map />}></Route>
          {/* Trigger */}
          <Route exact path="/triggers" element={ <Triggers />}></Route>
          <Route exact path="/AddTriggers" element={ <AddTriggers />}></Route>
          <Route exact path="/EditTriggers" element={ <AddTriggers />}></Route>
          {/* Report */}
          <Route exact path="/Reports" element={ <Reports />}></Route>
          {/* Event */}
          <Route exact path="/events" element={ <Events />}></Route>
          {/* Sharing */}
          <Route exact path="/sharing" element={ <SharingCenter />}></Route>
          <Route exact path="/support-ticket" element={ <SupportRequestClient />}></Route>
          {/* Profile Management */}
          <Route exact path="/profile" element={ <Profile />}></Route>
          <Route exact path="/set-permission/:d_id" element={ <SetPermission />}></Route>
          <Route exact path="/sitemap-view" element={ <SiteMapData />}></Route>

          <Route path="/admin-dashboard" element={<AdminDashbaordScreen/>} />
          <Route path="/clients" element={<AdminClientScreen/>} />   
          <Route path="/admin-users" element={<AdminUsersScreen/>} />
          <Route path="/units" element={<UnitScreen/>} />
          <Route path="/clientDeviceManagement" element={<DeviceManagementsScreen/>} />
          <Route path="/parameters" element={<Parameters/>} />
          <Route path="/troubleshooting" element={ <Troubleshooting/>} />
          <Route path="/supportTicket" element={ <SupportRequest/>} />
          <Route path="/privileges-role" element={ <PrivilegesRole/>} />
          <Route path="/modules" element={ <ModulesData/>} />
          <Route exact path="/role" element={ <RoleType />}></Route>
          <Route exact path="/site-map" element={ <SiteMap />}></Route>
          
        </Route> 
        <Route path="*" element={<h1 className="text-center m-auto">Error 404 Page not found !!</h1>} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
