import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "@mui/material";

const GatewayBasicSettings = () => {
  const [checked, setChecked] = useState(false);

  return (
    <>
      <Container>
        <Row>
          <Col sm={7}>
            <div className="flex flex-row gap-2 align-items-center mb-4">
              <label htmlFor="name" className="font-bold w-50">
                Name
              </label>
              <InputText
                id="name"
                className="w-100"
                value="LoRa Gateway_10.1"
              />
            </div>

            <div className="flex flex-row gap-2 mb-4">
              <label htmlFor="desc" className="font-bold w-50">
                Description
              </label>
              <InputTextarea
                keyfilter="int"
                placeholder="Description"
                rows={5}
                cols={30}
                className="w-100"
              />
            </div>

            <div className="flex flex-row gap-2 mb-4">
              <label htmlFor="offlineAlarm" className="font-bold w-50">
                Device Offline Alarm
              </label>
              <Checkbox
                onChange={(e) => setChecked(e.checked)}
                checked={checked}
                className="w-100"
              ></Checkbox>
            </div>

            <Divider />
            <Row className="mt-4">
              <Col>
                <Button
                  label="Cancle"
                  icon="pi pi-times"
                  severity="danger"
                  rounded
                />
                <Button
                  label="Save"
                  icon="pi pi-check"
                  className="ms-4"
                  autoFocus
                  rounded
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default GatewayBasicSettings;
