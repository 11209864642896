import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Button } from "primereact/button";
import { Divider } from "@mui/material";
import { Password } from 'primereact/password';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const UpdatePassword = () => {
  const [old_password, setOldPassword] = useState('');
  const [new_password, setNewPassword] = useState('');
  const [check_password, setCheckPassword] = useState();
  const [confirm_password, setConfirmPassword] = useState('');

  const handlePasswordStrong = (pass) => {
    setNewPassword(pass);

    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/;
     
    if(!regex.test(pass)){
      setCheckPassword(true)
    }else{
      setCheckPassword(false)
    }
  }

  const handleSubmit = () => {
    if (check_password) { } else {
      if (new_password === confirm_password) {
        var data = localStorage.getItem('userData');
        var result = JSON.parse(data);
  
        var postData = {
          usr_id: result.usr_id,
          old_password: old_password,
          new_password: new_password
        };
  
        console.log(postData)
  
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          }
        };
  
        axios.post(global.passUrl + "update_password", postData, axiosConfig).then((res) => {
          if (res.status === 200) {
            if (res.data.status === "success"){
              toast.dismiss()
              toast.success(res.data.msg, { theme: "colored", })
              setTimeout(() => {
                window.location.href = '/dashboard';
              }, 2000);
            } else {
              toast.dismiss()
              toast.error(res.data.msg, { theme: "colored", })
            }
          } else if (res.status === 400) {
            toast.dismiss()
            toast.error("Faill!!", { theme: "colored", })
          }
        }).catch((err) => { console.log("AXIOS ERROR: ", err); });
      } else {
        toast.dismiss()
        toast.error("New and confirmed Passwords are not matched.", { theme: "colored", })
      }
    }
    
  }

  return (
    <>
      <ToastContainer/>
      <Container className="changePassword">
        <Row>
          <Col sm={7}>

            <div className="flex flex-row gap-2 align-items-center mb-4">
              <label htmlFor="display" className="font-bold w-50">
              Old Password
              </label>
              <Password value={old_password} onChange={(e) => setOldPassword(e.target.value)} className="w-100" toggleMask feedback={false} />
            </div>
            
            <div className="flex flex-row gap-2 align-items-center mb-0">
              <label htmlFor="dst" className="font-bold w-50">
              New Password
              </label>
              <Password value={new_password} onChange={(e) => handlePasswordStrong(e.target.value)} className="w-100" toggleMask feedback={false} />
            </div>
            <div className="flex flex-row gap-2 align-items-center mb-4">
              <label className="w-25"> </label>
              <small style={{color: 'red', marginLeft: '10%'}}>{check_password ? "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" : ""}</small>
            </div>            
            <div className="flex flex-row gap-2 align-items-center mb-4">
              <label htmlFor="dst" className="font-bold w-50">
              Confirm Password
              </label>
              <Password value={confirm_password} onChange={(e) => setConfirmPassword(e.target.value)} className="w-100" toggleMask feedback={false} />
            </div>
            <Divider />
            <Row className="mt-4">
              <Col>
                <Button
                  label="Cancle"
                  icon="pi pi-times"
                  severity="danger"
                  rounded
                />
                <Button
                  label="Save"
                  icon="pi pi-check"
                  className="ms-4"
                  autoFocus
                  rounded
                  onClick={handleSubmit}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdatePassword;
