/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import Topbar from './topbar';
import Menubar from "./menubar";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/custom.css";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/Material.css";
import "@pnotify/core/BrightTheme.css";
import "@pnotify/confirm/dist/PNotifyConfirm.css";
import { useNavigate } from 'react-router-dom';

const SetPermission = () => {
    const [check_site_data, setSiteMapImage] = useState(false)
    const [site_image, setSiteMapImageName] = useState(false)

    const navigate = useNavigate()
    var data1 = localStorage.getItem('userData');
    var result1 = JSON.parse(data1);

    useEffect(() => {
        if (result1) {
            getAllDetails()
            updateAuditLog()
        } else {
            navigate("/admin-login")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllDetails = () => {
        var postData = {
            s_id: result1.client_id
        };

        let axiosConfig = {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        };
        //Get all users details in bootstrap table
        axios.post(global.passUrl + "get_site_images", postData, axiosConfig).then((res) => {
            if(res.data.length){
                setSiteMapImage(true)
                setSiteMapImageName(res.data[0].site_image_name)
            }else{
                setSiteMapImage(false)
            }
        });
    }

    
  const  updateAuditLog = () => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    var postData1 = {
      usr_id: result1.usr_id,
      user_action: "Site Map Open",
      sys_remark : "Site Map Open",
      device_eui: ""
    };

    axios.post(global.passUrl + 'call_audit_log', postData1, axiosConfig).then((res) => {      
    });
  }
  
    return (
        <>
            <ToastContainer />
            <div className="conatainer otherPages">
                <div className="row">
                    <div className="col-2 col-lg-1 pe-0 d-none d-sm-block">
                        <Menubar pageName="sitemap-view" />
                    </div>

                    <div className="col-12 col-lg-11 ps-0">
                        <Topbar />

                        <div className="mainContent" style={{ padding: "15px" }}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="leftPanel">
                                        <div className="row tableTitle">Site Map</div>                                        
                                        <div style={{ marginLeft: "15px",marginRight: "15px" }}>
                                            {
                                                check_site_data ?                                            
                                                    <img src={require('../../../../server/site_map/'+site_image)} alt={site_image} style={{ width: "100%", height: (window.innerHeight - 220) }} />
                                                :
                                                ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SetPermission;
