
import React from 'react'; 
import { Message } from 'primereact/message';

// Saverity shows the color codes info: Primary | success: Green | Error: Red | Warning: yellow
export default function StatusDemo(props) {
    return (
            <Message text={`${props.text}`} severity={`${props.status}`}/>
    )
}
        